var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaRegistrationStep",
        { ref: "RegistrationStep", attrs: { "step-title": "Communications." } },
        [
          _c("div", { staticClass: "registrationText" }, [
            _vm._v(
              '\n            I would like to receive communications relating to other Experian products and services that may be\n            beneficial to my business.\n            You can change this permission at any time on your "My Account Page".\n        '
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "registrationInput" },
            [
              _c(
                "ebaCheckbox",
                {
                  ref: "communicationInput",
                  attrs: { noValidation: true, inputID: "communicationsInput" },
                  model: {
                    value: _vm.registrationData.MarketingOptIn,
                    callback: function ($$v) {
                      _vm.$set(_vm.registrationData, "MarketingOptIn", $$v)
                    },
                    expression: "registrationData.MarketingOptIn",
                  },
                },
                [
                  _vm._v(
                    "\n                I agree to receive communications from Experian\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: {
            "step-title": "Third-party services.",
            showStepNumber: false,
          },
        },
        [
          _c("div", { staticClass: "registrationText" }, [
            _vm._v(
              "\n            I would like to receive communications from Experian about carefully selected partners who provide\n            complementary business\n            services such as Legal and Accountancy services.\n            "
            ),
            _c("br"),
            _vm._v(
              "\n            Please note, your details will never be passed directly to any third-party.\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "registrationInput" },
            [
              _c(
                "ebaCheckbox",
                {
                  ref: "thirdPartyServicesInput",
                  attrs: {
                    noValidation: true,
                    inputID: "thirdPartyServicesInput",
                  },
                  model: {
                    value: _vm.registrationData.ThirdPartyOptIn,
                    callback: function ($$v) {
                      _vm.$set(_vm.registrationData, "ThirdPartyOptIn", $$v)
                    },
                    expression: "registrationData.ThirdPartyOptIn",
                  },
                },
                [
                  _vm._v("\n                I agree to the "),
                  _c(
                    "a",
                    {
                      staticClass: "registrationLink",
                      attrs: { target: "_blank", href: _vm.thirdPartyServices },
                    },
                    [_vm._v("third-party services")]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }