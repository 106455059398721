var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "registrationPage" }, [
    _c("div", { staticClass: "registrationContainer" }, [
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "alertIcon" }, [
          _c("img", {
            attrs: {
              src: require("../../components/common/assets/images/alert.svg"),
              alt: "Alert",
            },
          }),
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "gradientText" }, [
          _vm._v("We encountered an issue processing your details."),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "informationText" }, [
          _vm._v(
            "\n                This may be caused by one of the following reasons:\n                "
          ),
          _c("ol", { staticClass: "reasonList" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n                        If your Home Address details are correct and you have verified this with Companies\n                        House, please call us on "
              ),
              _c(
                "a",
                {
                  staticClass: "addressLink",
                  attrs: {
                    href:
                      "tel:" + _vm.configObj.footerDetails.supportPhoneNumber,
                  },
                },
                [_vm._v(_vm._s(_vm.configObj.footerDetails.supportPhoneNumber))]
              ),
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.configObj.footerDetails.callChargeText) +
                  ",\n                        Mon - Thurs 8:30am to 5:30pm, Fri 8:30am to 5:00pm."
              ),
              _c("br"),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(
        "\n                        You may have entered your Home Address or Date of Birth incorrectly. For security\n                        reasons, please wait 24 hours and try registering again."
      ),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(
        "\n                        The Home Address you have entered for your directorship may be incorrect at Companies\n                        House.\n                        "
      ),
      _c(
        "a",
        {
          staticClass: "addressLink",
          attrs: {
            href: "http://www.companieshouse.gov.uk/forms/generalForms/CH01_change_of_directors_details.pdf",
            target: "_blank",
          },
        },
        [_vm._v("Find out how to change your address")]
      ),
      _vm._v(" "),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }