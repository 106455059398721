<template>
    <div id="divFooter">
        <div class="FooterContactUs">Contact Us</div>
        <div class="FooterViolet">If you have any queries, please contact us and a member of our team will be happy to
            help
        </div>
        <div class="FooterDarkBlue">
            <router-link id="anchorTelNo" :to="{name: 'SalesAndHelp'}">{{footerDetails.contactUsText}} -
                {{footerDetails.supportPhoneNumber}}</router-link>
            <span class="ContactUsDivider">|</span>
            <router-link id="anchorEmail" :to="{name: 'EmailUs'}">Email us -
                {{footerDetails.supportEmailAddress}}</router-link>
        </div>
        <div class="FooterSubText">{{footerDetails.callChargeText}}</div>
        <div class="line-separator"></div>
        <table class="FooterDocumentsContainer">
            <tbody>
            <tr>
                <td class="FooterDocument">                    
                    <router-link id="anchorTC" :to="{name: 'ViewTermsAndConditions'}">Terms & Conditions</router-link>                
                    <router-link id="anchorPP" :to="{name: 'Privacy'}">Privacy Policy</router-link>
                    <a href="javascript: Cookiebot.renew()" id="anchorCK">Cookies</a>
                    <router-link id="anchorHF" :to="{name: 'FAQs'}">Help & FAQ's</router-link>
                </td>
            </tr>
            </tbody>
        </table>
        <div class="FooterSubText">Experian Ltd is authorised and regulated by the Financial Conduct Authority. Experian
            Ltd is registered in England and Wales under company registration number 653331.
        </div>
        <div class="FooterSubText">Registered office address. The Sir John Peace Building. Experian Way, NG2 Business
            Park, Nottingham NG80 1ZZ
        </div>
        <div class="FooterSubText">&copy; 2018 Experian. All rights reserved</div>
        <div class="FooterSubText promotionalOfferText">
            <slot name="promotionalOffer">

            </slot>
        </div>
        <table class="tblSocialMedia">
            <tbody>
            <tr>
                <td>
                    <div class="innerMediaContainer">
                        <ebaImageLink
                                class="SocialMedia"
                                routeName="LinkedIn"
                                :source="require('./assets/images/LinkedIn.svg')"
                                altText="LinkedIn">
                        </ebaImageLink>
                        <ebaImageLink
                                class="SocialMedia"
                                routeName="Facebook"
                                :source="require('./assets/images/Facebook.svg')"
                                altText="Facebook/ExperianBusinessAssist">
                        </ebaImageLink>
                        <ebaImageLink
                                class="SocialMedia"
                                routeName="YouTube"
                                :source="require('./assets/images/Youtube.svg')"
                                altText="Youtube/ExperianSme">
                        </ebaImageLink>
                        <ebaImageLink
                                class="SocialMedia"
                                routeName="Twitter"
                                :source="require('./assets/images/Twitter.svg')"
                                altText="Twitter/ExperianBAssist">
                        </ebaImageLink>
                        <ebaImageLink
                                class="SocialMedia"
                                routeName="Blog"
                                :source="require('./assets/images/blog.svg')"
                                altText="ExperianBlog">
                        </ebaImageLink>
                    </div>
                    <ebaImageLink class="Stripe"
                                  :source="require('./assets/images/payment/powered_by_stripe.svg')"
                                  altText="Stripe">
                    </ebaImageLink>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    import {get as config} from '../../scripts/common/config';

    export default {
        name: 'Footer',
        data() {
            return {
                footerDetails: config().footerDetails,
                termsAndConditionsLink: config().TermsAndConditions
            };
        }
    };
</script>

<style lang="less">

    @import 'assets/css/common.less';

    .FooterViolet {
        overflow: hidden;
        text-align: center;
        color: @experian-violet;
        font-size: 18px;
        margin-top: 25px;
        padding: 0 5px;
    }

    .FooterDarkBlue {
        overflow: hidden;
        text-align: center;
        color: @experian-sapphire;
        font-size: 18px;
        margin: 15px 15px 12px 15px;
    }

    .FooterContactUs {
        overflow: hidden;
        text-align: center;
        color: @experian-violet;
        font-size: 18px;
        padding-bottom: 4px;
    }

    .FooterContactUs:before,
    .FooterContactUs:after {
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }

    .FooterContactUs:before {
        right: 20px;
        margin-left: -50%;
        background-color: @experian-sapphire;
    }

    .FooterContactUs:after {
        left: 20px;
        margin-right: -50%;
        background-color: @experian-violet;
    }

    .ContactUsDivider {
        margin: 10px;
    }

    .line-separator {
        height: 1px;
        background: @experian-light-grey;
        margin: 20px 50px 10px 50px;
    }

    .FooterDocumentsContainer {
        margin: 0 auto;
    }

    .FooterDocument a {
        color: @experian-sapphire;
        font-size: 18px;
        margin: 20px;
        float: left;
    }

    .FooterSubText {
        overflow: hidden;
        text-align: center;
        color: @experian-dark-medium-grey;
        font-size: 12px;
        margin: 5px 20px;
    }

    .FooterSubText a {
        color: @experian-sapphire;
    }

    .SocialMediaContainer {
        background-color: @experian-light-grey;
        padding: 26px 0;
        min-height: 45px;
        width: 100%;
        margin-top: 20px;
    }

    .tblSocialMedia {
        background-color: @experian-light-grey;
        padding: 26px 0;
        min-height: 45px;
        width: 100%;
    }

    .innerMediaContainer {
        padding-left: 24px;
    }

    .Stripe img {
        height: 45px;
        float: right;
        margin-right: 42px;
    }

    .SocialMedia {
        float: left;
        width: 45px;
        height: 45px;
        padding-right: 10px;
    }

    .SocialMedia img {
        width: 45px;
        height: 45px;
    }

    .SocialMedia:hover img {
        opacity: 0.7;
    }

    .promotionalOfferText {
        margin: 20px;
    }

    @media only screen and (max-width: 640px) {

        #anchorEmail {
            margin-top: -6px;
        }

        .FooterDocumentsContainer a {
            width: 100%;
        }

        .FooterDocument {
            text-align: center;
        }

        .FooterDocument a {
            margin: 10px 0px;
        }

        .FooterViolet {
            padding: 0 25px;
        }

        .FooterDarkBlue {
            padding: 0;
            margin: 6px;
        }

        .ContactUsDivider {
            display: none;
        }

        #anchorTelNo, #anchorEmail {
            width: 100%;
            float: left;
            margin: 10px 0px;
        }

        .SocialMediaContainer {
            margin: auto;
            padding: 5px 0px 0px 0px;
        }

        .tblSocialMedia {
            padding: 5px 0;
            text-align: center;
        }

        .SocialMedia {
            float: left;
            width: 20%;
            padding-right: 0;
        }

        .SocialMedia img {
            margin: 8px;
        }

        .Stripe img {
            float: none;
            margin: auto;
        }

        .FooterSubText {
            padding: 14px 8px;
            margin: -12px 5px 0 5px;
        }

        .FooterSubText a {
            color: @experian-sapphire;
        }

        .line-separator {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .innerMediaContainer {
            margin-right: 0px;
            padding-left: 0px;
        }

        .promotionalOfferText {
            margin-top: 0;
        }
    }

    /* Mobile */
    @media only screen and (max-width: 560px) {
        .innerMediaContainer {
            margin-right: 0px;
            padding-left: 0px;
        }

        .SocialMediaContainer {
            padding-top: 0;
        }
    }
</style>
