var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: {
            "step-title":
              "Choose an address from the list below or enter manually.",
          },
        },
        [
          _c(
            "div",
            [
              _c("ebaDropdown", {
                ref: "addressOptionsDropdown",
                attrs: {
                  "data-set": _vm.dataSet,
                  inputID: "suggestedAddressDropdown",
                  "place-holder": "Choose an address",
                },
                nativeOn: {
                  change: function ($event) {
                    return _vm.handleSelect.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.selectedAddressData.dropdownSelect,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedAddressData, "dropdownSelect", $$v)
                  },
                  expression: "selectedAddressData.dropdownSelect",
                },
              }),
              _vm._v(" "),
              _c("ebaInfo", [
                _c("strong", [
                  _vm._v("Please choose an address from our list above"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("ebaTextInput", {
                ref: "FlatNumberInput",
                attrs: {
                  "place-holder": "Flat Number",
                  invalidText: _vm.flatNumberErrorMessage,
                  validation: _vm.numbersRegex,
                  inputID: "flatInput",
                },
                model: {
                  value: _vm.Address.FlatNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.Address, "FlatNumber", $$v)
                  },
                  expression: "Address.FlatNumber",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "buildingNameInput",
                attrs: {
                  autocomplete: "address-line1",
                  "place-holder": "Building Number/Name",
                  invalidText: _vm.buildingNameErrorMessage,
                  validation: _vm.specialCharactersRegex,
                  inputID: "buildingInput",
                },
                model: {
                  value: _vm.Address.BuildingNameNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.Address, "BuildingNameNumber", $$v)
                  },
                  expression: "Address.BuildingNameNumber",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "addressInput",
                attrs: {
                  autocomplete: "address-line2",
                  "no-validation": true,
                  "place-holder": "Address",
                  inputID: "addressInput",
                },
                model: {
                  value: _vm.Address.Address,
                  callback: function ($$v) {
                    _vm.$set(_vm.Address, "Address", $$v)
                  },
                  expression: "Address.Address",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "townInput",
                attrs: {
                  autocomplete: "address-level2",
                  invalidText: _vm.townErrorMessage,
                  validation: _vm.specialCharactersRegex,
                  "place-holder": "Town",
                  inputID: "townInput",
                },
                model: {
                  value: _vm.Address.Town,
                  callback: function ($$v) {
                    _vm.$set(_vm.Address, "Town", $$v)
                  },
                  expression: "Address.Town",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "postcodeInput",
                attrs: {
                  autocomplete: "postal-code",
                  invalidText: "You must specify a postcode",
                  validation: _vm.postcodeRegex,
                  "place-holder": "Postcode",
                  inputID: "postcodeInput",
                },
                model: {
                  value: _vm.Address.Postcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.Address, "Postcode", $$v)
                  },
                  expression: "Address.Postcode",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }