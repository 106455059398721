var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cardContainer" },
    [
      _c("ebaCommonErrorModal", { ref: "commonErrorModal" }),
      _vm._v(" "),
      _c("ebaLoadingModal", { ref: "loadingModal" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "ebaRegistrationStep",
            {
              attrs: {
                "step-title": "We've been unable to verify your identity.",
                showStepNumber: false,
              },
            },
            [
              _c(
                "div",
                [
                  _c("div", { staticClass: "cardBodyText" }, [
                    _vm._v(
                      "\n                    We have been unable to verify your identity from the information provided."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n                    To ensure the security of your business data we have sent a PIN (Personal Identification Number)\n                    verification\n                    letter to the address that you entered during the registration process."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n\n                    When you receive your PIN letter please follow the instructions provided to gain access to your\n                    Experian My Business Profile credit report.\n                    "
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        "Please Note it could take up to 7 Days for a PIN letter to arrive"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("ebaInfo", [
                    _c("strong", [
                      _vm._v(
                        "Your subscription will NOT start & payment will not be taken until you have\n                        completed the registration process."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }