var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "textInputGroup" }, [
    _c("input", {
      ref: "textInput",
      class: [_vm.getValidClass(), _vm.isDisabled()],
      attrs: {
        id: _vm.inputID,
        autocomplete: _vm.autocomplete === true ? "on" : "off",
        maxlength: _vm.maxLength,
        type: _vm.getInputType(),
        required: "",
        disabled: _vm.disabled,
      },
      domProps: { value: _vm.value },
      on: { input: _vm.updateValue },
    }),
    _vm._v(" "),
    _vm.inputType === "password"
      ? _c("div", [
          _c("span", {
            class: [_vm.showPasswordClass, "toggle-password"],
            on: { click: _vm.togglePassword },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("label", { class: [_vm.getValidClass(), _vm.isDisabled()] }, [
      _vm._v(_vm._s(_vm.placeHolder)),
    ]),
    _vm._v(" "),
    _c("p", { class: [_vm.getValidClass(), _vm.isDisabled()] }, [
      _vm._v(_vm._s(_vm.getLabelText())),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }