<template>
    <div>
        <ebaContactErrorModal ref="contactErrorModal" errorCode="EXP-MBP-002" :showEmailFooter="true"
                              :showCloseFooter="false"/>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Please tell us your contact details.">
            <div>
                <ebaTextInput ref="emailAddressInput" inputID="emailAddressInput"
                              input-type="email"
                              :invalidText="invalidEmailAddress"
                              place-holder="Email Address"
                              v-model="registrationData.EmailAddress"/>
                <ebaTextInput ref="telephoneInput" inputID="telephoneNumberInput"
                              input-type="tel"
                              invalidText="You must specify a valid Telephone Number"
                              place-holder="Telephone"
                              :validation="telephoneValidationRegex"
                              v-model="registrationData.Telephone"/>
            </div>
            <br style="clear:both">
            <p class="subtext">We will only use the data you provide to us when creating your account for up to 30 days
                and it will be subsequently deleted</p>
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import {mapState, mapActions} from 'vuex';
    import {get as config} from '../../scripts/common/config';
    import registrationHandler from '../../scripts/registrationHandler';
    import validationHandler from '../../scripts/common/validationHandler';

    export default {
        name: 'contactDetailsStep',
        props: {
            registrationData: {
                type: Object
            }
        },
        data() {
            return {
                invalidEmailAddress: 'You must specify an Email Address',
                telephoneValidationRegex: ''
            };
        },
        computed: {
            // Map our getters from Vuex
            ...mapState([
                'Director'
            ])
        },
        mounted() {

            if (this.$store.state.businessSearch.HasDirector === false) {
                this.$router.push({
                    name: 'Home'
                });
            }

            this.$emit('setCustomHeaderText', {
                text: 'Welcome ' + this.$store.state.businessSearch.Director.firstName + ' ' + this.$store.state.businessSearch.Director.surname + '<br/>' +
                    'We need to ask you a few questions to create your account'
            });

            // Setup Product Code and ProductVariantSKU
            this.registrationData.ProductCode = config().ProductCode;
            this.registrationData.ProductVariantSku = config().ProductVariantSku;

            this.telephoneValidationRegex = validationHandler.getValidTelephonePattern();
            this.$refs.emailAddressInput.$el.getElementsByTagName('input')[0].focus();

            if (this.$store.state.peps.hasPeps === true) {
                this.$emit('requestCustomProceedText', {
                    text: 'Submit'
                });
            } else {
                this.$emit('clearCustomProceedText');
            }
        },
        methods: {

            ...mapActions([
                'clearExistingData'
            ]),

            isStepValid: function () {
                var self = this;

                return new Promise(function (resolve, reject) {
                    var isValid = true;

                    if (!self.$refs.emailAddressInput.validate()) {
                        isValid = false;
                    }

                    if (!self.$refs.telephoneInput.validate()) {
                        isValid = false;
                    }

                    if (isValid) {
                        var emailString = self.$refs.emailAddressInput.value;

                        // If its still valid, check to make sure its not already in use
                        if (isValid) {
                            // Validation passed. If user hasPeps, trigger email
                            if (self.$store.state.peps.hasPeps === true) {
                                registrationHandler.triggerCustomerSupportEmail({
                                    emailAddress: emailString,
                                    telephoneNumber: self.$refs.telephoneInput.value,
                                    businessName: self.$store.state.businessSearch.Business.name,
                                    businessRef: self.$store.state.businessSearch.Business.businessRef,
                                    directorFirstname: self.$store.state.businessSearch.Director.firstName,
                                    directorSurname: self.$store.state.businessSearch.Director.surname
                                })
                                    .then(() => {
                                        self.clearExistingData()
                                            .then(() => {
                                                resolve(isValid);
                                            });
                                    })
                                    .catch(error => {
                                        self.$refs.contactErrorModal.show();
                                        console.log(error);
                                        resolve(false);
                                    });
                            } else {
                                registrationHandler.isExistingCustomer(emailString)
                                    .then((response) => {
                                        if (response === true) {
                                            self.invalidEmailAddress = 'Please check the email address you have entered. If you have any questions, please call 0345 045 8081';
                                            self.$refs.emailAddressInput.invalidate();
                                            isValid = false;
                                        } else {
                                            isValid = true;
                                        }

                                        resolve(isValid);
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            }
                        } else {
                            resolve(isValid);
                        }
                    } else {
                        resolve(isValid);
                    }
                });
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../../components/common/assets/css/common.less';

    .subtext {
        margin: 0 4px 0 0;
        text-align: left;
        color: @experian-dark-medium-grey;
        font-size: 12px;
    }

</style>
