<template>
    <div>
        <ebaRegistrationStep ref="RegistrationStep"
                             step-title="Please choose a security question to help you recover your account.">
            <div style="margin-top: 20px;">
                <ebaDropdown ref="securityQuestionInput" :data-set="securityQuestions"
                             v-model="registrationData.SecurityQuestionDetails.SecurityQuestion"
                             invalidText="You must select a security question"
                             inputID="securityQuestionInput"
                             place-holder="Security Question"/>
                <ebaTextInput ref="securityAnswerInput" input-type="password"
                              v-model="registrationData.SecurityQuestionDetails.SecurityAnswer"
                              :invalidText="securityAnswerInvalidText"
                              inputID="securityAnswerInput"
                              helpText="Your security answer should be between 3-50 characters long"
                              :validation="securityValidationRegex"
                              place-holder="Security Answer"/>
                <ebaTextInput ref="securityAnswerConfirmationInput"
                              v-model="registrationData.SecurityQuestionDetails.ConfirmSecurityAnswer"
                              input-type="password"
                              :invalidText="confirmSecurityAnswerInvalidText"
                              inputID="securityAnswerConfirmationInput"
                              :validation="securityValidationRegex"
                              place-holder="Confirm Security Answer"/>
            </div>
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import validationHandler from '../../scripts/common/validationHandler';
    import registrationHandler from '../../scripts/registrationHandler';

    export default {
        name: 'SecurityStep',
        props: {
            registrationData: {
                type: Object
            }
        },
        created() {
            var self = this;

            self.securityValidationRegex = '^.{3,312}$';

            registrationHandler.getSecurityQuestions()
                .then((response) => {
                   self.securityQuestions = response;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        mounted() {
            this.$emit('clearCustomProceedText');
        },
        data: function () {
            return {
                securityAnswerInvalidText: '',
                confirmSecurityAnswerInvalidText: '',

                securityQuestions: []
            };
        },
        methods: {
            isStepValid: function () {
                var self = this;

                return new Promise(function (resolve, reject) {
                    var isValid = true;

                    self.$refs.securityAnswerInput.clearValidation();
                    self.$refs.securityAnswerConfirmationInput.clearValidation();

                    if (!self.$refs.securityQuestionInput.validate()) {
                        isValid = false;
                    }

                    if (self.$refs.securityAnswerInput.value === '' ||
                        self.$refs.securityAnswerInput.value === undefined ||
                        self.$refs.securityAnswerInput.value === null) {

                        self.securityAnswerInvalidText = 'Please enter your security answer';
                        self.$refs.securityAnswerInput.invalidate();
                        isValid = false;
                    } else if (!self.$refs.securityAnswerInput.validate()) {
                        self.securityAnswerInvalidText = 'Please make sure your answer is between 3-50 characters';
                        isValid = false;
                    }

                    var confirmSecurityAnswer = self.$refs.securityAnswerConfirmationInput.value;
                    if (confirmSecurityAnswer === null || confirmSecurityAnswer === undefined || confirmSecurityAnswer === '') {
                        self.confirmSecurityAnswerInvalidText = 'Please enter your security answer';
                        isValid = false;

                        self.$refs.securityAnswerConfirmationInput.invalidate();
                    }

                    // Complex checks
                    if (isValid) {
                        // Check Security Answer does not contain other values
                        var arrayToCheck = [
                            self.registrationData.EmailAddress,
                            self.registrationData.Password,
                            self.registrationData.MemorableWordDetails.MemorableWord,
                            self.registrationData.MemorableWordDetails.MemorableHint
                        ];
                        if (validationHandler.arrayUniqueValueCheck(self.$refs.securityAnswerInput.value, arrayToCheck) === false) {
                            isValid = false;
                            self.securityAnswerInvalidText = 'The values you have entered do not follow our account creation rules';
                            self.$refs.securityAnswerInput.invalidate();
                        }

                        //Check Security Answers match
                        if (validationHandler.valueMatchCheck(self.$refs.securityAnswerConfirmationInput.value, self.$refs.securityAnswerInput.value) === false) {
                            isValid = false;
                            self.confirmSecurityAnswerInvalidText = 'The security answers you provided do not match';
                            self.$refs.securityAnswerConfirmationInput.invalidate();
                        }
                    }

                    resolve(isValid);
                });
            }
        }
    };
</script>

<style lang="less" scoped>

</style>