var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { float: "left" } }, [
    _c(
      "label",
      { staticClass: "checkboxContainer" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c("input", {
          ref: "checkboxInput",
          attrs: { type: "checkbox", id: _vm.inputID, required: "" },
          domProps: { checked: _vm.value },
          on: { click: _vm.updateValue },
        }),
        _vm._v(" "),
        _c("span", { class: [_vm.getValidClass(), "checkmark"] }),
        _vm._v(" "),
        _c("p", { class: [_vm.getValidClass()] }, [
          _vm._v(_vm._s(_vm.getLabelText())),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }