var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outerContainer" }, [
    _c("div", { staticClass: "headerContainer" }, [
      _c(
        "div",
        { staticClass: "experianLogo" },
        [
          _c("ebaImageLink", {
            attrs: {
              "alt-text": "ExperianLogo",
              "route-name": "Home",
              isResponsive: true,
              source: require("../common/assets/images/ExperianLogo.svg"),
              tabletSource: require("../common/assets/images/ExperianLogo.svg"),
              mobileSource: require("../common/assets/images/ExperianLogoSmall.svg"),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "headerActions" }, [_c("ebaHeaderActions")], 1),
      _vm._v(" "),
      _c("br", { staticStyle: { clear: "both" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "productImage" },
        [
          _c("ebaLogo", {
            attrs: {
              "alt-text": "ProspectorLogo",
              isResponsive: true,
              source: require("../../assets/Images/ProductIcon.svg"),
              tabletSource: require("../../assets/Images/ProductIconSmall.svg"),
              mobileSource: require("../../assets/Images/ProductIconSmall.svg"),
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "productText" }, [
            _vm._v("\n                My Business Profile\n            "),
          ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "seperator" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }