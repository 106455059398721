var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaButton",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPackageDetails === true,
              expression: "showPackageDetails === true",
            },
          ],
          attrs: { "style-type": "flat" },
          nativeOn: {
            click: function ($event) {
              return _vm.showPackageInfo()
            },
          },
        },
        [
          _vm._v("\n        Package "),
          _c("span", { staticClass: "success" }, [_vm._v("(1)")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ebaButton",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAuthenticated === false,
              expression: "isAuthenticated === false",
            },
          ],
          attrs: { "style-type": "flat", "route-name": "Login" },
        },
        [_vm._v("Login")]
      ),
      _vm._v(" "),
      _c(
        "ebaButton",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAuthenticated === true,
              expression: "isAuthenticated === true",
            },
          ],
          attrs: { "style-type": "flat", "route-name": "Logout" },
        },
        [_vm._v("Logout")]
      ),
      _vm._v(" "),
      _c("ebaPackageModal", { ref: "packageModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }