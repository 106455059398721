<template>
    <div>
        Directorship taken
    </div>
</template>

<script>

    export default {
        name: 'directorshipTaken',
    };

</script>

<style lang="less" scoped>

    @import '../../components/common/assets/css/common.less';

</style>
