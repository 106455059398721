<template>
    <span v-if="hasValidRoute()">
        <router-link :to="routeName">
            <slot>
            </slot>
        </router-link>
    </span>
    <span v-else>
        <a v-bind:href="linkUrl" :target="target">
            <slot>
            </slot>
        </a>
    </span>
</template>
<script>
    export default {
        name: 'LinkText',
        props: {
            linkUrl: {
                type: String,
                required: false,
                default: null
            },
            routeName: {
                type: String,
                required: false,
                default: null
            },
            className: {
                type: String,
                required: false
            },
            target: {
                type: String,
                required: false,
                default: '_blank'
            }
        },
        methods: {
            hasValidRoute() {
                return this.routeName !== null;
            }
        }
    };
</script>