<template>
    <div class="container">
        <div :class="['option', isTrueSelected, setValidity]" id="true" @click="select(true)">
            {{trueText}}
        </div>
        <div :class="['option', isFalseSelected, setValidity]" id="false" @click="select(false)">
            {{falseText}}
        </div>
        <p :class="[getTextClass()]">
            {{getText()}}
        </p>
    </div>
</template>

<script>

    import validationHandler from '../../../../scripts/common/validationHandler';

    export default {
        name: 'ebaSwitch',
        props: {
            trueText: {
                type: String,
                required: false,
                default: 'YES'
            },
            falseText: {
                type: String,
                required: false,
                default: 'NO'
            },
            invalidText: {
                type: String,
                required: false,
                default: 'An input is required'
            },
            helpText: {
                type: String,
                required: false
            },
            value: {}
        },
        mounted() {
            this.select(this.value);
        },
        computed: {
            isTrueSelected() {
                if (this.value === true) {
                    return 'selected';
                }
            },
            isFalseSelected() {
                if (this.value === false) {
                    return 'selected';
                }
            },
            setValidity() {
                if (this.validationState === this.validationStateEnum.INVALID) {
                    return 'invalid';
                }
            }
        },
        data() {
            return {
                validationStateEnum: validationHandler.getValidationStates(),
                validationState: null
            };
        },
        methods: {
            select(val) {
                this.$emit('input', val);
                this.$emit('onChange', val);

                //Reset validation
                this.validationState = this.validationStateEnum.INITIAL;
            },
            clearValidation() {
                this.validationState = this.validationState.INITIAL;
            },
            validate() {
                if (this.value === undefined) {
                    this.validationState = this.validationStateEnum.INVALID;
                } else {
                    this.validationState = this.validationStateEnum.VALID;
                }

                return this.validationState !== this.validationStateEnum.INVALID;
            },
            getText() {
                if (this.validationState === this.validationStateEnum.INITIAL) {
                    return this.helpText;
                } else if (this.validationState === this.validationStateEnum.INVALID) {
                    return this.invalidText;
                } else {
                    return '';
                }
            },
            getTextClass() {
                if (this.validationState === this.validationStateEnum.INITIAL) {
                    return 'helpText';
                } else if (this.validationState === this.validationStateEnum.INVALID) {
                    return 'invalid';
                } else {
                    return '';
                }
            }
        }
    };

</script>

<style lang="less" scoped>

    @import '../../assets/css/common.less';

    .container {
        display: inline-block;
    }

    .option {
        display: inline-block;
        min-width: 60px;
        height: 36px;
        text-align: center;
        padding: 8px;
        margin: 8px 8px 8px 0;
        line-height: 36px;

        cursor: pointer;
        background: white;
        color: @experian-dark-medium-grey;
        border: 2px solid @experian-purple;
        -moz-border-image: -moz-linear-gradient(top, @experian-light-blue 0%, @experian-purple 100%);
        -webkit-border-image: -webkit-linear-gradient(top, @experian-light-blue 0%, @experian-purple 100%);
        border-image: linear-gradient(to bottom, @experian-light-blue 0%, @experian-purple 100%);
        border-image-slice: 1;
    }

    .option:hover:not(.selected) {
        background: @experian-light-grey;
    }

    .selected {
        background: @experian-success;
        color: white;
        border: 2px solid @experian-success;
    }

    .invalid:not(.container p) {
        border: 2px solid @experian-danger-error;
    }

    .container p {
        margin: 6px 10px 4px 0px;
        font-size: 14px;
    }

    .container p.invalid {
        color: @experian-danger-error;
    }

    .container p.helpText {
        color: @experian-sapphire;
    }

</style>
