import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

//Modules
import registration from './common/modules/registration/registration';
import businessSearch from './businessSearch/businessSearch';
import authentication from './common/modules/authentication/authentication';
import peps from './common/modules/peps/peps';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

let plugins = [];
if (process.browser) {
    plugins.push(createPersistedState({storage: window.sessionStorage}));
}

// Create the Vuex Store and assign to it our modules
export default new Vuex.Store({
    modules: {
        registration,
        businessSearch,
        authentication,
        peps
    },
    strict: debug
});
