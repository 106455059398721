<template>
    <div>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Communications.">
            <div class="registrationText">
                I would like to receive communications relating to other Experian products and services that may be
                beneficial to my business.
                You can change this permission at any time on your "My Account Page".
            </div>
            <div class="registrationInput">
                <ebaCheckbox ref="communicationInput"
                             :noValidation="true"
                             v-model="registrationData.MarketingOptIn"
                             inputID="communicationsInput">
                    I agree to receive communications from Experian
                </ebaCheckbox>
            </div>
            <br/>
            <br/>
        </ebaRegistrationStep>
        <br/>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Third-party services." :showStepNumber="false">
            <div class="registrationText">
                I would like to receive communications from Experian about carefully selected partners who provide
                complementary business
                services such as Legal and Accountancy services.
                <br/>
                Please note, your details will never be passed directly to any third-party.
            </div>
            <div class="registrationInput">
                <ebaCheckbox ref="thirdPartyServicesInput"
                             :noValidation="true"
                             v-model="registrationData.ThirdPartyOptIn"
                             inputID="thirdPartyServicesInput">
                    I agree to the <a class="registrationLink" target="_blank"
                                      :href="thirdPartyServices">third-party services</a>
                </ebaCheckbox>
            </div>
            <br/>
            <br/>
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import {get as config} from '../../scripts/common/config';

    export default {
        name: 'CommsAndMarketing',
        props: {
            registrationData: {
                type: Object
            }
        },
        mounted() {
            this.$emit('clearHeaderText');
            this.$emit('clearCustomProceedText');
        },
        data() {
            return {
                thirdPartyServices: config().ThirdPartyServices
            };
        },
        methods: {
            isStepValid: function () {
                var self = this;

                return new Promise(function (resolve, reject) {
                    var isValid = true;

                    if (!self.$refs.communicationInput.validate()) {
                        isValid = false;
                    }

                    resolve(isValid);
                });
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../../components/common/assets/css/common.less';


</style>
