var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ebaCommonErrorModal", { ref: "commonErrorModal" }),
      _vm._v(" "),
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: { "step-title": "Terms and Conditions." },
        },
        [
          _c(
            "div",
            { staticClass: "registrationText" },
            [
              _vm._v(
                "\n            We want you to know how our service works and why we need your registration details. Please state that\n            you have\n            read and agree to "
              ),
              _c(
                "router-link",
                {
                  staticClass: "registrationLink",
                  attrs: { to: { name: "ViewTermsAndConditions" } },
                },
                [_vm._v("these\n            terms")]
              ),
              _vm._v(
                ",\n            it's also required to complete the registration process\n        "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "registrationInput" },
            [
              _c(
                "ebaCheckbox",
                {
                  ref: "termsAndConditionsInput",
                  attrs: {
                    inputID: "termsAndConditionsInput",
                    invalidText: "You must accept the Terms and Conditions",
                  },
                  model: {
                    value: _vm.registrationData.TermsAndConditions,
                    callback: function ($$v) {
                      _vm.$set(_vm.registrationData, "TermsAndConditions", $$v)
                    },
                    expression: "registrationData.TermsAndConditions",
                  },
                },
                [
                  _vm._v("\n                I agree to the "),
                  _c(
                    "router-link",
                    {
                      staticClass: "registrationLink",
                      attrs: { to: { name: "ViewTermsAndConditions" } },
                    },
                    [_vm._v("terms and\n                conditions")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }