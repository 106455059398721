var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("button", { on: { click: _vm.moveToTop } }, [
        _c("img", {
          staticClass: "upImage",
          attrs: { src: require("../../assets/images/backToTop.svg") },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }