import * as types from './authenticationMutations';
import authenticationHandler from '../../../../scripts/authenticationHandler.js';

const state = {
    isAuthenticated: false,
    userID: undefined,
    customerID: undefined,
    registrationStep: undefined,
    FOC: undefined,
    userName: undefined,
    user: {
        id: undefined,
        firstName: undefined,
        lastName: undefined
    }
};

const getters = {
    isAuthenticated: state => {
        return state.isAuthenticated;
    },
    getUserDetails: state => {
        return state.user;
    }
};

const mutations = {
    authenticate(state, payload) {
        state.isAuthenticated = payload && payload.success === true;

        // Set the UserID/CustomerID if we have it (Will be -1 if it does not exist)
        if (payload.customerID !== -1){
            state.customerID = payload.customerID;
        }

        if (payload.FOC !== -1){
            state.FOC = payload.FOC;
        }

        if (payload.userName !== -1){
            state.userName = payload.userName;
        }

        if (payload.userID !== -1){
            state.userID = payload.userID;
        }

        if (payload.step !== -1) {
            state.registrationStep = payload.step;
        }

        // Only set User objects when exist (I.E in Prospector)
        if (payload.user) {
            if (state.isAuthenticated) {
                state.user.id = payload.user.id;
                state.user.firstName = payload.user.firstName;
                state.user.lastName = payload.user.lastName;
            } else {
                state.user.id = undefined;
                state.user.firstName = undefined;
                state.user.lastName = undefined;
            }
        }
    }
};

const actions = {
    authenticate({commit, state}) {
        return new Promise((resolve, reject) => {
            try {
                authenticationHandler.authenticate()
                    .then((resp) => {
                        commit(types.AUTHENTICATE, resp);
                        resolve();
                    })
                    .catch(() => {
                        // This catch is required, otherwise the page will sit and hang
                        commit(types.AUTHENTICATE, {success: false});
                        resolve();
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
