<template>
    <div class="extraInformation">
        <div v-show="showArrow === true" class="arrow-up"></div>
        <slot class="text">Information</slot>
    </div>
</template>

<script>

    export default {
        name: 'informationBlock',
        props: {
            showArrow: {
                type: Boolean,
                default: true
            }
        }
    };

</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    .text {
        text-align: center;
    }

    .extraInformation {
        background: @experian-sapphire;
        color: white;
        padding: 16px;
        text-align: center;
        line-height: 28px;
        margin-top: 42px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
    }

    .arrow-up {
        float: right;
        position: relative;
        right: 32px;
        top: -30px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid @experian-sapphire;
    }

    @media only screen and (max-width: 560px) {
        .extraInformation {
            padding: 16px;
        }
    }

</style>
