var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Chevron" }, [
    _c("img", {
      attrs: { src: require("./assets/Images/chevron.svg"), alt: "chevron" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }