var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showModal
    ? _c("ebaModal", { attrs: { showTopClose: false, width: 320 } }, [
        _c("div", { attrs: { slot: "body" }, slot: "body" }, [
          _c(
            "div",
            { staticClass: "loading" },
            [
              _c("ebaSpinner", { attrs: { useAlt: true } }),
              _vm._v(" "),
              _c("div", { staticClass: "loadingText gradientText" }, [
                _vm._v("\n                Please Wait\n            "),
              ]),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }