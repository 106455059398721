<template>
    <div>
        <ebaRegistrationStep ref="RegistrationStep"
                             step-title="Choose an address from the list below or enter manually.">
            <div>
                <ebaDropdown ref="addressOptionsDropdown" :data-set="dataSet"
                             v-model="selectedAddressData.dropdownSelect"
                             v-on:change.native="handleSelect"
                             inputID="suggestedAddressDropdown"
                             place-holder="Choose an address"/>
                <ebaInfo>
                    <strong>Please choose an address from our list above</strong>
                </ebaInfo>
            </div>
            <div>
                <ebaTextInput ref="FlatNumberInput"
                              v-model="Address.FlatNumber"
                              place-holder="Flat Number"
                              :invalidText="flatNumberErrorMessage"
                              :validation="numbersRegex"
                              inputID="flatInput"/>
                <ebaTextInput ref="buildingNameInput"
                              autocomplete='address-line1'
                              v-model="Address.BuildingNameNumber"
                              place-holder="Building Number/Name"
                              :invalidText="buildingNameErrorMessage"
                              :validation="specialCharactersRegex"
                              inputID="buildingInput"/>
                <ebaTextInput ref="addressInput"
                              autocomplete='address-line2'
                              v-model="Address.Address"
                              :no-validation="true"
                              place-holder="Address"
                              inputID="addressInput"/>
                <ebaTextInput ref="townInput"
                              autocomplete='address-level2'
                              v-model="Address.Town"
                              :invalidText="townErrorMessage"
                              :validation="specialCharactersRegex"
                              place-holder="Town"
                              inputID="townInput"/>
                <ebaTextInput ref="postcodeInput"
                              autocomplete='postal-code'
                              v-model="Address.Postcode"
                              invalidText="You must specify a postcode"
                              :validation="postcodeRegex"
                              place-holder="Postcode"
                              inputID="postcodeInput"/>
            </div>
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import validationHandler from '../../scripts/common/validationHandler';

    export default {
        name: 'QASSelect',
        services: ['$http'],
        props: {
            registrationData: {
                type: Object
            },
            isPreviousAddress: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        mounted() {
            this.bindData();

            this.$emit('clearCustomProceedText');
            this.$emit('clearHeaderText');

            this.numbersRegex = validationHandler.getTextAndNumbersSpacesPattern();
            this.specialCharactersRegex = validationHandler.getValidAddressPattern();
            this.postcodeRegex = validationHandler.getValidPostcodePattern();
        },
        data: function () {
            return {
                selectedAddressData: {
                    dropdownSelect: ''
                },

                // Bindings
                dataSet: [],
                Address: {
                    FlatNumber: '',
                    BuildingNameNumber: '',
                    Address: '',
                    Town: '',
                    Postcode: ''
                },

                numbersRegex: '',
                specialCharactersRegex: '',
                postcodeRegex: '',

                flatNumberErrorMessage: '',
                buildingNameErrorMessage: '',
                addressErrorMessage: '',
                townErrorMessage: ''
            };
        },
        methods: {
            bindData() {
                if (this.isPreviousAddress) {
                    this.dataSet = this.registrationData.PreviousAddressResults;
                    this.Address = this.registrationData.PreviousAddress;
                } else {
                    this.dataSet = this.registrationData.CurrentAddressResults;
                    this.Address = this.registrationData.CurrentAddress;
                }
            },
            isStepValid() {
                return new Promise(function (resolve, reject) {
                    var isValid = true;

                    if (!this.$refs.buildingNameInput.validate()) {
                        if ((this.$refs.buildingNameInput.value || '').trim() === '' || this.$refs.buildingNameInput.value === null) {
                            this.buildingNameErrorMessage = 'You must specify a Building Number/Name';
                        } else {
                            this.buildingNameErrorMessage = 'There are invalid characters in your building number/name';
                        }

                        this.$refs.buildingNameInput.invalidate();
                        isValid = false;
                    }


                    if (!this.$refs.addressInput.validate()) {
                        if ((this.$refs.addressInput.value || '').trim() === '' || this.$refs.addressInput.value === null) {
                            this.addressErrorMessage = 'You must specify an address';
                        } else {
                            this.addressErrorMessage = 'There are invalid characters in your address';
                        }

                        this.$refs.addressInput.invalidate();
                        isValid = false;
                    }

                    if (!this.$refs.townInput.validate()) {
                        if ((this.$refs.townInput.value || '').trim() === '' || this.$refs.townInput.value === null) {
                            this.townErrorMessage = 'You must specify a town';
                        } else {
                            this.townErrorMessage = 'There are invalid characters in your town';
                        }

                        this.$refs.townInput.invalidate();
                        isValid = false;
                    }

                    if (!this.$refs.postcodeInput.validate()) {
                        isValid = false;
                    }

                    if (isValid) {
                        if (this.isPreviousAddress) {
                            this.registrationData.PreviousAddress = this.Address;
                        } else {
                            this.registrationData.CurrentAddress = this.Address;
                        }
                    }

                    resolve(isValid);

                }.bind(this));
            },
            handleSelect(event) {

                //Retrieve object from select
                var qasID = event.target.value;

                this.$services.$http({
                    method: 'GET',
                    url: encodeURI(this.$config.QASApi + '/moniker/' + qasID),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        if (response.status === 200) {
                            var data = response.data;

                            var buildingNameNumber = data.BuildingName === '' ? data.BuildingNumber : data.BuildingName;

                            if (this.isPreviousAddress) {

                                this.$refs.FlatNumberInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.PreviousAddress.FlatNumber = data.FlatNumber

                                // Focus on each element to fix animation bug
                                this.$refs.buildingNameInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.PreviousAddress.BuildingNameNumber = buildingNameNumber;

                                this.$refs.addressInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.PreviousAddress.Address = data.Street;

                                this.$refs.townInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.PreviousAddress.Town = data.Town;

                                this.$refs.postcodeInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.PreviousAddress.Postcode = data.Postcode;

                                this.$refs.postcodeInput.$el.getElementsByTagName('input')[0].blur();
                            } else {

                                this.$refs.FlatNumberInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.CurrentAddress.FlatNumber = data.FlatNumber

                                // Focus on each element to fix animation bug
                                this.$refs.buildingNameInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.CurrentAddress.BuildingNameNumber = buildingNameNumber;

                                this.$refs.addressInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.CurrentAddress.Address = data.Street;

                                this.$refs.townInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.CurrentAddress.Town = data.Town;

                                this.$refs.postcodeInput.$el.getElementsByTagName('input')[0].focus();
                                this.registrationData.CurrentAddress.Postcode = data.Postcode;

                                this.$refs.postcodeInput.$el.getElementsByTagName('input')[0].blur();
                            }
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error.response.data);
                        } else {
                            console.log(error);
                        }
                    });
            }
        }
    };
</script>

<style lang="less" scoped>

</style>
