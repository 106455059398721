var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ebaModal",
    {
      class: { confirmation: _vm.isConfirmation },
      attrs: { showTopClose: false, showHeader: false },
    },
    [
      _c(
        "div",
        { staticClass: "body", attrs: { slot: "body" }, slot: "body" },
        [
          _c(_vm.currentComponent, {
            ref: "stepComponent",
            tag: "component",
            attrs: { commonProperties: _vm.commonProperties },
            on: {
              generateNavButtons: _vm.generateNavButtons,
              action: _vm.action,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
        _vm._l(_vm.buttons, function (button, index) {
          return _c(
            "ebaButton",
            {
              key: index,
              ref: button.type,
              refInFor: true,
              attrs: {
                styleType: button.type == "primary" ? "raised" : "flat",
                isLoading: button.isLoading,
              },
              nativeOn: {
                click: function ($event) {
                  return button.action.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(button.text))]
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }