<template>
    <div class="cardContainer">
        <div class="card">
            <div id="innerContainer">
                <div id="supportImage">
                    <img :src="require('../components/common/assets/images/support.svg')"/>
                </div>
                <div id="infoText" class="cardBodyText gradientText">
                    Thank you for submitting your details
                    <br>
                    we will email you within 24 hours (Monday-Friday) to complete your registration.
                </div>
            </div>
            <span class="cardErrorCode">
                EXP-MBP-001
            </span>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'contactCustomerSupport',
        mounted() {
            let self = this;

            // Invalid data, redirect to home page
            if (!self.$store.state.peps.hasPeps) {
                self.$router.push({
                    name: 'Home'
                });
            }
        }
    };
</script>

<style lang="less" scoped>

    #innerContainer {
        margin: 10px;
    }

    .card {
        padding-bottom: 32px;
    }

    #infoText {
        width: 100%;
        font-size: 22px;
        line-height: 1.5em;
        margin-top: 20px;
        text-align: center;
    }

    #supportImage {
        margin: 0 auto;
        text-align: center;
        width: 120px;
        height: 120px;
    }

</style>
