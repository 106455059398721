var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "confirmationImageContainer" }, [
      _vm.commonProperties.success == true
        ? _c("img", {
            staticClass: "confirmationImage",
            attrs: { src: require("../../assets/Images/success.svg") },
          })
        : !_vm.success
        ? _c("img", {
            staticClass: "confirmationImage",
            attrs: { src: require("../../assets/Images/alert.svg") },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "confirmationTextContainer" }, [
      _vm.commonProperties.success
        ? _c("h3", { staticClass: "gradientText" }, [
            _vm._v("\n            " + _vm._s(_vm.successText) + "\n        "),
          ])
        : !_vm.commonProperties.success
        ? _c("h3", { staticClass: "gradientText" }, [
            _vm._v(
              "\n            We encountered an error processing your request. Please try again.\n        "
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }