var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: {
            "step-title":
              "Please choose a security question to help you recover your account.",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("ebaDropdown", {
                ref: "securityQuestionInput",
                attrs: {
                  "data-set": _vm.securityQuestions,
                  invalidText: "You must select a security question",
                  inputID: "securityQuestionInput",
                  "place-holder": "Security Question",
                },
                model: {
                  value:
                    _vm.registrationData.SecurityQuestionDetails
                      .SecurityQuestion,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData.SecurityQuestionDetails,
                      "SecurityQuestion",
                      $$v
                    )
                  },
                  expression:
                    "registrationData.SecurityQuestionDetails.SecurityQuestion",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "securityAnswerInput",
                attrs: {
                  "input-type": "password",
                  invalidText: _vm.securityAnswerInvalidText,
                  inputID: "securityAnswerInput",
                  helpText:
                    "Your security answer should be between 3-50 characters long",
                  validation: _vm.securityValidationRegex,
                  "place-holder": "Security Answer",
                },
                model: {
                  value:
                    _vm.registrationData.SecurityQuestionDetails.SecurityAnswer,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData.SecurityQuestionDetails,
                      "SecurityAnswer",
                      $$v
                    )
                  },
                  expression:
                    "registrationData.SecurityQuestionDetails.SecurityAnswer",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "securityAnswerConfirmationInput",
                attrs: {
                  "input-type": "password",
                  invalidText: _vm.confirmSecurityAnswerInvalidText,
                  inputID: "securityAnswerConfirmationInput",
                  validation: _vm.securityValidationRegex,
                  "place-holder": "Confirm Security Answer",
                },
                model: {
                  value:
                    _vm.registrationData.SecurityQuestionDetails
                      .ConfirmSecurityAnswer,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData.SecurityQuestionDetails,
                      "ConfirmSecurityAnswer",
                      $$v
                    )
                  },
                  expression:
                    "registrationData.SecurityQuestionDetails.ConfirmSecurityAnswer",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }