<template>
    <div class="container">
        <div class="header">
            <div class="image">
                <img src="../../assets/Images/discount.svg" />
            </div>
            <span class="gradientText">
                <strong>{{commonProperties.customerName}}</strong>, you are one of our favourites and
                <br />we don't want to see you go...
            </span>
        </div>
        <div class="spacer-line"></div>
        <div class="text">
            <div class="text-center">
                <p class="gradientText text-center">
                    <strong>
                        Let us make you an offer you can't refuse.
                        <br />A huge saving for 6 months, that's only...
                    </strong>
                </p>
                <div class="price">
                    <p class="gradientText">£12.50</p>
                    <br />
                    <p class="sub gradientText">per month</p>
                </div>
                <p class="price-summary text-center">
                    <strong>Cancel anytime</strong>
                </p>
            </div>

            <div class="benefits">
                <p class="gradientText">
                    <strong>Your premium benefits include:</strong>
                </p>
                <span>
                    The most accurate and fully comprehensive view
                    of your own business credit profile.
                </span>
                
                <span>
                    Receive alerts about any changes so you can act
                    quickly to reduce any negative impact on your score.
                </span>
                <span>Premium customer support (phone, email and chat).</span>
            </div>
            <div class="smallprint">
                <span class="tc">Please note you may be required to re-authenticate your payment method via email.</span>
                <span>
                    The offer will provide the Full My Business Profile product for £12.50 for 6 months.
                    After 6 months your subscription will revert back to your current subscription amount.
                    You can still cancel the product at any time.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StepTwo",
    data() {
        return {
            customerName: ""
        };
    },
    props: {
        commonProperties: {
            default: null,
            type: Object
        }
    },
    mounted() {
        const context = this;
        this.$emit("generateNavButtons", [
            {
                type: "secondary",
                text: "No thanks",
                action() {
                    context.$emit("action", [
                        "progressToStep",
                        "ebaSubCancellationStepThree"
                    ]);
                }
            },
            {
                type: "primary",
                text: "Yes, I'll take it",
                action() {
                    context.$emit("action", ["downgradePlan", false]);
                }
            }
        ]);
    }
};
</script>

<style lang="less" scoped>
@import "../../components/common/assets/css/colours.less";

.container {
    .text {
        margin-top: 0.5em;
        .text-center {
            text-align: center;
        }
        p {
            margin-top:0.1em;
            margin-bottom:0.1em;
        }
        .price {
            text-align: center;
            margin: 0.1em;

            p {
                font-size: 5em;
                white-space: pre-wrap;
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0.1em;
                margin-bottom:0.1em;
            }
            padding-top: 0;
            padding-bottom: 0;

            .sub {
                margin-top: -1.1em;
                font-size: 2em;
            }
        }

        .text-center {
            text-align: center;
        }
        .price-summary {
            padding-top: 0;
            color: @experian-success;
        }

        .smallprint {
            margin-top: 1em;
            text-align: center;
            font-size: 0.5em;
            span {
                color: @experian-dark-grey;
            }
            .tc {
                color: @experian-warning;
            }
        }

        .benefits {
            p,span {
                margin-top: 0.5em;
            }
        }
    }
}
</style>

