<template>
    <div>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Please enter a password.">
            <div>
                <ebaTextInput ref="password" inputID="passwordInput"
                              autocomplete='new-password'
                              input-type="password"
                              place-holder="Enter Password"
                              :invalidText="passwordInvalidText"
                              v-model="registrationData.Password"/>
                <ebaCriteriaList ref="passwordList"/>
                <ebaTextInput ref="confirmedPassword" inputID="confirmPasswordInput"
                              autocomplete='new-password'
                              input-type="password"
                              place-holder="Confirm Password"
                              :invalidText="confirmPasswordInvalidText"
                              v-model="registrationData.ConfirmPassword"/>
                <ebaCriteriaList ref="confirmPasswordList"/>
            </div>
        </ebaRegistrationStep>
    </div>
</template>

<script>
  import validationHandler from '../../scripts/common/validationHandler';

    export default {
        name: 'password',
        props: {
            registrationData: {
                type: Object
            }
        },
        data() {
            return {
                passwordValidationRegex: '',
                passwordInvalidText: '',
                confirmPasswordInvalidText: '',

                passwordCriteria: [],
                confirmPasswordCriteria: []
            };
        },
        mounted() {
            this.$emit('clearCustomProceedText');
            this.$emit('clearHeaderText');

            this.passwordCriteria.push({
                description: '8 or more characters',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        var regex = new RegExp('^.{8,56}$');
                        if (regex.test(value.trim())) {
                            resolve();
                        } else {
                            reject();
                        }
                    });
                }
            });

            this.passwordCriteria.push({
                description: '1 or more numbers',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        var regex = new RegExp('(?=.*[0-9]{1})');
                        if (regex.test(value)) {
                            resolve();
                        } else {
                            reject();
                        }
                    });
                }
            });

            this.passwordCriteria.push({
                description: '1 uppercase letter',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        var regex = new RegExp('(?=.*[A-Z])');
                        if (regex.test(value)) {
                            resolve();
                        } else {
                            reject();
                        }
                    });
                }
            });

            this.passwordCriteria.push({
                description: '1 lowercase letter',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        var regex = new RegExp('(?=.*[a-z])');
                        if (regex.test(value)) {
                            resolve();
                        } else {
                            reject();
                        }
                    });
                }
            });

            this.passwordCriteria.push({
                description: '1 symbol',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        var regex = new RegExp('(?=.*[$@$!%*#?&])');
                        if (regex.test(value)) {
                            resolve();
                        } else {
                            reject();
                        }
                    });
                }
            });

            this.passwordCriteria.push({
                description: 'NO spaces',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        if (value === '' || value.indexOf(' ') > 0) {
                            reject();
                        }

                        resolve();
                    });
                }
            });

            this.passwordCriteria.push({
                description: 'Must NOT be the same as your email address',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                         //Check password and email address are not same
                         let checkArr = [
                            self.registrationData.EmailAddress
                        ]
                        if (validationHandler.arrayUniqueValueCheck(value, checkArr) === false) {
                            reject();
                        }

                        resolve();
                    });
                }
            });

            this.passwordCriteria.push({
                description: 'Must NOT include your email address',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                         //Check password doesn't include email address
                         let checkArr = [
                            self.registrationData.EmailAddress
                        ]
                        if (validationHandler.arrayContainValueCheck(value, checkArr) === false) {
                            reject();
                        }

                        resolve();
                    });
                }
            });

            this.$refs.passwordList.setObjectReference(this.$refs.password);
            this.$refs.passwordList.setCriteria(this.passwordCriteria);

            var self = this;
            this.confirmPasswordCriteria.push({
               description: 'Passwords must match',
               requirement: (value) => {
                   return new Promise((resolve, reject) => {
                      if (self.registrationData.Password !== self.registrationData.ConfirmPassword) {
                          reject();
                      }

                      resolve();
                   });
               }
            });

            this.$refs.confirmPasswordList.setObjectReference(this.$refs.confirmedPassword);
            this.$refs.confirmPasswordList.setCriteria(this.confirmPasswordCriteria);

            this.$refs.password.$el.getElementsByTagName('input')[0].focus();
        },
        methods: {
            isStepValid: function () {
                let self = this;

                return new Promise((resolve) => {
                    let passwordValidPromise = self.$refs.passwordList.isCriteriaValid();
                    let confirmPasswordValidPromise = self.$refs.confirmPasswordList.isCriteriaValid();

                    Promise.all([
                        passwordValidPromise,
                        confirmPasswordValidPromise,
                    ])
                        .then((result) => {
                            if (result.indexOf(false) >= 0) {
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                        });
                });
            },
            clearPasswords: function () {
                this.registrationData.Password = null;
                this.registrationData.ConfirmPassword = null;
            }
        }
    };

</script>

<style lang="less" scoped>

</style>