const local = {

    ApplicationId: '88E84135-F72B-4267-BEB3-6A2F2353B147',

    AntiForgeryAPI: 'http://www.mybusinessprofile.local/api/Api.AntiForgery/api/antiforgery/tokens/',
    AntiForgeryCookieDomain: 'localhost',

    BusinessSearchAPI: 'http://www.mybusinessprofile.local/api/BusinessSearch/api/business-target/',
    RegistrationAPI: 'http://www.mybusinessprofile.local/api/MBPRegistration/api/',
    DirectorSearchAPI: 'http://www.mybusinessprofile.local/api/directorSearch/api/officer/directorsByRegNo/',
    UraAPI: 'http://www.mybusinessprofile.local/api/ura/api/ura/search',
    AuthenticateIiqAPI: "http://www.mybusinessprofile.local/api/Authentication/",
    EventTracking: "http://www.mybusinessprofile.local/api/SessionTracking/Create",
    PinAPI: 'http://www.mybusinessprofile.local/api/PIN/api/personalAuth/',
    QASApi: 'http://www.mybusinessprofile.local/api/Api.Qas/api/address',
    AuthenticationEndpoint: 'http://www.mybusinessprofile.local/Security/IsUserLoggedIn',
    LoginEndpoint: 'http://www.mybusinessprofile.local/Account/LoginAsync',
    TermsAndConditions: 'http://www.mybusinessprofile.local/Docs/MBP-Terms-and-Conditions-040124.pdf',
    PromotionalTermsAndConditions: 'http://www.mybusinessprofile.local/Docs/mbp-tsandcs.pdf',
    ThirdPartyServices: 'https://www.experian.co.uk/legal/privacy-statement.html',
    CancellationEndPoint: 'http://www.mybusinessprofile.local/cancellation',
    WebFilingLink : "https://idam-ui.company-information.service.gov.uk/",

    UraPurpose: 'Riskdisk MBP QA Testing',

    GeneralEnquiryEmail: 'mbp.info@experian.com',
    GeneralEnquirySubject: 'MBP - General Enquiry',
    GeneralEbeEnquiryEmail: 'ebe.info@experian.com',

    NonLimReport: 'https://www.experian.co.uk/assets/consumer/stat-report/Stat-credit-report-app-non-limited.pdf',

    ProductCode: '',
    ProductVariantSku: '',

    footerDetails: {
        contactUsText: 'Sales & Help',
        supportPhoneNumber: '0345 045 8081',
        supportEmailAddress: 'mbp.info@experian.com',
        callChargeText: '(Calls charged at standard rate plus your phone company\'s access charge - please see FAQ\'s)',

        footerLinks: [
            {
                text: 'Terms & Conditions',
                url: 'https://www.experian.co.uk/assets/business-express/mbp-tsandcs.pdf'
            },
            {
                text: 'Privacy & Cookies',
                url: 'https://www.experian.co.uk/bi-data-privacy-policy.html'
            },
            {
                text: 'Report a Problem',
                url: 'mailto:mbp.info@experian.com'
            },
            {
                text: 'Help & FAQ\'s',
                url: 'https://www.mybusinessprofile.com/help'
            },
            {
                text: 'Statutory Report',
                url: 'https://www.mybusinessprofile.com/statutory'
            }
        ]
    },

    registrationOrder: [
        'ContactDetails',
        'Password',
        'MemorableWord',
        'SecurityQuestions',
        'PersonalDetails',
        'QASSelect',
        'PreviousAddress',
        'TermsAndConditions',
        'CommsAndMarketing',
        'BillingDetails'
    ],

    StripePublishableKey: 'pk_test_SopmCdY2ocQ5fZufSVqEByhf'
};

module.exports = local;
