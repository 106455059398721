var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showModal
    ? _c(
        "ebaModal",
        {
          attrs: { showTopClose: false, width: 720 },
          on: {
            close: function ($event) {
              _vm.showModal = false
              _vm.clickAway()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "body", attrs: { slot: "body" }, slot: "body" },
            [
              _c("div", { staticClass: "alertIcon" }, [
                _c("img", {
                  attrs: {
                    src: require("../assets/images/alert.svg"),
                    alt: "Warning!",
                  },
                }),
              ]),
              _vm._v(" "),
              _vm._t("default", function () {
                return [
                  _c("h3", { staticClass: "gradientText errorMessage" }, [
                    _vm._v(
                      "\n                We encountered an error processing your details.\n                "
                    ),
                    _c("br"),
                    _vm._v("\n                Please email us at "),
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.buildContactHref(), target: "_top" },
                      },
                      [_c("strong", [_vm._v(_vm._s(_vm.supportEmail))])]
                    ),
                    _vm._v(
                      "\n                to resolve this issue.\n            "
                    ),
                  ]),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showEmailFooter === true,
                      expression: "showEmailFooter === true",
                    },
                  ],
                },
                [
                  _c(
                    "ebaButton",
                    {
                      staticClass: "continueCTA",
                      attrs: { styleType: "raised" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.createEmail.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Email us")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCloseFooter === true,
                      expression: "showCloseFooter === true",
                    },
                  ],
                },
                [
                  _c(
                    "ebaButton",
                    {
                      staticClass: "continueCTA",
                      attrs: { styleType: "raised" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.close.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }