var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: {
            "step-title":
              "Have you lived at this address for at least 12 months?",
            showStepNumber: false,
          },
        },
        [
          _c(
            "div",
            [
              _c("ebaSwitch", {
                ref: "recentAddress",
                on: { onChange: _vm.switchOnChange },
                model: {
                  value: _vm.registrationData.IsRecentAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "IsRecentAddress", $$v)
                  },
                  expression: "registrationData.IsRecentAddress",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ebaRegistrationStep",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAddressEntry,
              expression: "showAddressEntry",
            },
          ],
          ref: "RegistrationStep",
          attrs: {
            "step-title": "What's your previous home address?",
            showStepNumber: false,
          },
        },
        [
          _c(
            "div",
            [
              _c("ebaTextInput", {
                ref: "houseNumberName",
                attrs: {
                  autocomplete: "address-line1",
                  inputID: "houseNumberNameInput",
                  invalidText: "You must specify a Building Number/Name",
                  "place-holder": "Building Number/Name",
                  validation: _vm.anyCharacterValidationRegex,
                },
                model: {
                  value: _vm.registrationData.SearchPreviousHouseNumberName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData,
                      "SearchPreviousHouseNumberName",
                      $$v
                    )
                  },
                  expression: "registrationData.SearchPreviousHouseNumberName",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "postCode",
                attrs: {
                  autocomplete: "postal-code",
                  inputID: "postcodeInput",
                  invalidText: "You must specify a valid postcode",
                  "place-holder": "Postcode",
                  validation: _vm.postcodeValidationRegex,
                },
                model: {
                  value: _vm.registrationData.SearchPreviousPostcode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData,
                      "SearchPreviousPostcode",
                      $$v
                    )
                  },
                  expression: "registrationData.SearchPreviousPostcode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("br", { staticStyle: { clear: "both" } }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }