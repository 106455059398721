<template>
    <ebaModal
            v-if="showModal"
            :showTopClose="false"
            :width="320">
        <div slot="body">
            <div class="loading">
                <ebaSpinner :useAlt="true"/>
                <div class="loadingText gradientText">
                    Please Wait
                </div>
            </div>
        </div>
    </ebaModal>
</template>

<script>

    export default {
        name: 'commonLoadingModal',
        data() {
          return {
              showModal: false
          }
        },
        methods: {
            show() {
                this.showModal = true;
            },
            close() {
                this.showModal = false;
            }
        }
    };

</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    .loading {
        text-align: center;
    }

    .loading svg {
        width: 96px;
        height: 96px;
        padding: 18px;
    }

    .loadingText {
        font-size: 22px;
        margin: 18px 0;
        display: block;
    }

</style>
