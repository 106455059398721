<template>
    <div class="registrationPage">
        <ebaCommonErrorModal ref="commonErrorModal"/>
        <div class="registrationContainer">
            <ebaRegistrationStep :step-title="QuestionTitle" :showStepNumber="false">
                <div style="margin-top: 20px;">
                    <input type="hidden" name="hdQuestionID" :value="QuestionID" />
                    <ebaDropdown ref="question" inputID="iiqQuestion"
                                 :disabled="IsDropdownDisabled"
                                 :place-holder="QuestionPlaceHolder" :help-text="QuestionHelpText"
                                 invalidText="You must select an answer"
                                 v-model="DropdownModel"
                                 :data-set="QuestionAnswers"/>
                </div>
                <div class="navigationButton">
                    <ebaButton style-type="raised" :isLoading="IsLoading" v-on:click.native="submitIIQ">
                        {{ButtonText}}
                    </ebaButton>
                </div>
                <br style="clear: both;"/>
            </ebaRegistrationStep>
        </div>
    </div>
</template>

<script>

    import RegistrationHandler from '../../scripts/registrationHandler';

    export default {
        name: 'iiqQuestions',
        props: {
            registrationData: {
                type: Object
            }
        },
        mounted() {
            var self = this;
            var userID = self.$store.state.authentication.userID;

            //Invalid data, redirect to home
            if (!userID) {
                self.$router.push({
                    name: 'Home'
                });
            } else {

                self.$emit('setCustomHeaderText', {
                    text: 'We need to ask you a few questions to confirm your identity'
                });

                self.setLoading(true);

                // Populate first set of IIQ Questions
                RegistrationHandler.getAuthenticationQuestions(userID)
                    .then(response => {
                        self.IncomingResponse = response;
                        if (response.SaaResult.Questions) {
                            for (var i = 0; i < response.SaaResult.Questions.length; i++) {
                                self.Questions.push(response.SaaResult.Questions[i]);
                            }

                            self.setQuestion(self.Questions[self.index]);
                        } else {
                            self.$refs.commonErrorModal.show();
                        }
                    })
                    .catch((error) => {
                        self.$refs.commonErrorModal.show();
                    })
                    .finally(() => {
                        self.setLoading(false);
                    });
            }
        },
        data: function () {
            return {
                index: 0,
                Questions: [],

                IIQQuestionResult: [],
                DropdownModel: undefined,

                IncomingResponse: {},

                QuestionID: '',
                QuestionTitle: '',
                QuestionPlaceHolder: '',
                QuestionAnswers: [],

                QuestionHelpText: '',

                IsLoading: false,
                IsDropdownDisabled: false,
                ButtonText: 'Next',
            };
        },
        methods: {
            submitIIQ() {

                //Validate
                if (this.DropdownModel === undefined) {
                    this.$refs.question.invalidate();
                } else {

                    // Get the original question object out of the array to update the SelectedOption property
                    let originalQuestion = this.IncomingResponse.SaaResult.Questions.filter(obj => {
                        return obj.Id.Value === this.QuestionID;
                    })[0];

                    originalQuestion.Answer.SelectedOption = this.DropdownModel;

                    // Reset the model
                    this.DropdownModel = undefined;

                    if (this.Questions[this.index] !== null && this.Questions[this.index] !== undefined) {
                        this.setQuestion(this.Questions[this.index]);
                    } else {
                        this.setLoading(true);

                        // Submit data to API
                        const userID = this.$store.state.authentication.userID;
                        RegistrationHandler.submitAuthenticationAnswers(userID, this.IncomingResponse)
                            .then(response => {
                                if (response) {
                                    console.log(response);
                                    let outcomeResult = response;
                                    if (outcomeResult === 1) {
                                        this.$router.push({
                                            name: 'RegistrationComplete'
                                        });
                                    } else {
                                        this.$router.push({
                                            name: 'PinSent'
                                        });
                                    }
                                }
                            })
                            .catch(error => {
                                console.log(error);
                                self.$refs.commonErrorModal.show();
                            })
                            .finally(() => {
                                this.setLoading(false);
                            });
                    }
                }
            },
            setQuestion(Question) {
                this.QuestionID = Question.Id.Value;
                this.QuestionTitle = Question.Text;
                this.QuestionPlaceHolder = 'Please Select an Answer';

                // Clear any existing questions
                this.QuestionAnswers = [];
                for (var i = 0; i < Question.Answer.Options.length; i++) {
                    this.QuestionAnswers.push({
                        name: Question.Answer.Options[i],
                        value: Question.Answer.Options[i]
                    });
                }

                this.QuestionHelpText = Question.Tooltip;
                this.index += 1;
            },
            setLoading(loading) {
                if (loading === true) {
                    this.IsLoading = true;
                    this.IsDropdownDisabled = true;
                } else {
                    this.IsLoading = false;
                    this.IsDropdownDisabled = false;
                }
            }
        }
    };
</script>

<style scoped lang="less">

    @import '../../components/common/assets/css/common.less';

    .bodyText {
        text-align: left;
        font-size: 18px;
        line-height: 24px;
    }

    .registrationPage {
        padding-top: 24px;
    }

    .registrationHeader {
        text-align: center;
        margin: 0 auto 8px auto;
        padding: 4px 32px 12px 32px;
        font-size: 22px;
        line-height: 28px;
    }

    .registrationContainer {
        width: 45%;
        margin: 0 auto 54px auto;
        padding: 32px 32px 24px 32px;
        -webkit-box-shadow: 0 0 8px @experian-dark-medium-grey;
        -moz-box-shadow: 0 0 8px @experian-dark-medium-grey;
        box-shadow: 0 0 8px @experian-dark-medium-grey;
        z-index: 11;
    }

    .navigationButton {
        margin: 24px 0 -14px -30px;
        float: right;
    }

    @media only screen and (max-width: 1024px) {
        .registrationContainer {
            width: 80%;
            margin: 0 auto 48px auto;
        }
    }

    @media only screen and (max-width: 560px) {

        .bodyText {
            font-size: 13px;
            line-height: 22px;
        }

        .registrationContainer {
            width: 90%;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }

        .navigationButton {
            margin-top: 12px;
        }
    }

</style>
