import * as types from './registrationMutations';
import antiForgeryHandler from '../../../../scripts/common/antiForgeryHandler';

const config = require('../../../../scripts/common/config.js').get();

let registrationOrder = config.registrationOrder;

//Initialise the state as the first step in the registrationOrder array
const state = {
    currentRegistrationStep: registrationOrder[0],
    stepProgress: [registrationOrder[0]],
    refresh: true,
    formToken: '',
    previousAddressEnabled: false,
    isNextEnabled: false
};

const getters = {
    getCurrentStep: state => {
        return state.currentRegistrationStep;
    },
    getCurrentStepIndex: state => {
        return getCurrentStepIndex(state);
    },
    getCurrentStepFromName(state) {
        return function (args) {
            for (var i = 0; i < registrationOrder.length; i++) {
                var temp = registrationOrder[i];
                if (temp === args) {
                    return i + 1;
                }
            }
        };
    },
    getPreviousExist: state => {
        return getCurrentStepIndex(state) > 0;
    },
    isFinalStep: state => {
        return getCurrentStepIndex(state) === registrationOrder.length - 1;
    },
    getStepLength: state => {
        return registrationOrder.length;
    },
    getFormToken: state => {
        return state.formToken;
    },
    getIsNextEnabled: state=> {
        return state.isNextEnabled;
    }
};

const mutations = {
    moveToNextStep: state => {
        var currentIndex = getCurrentStepIndex(state);
        if (currentIndex + 1 <= registrationOrder.length) {
            state.currentRegistrationStep = registrationOrder[currentIndex + 1];
            state.stepProgress.push(state.currentRegistrationStep);
        }
    },
    moveToPreviousStep: state => {
        var currentIndex = getCurrentStepIndex(state);
        if (currentIndex - 1 >= 0) {
            state.currentRegistrationStep = registrationOrder[currentIndex - 1];
        }
    },
    setStepFromName(state, payload) {
        //Forgery check
        if (state.stepProgress.indexOf(payload) < 0) {
            state.currentRegistrationStep = state.stepProgress[0];
            return;
        }

        // Check to make sure the step exists in the array
        var index = registrationOrder.indexOf(payload);
        if (index >= 0 && !state.refresh) {
            state.currentRegistrationStep = payload;
        }

        state.refresh = false;
    },
    setPageToken(state, payload) {
        state.formToken = payload;
    },
    setupPreviousAddressState(state) {
        if (!state.previousAddressEnabled) {
            var currentIndex = getCurrentStepIndex(state);
            registrationOrder.splice(currentIndex + 1, 0, 'QASPreviousSelect');

            state.previousAddressEnabled = true;
        }
    },
    removePreviousAddressState(state) {
        var index = registrationOrder.indexOf('QASPreviousSelect');
        if (index >= 0) {
            registrationOrder.splice(index, 1);
        }

        state.previousAddressEnabled = false;
    },
    updateToPepsRoute() {
        registrationOrder.splice(1);
        registrationOrder.push('ContactCustomerSupport');
    },
    updateIsNextEnabled(state, value) {
        state.isNextEnabled = value;
    }
};

const actions = {
    moveToNextStep({commit, state}) {
        return new Promise((resolve, reject) => {
            try {
                commit(types.MOVE_TO_NEXT_STEP);
                resolve(state.currentRegistrationStep);
            } catch (error) {
                reject(error);
            }
        });
    },
    moveToPreviousStep({commit, state}) {
        return new Promise((resolve, reject) => {
            try {
                commit(types.MOVE_TO_PREVIOUS_STEP);
                resolve(state.currentRegistrationStep);
            } catch (error) {
                reject(error);
            }
        });
    },
    setStepFromName({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SET_STEP_FROM_NAME, payload);
            resolve();
        });
    },
    setPageToken({commit, state}) {
        return new Promise((resolve, reject) => {
            try {
                antiForgeryHandler.requestAntiForgeryToken()
                    .then((resp) => {
                        commit(types.SET_PAGE_TOKEN, resp);
                        resolve(state.formToken);
                    });
            } catch (error) {
                reject(error);
            }

        });
    },
    setupPreviousAddress({commit}) {
        return new Promise((resolve, reject) => {
            try {
                commit(types.SETUP_PREVIOUS_ADDRESS);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    },
    removePreviousAddress({commit}) {
        return new Promise((resolve, reject) => {
            try {
                commit(types.REMOVE_PREVIOUS_ADDRESS);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    },
    updateToPepsRoute({commit}) {
        return new Promise((resolve, reject) => {
           try {
                commit(types.UPDATE_TO_PEPS_ROUTE);
                resolve();
           } catch (error) {
               reject(error);
           }
        });
    },
    updateIsNextEnabled({commit}, payload) {
        return new Promise((resolve, reject) => {
            try {
                commit(types.UPDATE_IS_NEXT_ENABLED, payload.isNextEnabled);
                resolve();
            } catch(error) {
                reject(error);
            }
        })
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

function getCurrentStepIndex(state) {
    return registrationOrder.indexOf(state.currentRegistrationStep);
}
