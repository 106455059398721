var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-authentication registration-background" },
    [
      _c(
        "ebaRegistrationStep",
        { ref: "RegistrationStep", attrs: { "step-title": "Card Details." } },
        [
          _c(
            "div",
            [
              _c(
                "ebaError",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.PaymentFailedFlag === true,
                      expression: "PaymentFailedFlag === true",
                    },
                  ],
                },
                [
                  _c("strong", [
                    _vm._v(
                      "\n                    There was an error trying to process your payment. No charges have been made.\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    Please check your details or try a different card.\n                "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "ebaError",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.paymentError,
                      expression: "paymentError",
                    },
                  ],
                },
                [_c("strong", [_vm._v(_vm._s(_vm.paymentError))])]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "stripeContainer" },
                [
                  _c(
                    "div",
                    { staticClass: "stripe-field" },
                    [
                      _c("ebaTextInput", {
                        ref: "cardNameRef",
                        attrs: {
                          inputID: "cardNameInput",
                          "place-holder": "Name on Card",
                          validation: _vm.cardNameRegex,
                        },
                        model: {
                          value: _vm.cardName,
                          callback: function ($$v) {
                            _vm.cardName = $$v
                          },
                          expression: "cardName",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.cardNameError !== undefined,
                              expression: "cardNameError !== undefined",
                            },
                          ],
                          staticClass: "card-errors",
                        },
                        [_vm._v(_vm._s(_vm.cardNameError))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "stripe-field" }, [
                    _c("label", { staticClass: "stripe-label" }, [
                      _vm._v("Card Number"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      ref: "cardNumberElement",
                      attrs: { id: "cardNumberElement" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "stripe-field" }, [
                    _c("label", { staticClass: "stripe-label" }, [
                      _vm._v("Expiry Date"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      ref: "cardExpiryElement",
                      attrs: { id: "cardExpiryElement" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "stripe-field" }, [
                    _c("label", { staticClass: "stripe-label" }, [
                      _vm._v("Security Code"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      ref: "cardCvcElement",
                      attrs: { id: "cardCvcElement" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("ebaInfo", [
                    _c("p", [
                      _vm._v(
                        "\n                        If you cancel before the end of your trial you won’t be charged\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("img", {
                      staticClass: "stripeLogo",
                      attrs: {
                        src: require("../../components/common/assets/images/payment/powered_by_stripe.svg"),
                        alt: "Powered by Stripe",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "cardLogoContainer" }, [
                      _c("img", {
                        staticClass: "cardLogo",
                        attrs: {
                          src: require("../../components/common/assets/images/payment/visa.svg"),
                          alt: "visa",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "cardLogo",
                        attrs: {
                          src: require("../../components/common/assets/images/payment/americanexpress.svg"),
                          alt: "maestro",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "cardLogo",
                        attrs: {
                          src: require("../../components/common/assets/images/payment/mastercard.svg"),
                          alt: "mastercard",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("br", { staticStyle: { clear: "both" } }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: { "step-title": "Billing Address.", showStepNumber: false },
        },
        [
          _c(
            "div",
            [
              _c("ebaTextInput", {
                ref: "billingName",
                attrs: {
                  invalidText: _vm.billingNameErrorMessage,
                  validation: _vm.specialCharactersRegex,
                  inputID: "billingName",
                  "place-holder": "Name or Number",
                },
                model: {
                  value: _vm.registrationData.BillingDetails.NameOrNumber,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData.BillingDetails,
                      "NameOrNumber",
                      $$v
                    )
                  },
                  expression: "registrationData.BillingDetails.NameOrNumber",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "billingStreet",
                attrs: {
                  inputID: "billingStreet",
                  "no-validation": true,
                  "place-holder": "Street",
                },
                model: {
                  value: _vm.registrationData.BillingDetails.Street,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData.BillingDetails, "Street", $$v)
                  },
                  expression: "registrationData.BillingDetails.Street",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "billingTownOrCity",
                attrs: {
                  invalidText: _vm.billingTownErrorMessage,
                  validation: _vm.specialCharactersRegex,
                  inputID: "billingTownOrCity",
                  "place-holder": "Town or City",
                },
                model: {
                  value: _vm.registrationData.BillingDetails.TownOrCity,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData.BillingDetails,
                      "TownOrCity",
                      $$v
                    )
                  },
                  expression: "registrationData.BillingDetails.TownOrCity",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "billingPostcode",
                attrs: {
                  invalidText: "You must specify a postcode",
                  inputID: "billingPostcode",
                  "place-holder": "Postcode",
                  validation: _vm.postcodeRegex,
                },
                model: {
                  value: _vm.registrationData.BillingDetails.Postcode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData.BillingDetails,
                      "Postcode",
                      $$v
                    )
                  },
                  expression: "registrationData.BillingDetails.Postcode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("br", { staticStyle: { clear: "both" } }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }