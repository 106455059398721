import Vue from 'vue';
import VueRouter from 'vue-router';
import ComponentRepository from '../scripts/service/componentRepository';
import Store from '../vuex/store.js';
import * as Mutations from '../vuex/common/modules/authentication/authenticationMutations.js';

import EventTrackingHandler from '../scripts/common/eventTrackingHandler';
import {get as config} from '../scripts/common/config';

const pageObj = ComponentRepository.registerPages();

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    },
    routes: [
        {
            name: 'Redirect',
            path: '/login-redirect',
            component: pageObj.Redirect
        },
        {
            name: 'Home',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.landingPage
            },
            path: '/home',
            component: pageObj.Home,
            beforeEnter: (to, from, next) => {
                if (Store.state.authentication.isAuthenticated) {
                    next({
                        name: 'Redirect'
                    });
                } else {
                    next();
                }
            },
        },
        {
            name: 'NonLimitedReport',
            path: '/nonlimited',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.NonLimReport
            },
            beforeEnter: (to, from, next) => {
                window.location.href = config().NonLimReport;
            }
        },
        {
            name: 'ContactCustomerSupport',
            path: '/contactcustomersupport',
            component: pageObj.ContactCustomerSupport,
            meta: {
                eventID: EventTrackingHandler.trackingEvents.pepsCustomerSupportConfirmation,
                noMoveTop: true
            }
        },

        //Registration pages
        {
            name: 'Registration',
            path: '/register',
            component: pageObj.Registration.Registration,
            children: [
                {
                    name: 'ContactDetails',
                    path: 'contactDetails',
                    component: pageObj.Registration.ContactDetails,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.contactDetailsPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'Password',
                    path: 'password',
                    component: pageObj.Registration.Password,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.passwordPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'SecurityQuestions',
                    path: 'securityQuestions',
                    component: pageObj.Registration.SecurityQuestions,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.securityQuestionPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'PersonalDetails',
                    path: 'personalDetails',
                    component: pageObj.Registration.PersonalDetails,
                    props: true,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.personalDetailsPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'PreviousAddress',
                    path: 'previousAddress',
                    component: pageObj.Registration.PreviousAddress,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.addressSelectionPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'MemorableWord',
                    path: 'memorableWord',
                    component: pageObj.Registration.MemorableWord,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.memWordPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'TermsAndConditions',
                    path: 'termsAndConditions',
                    component: pageObj.Registration.TermsAndConditions,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.termsAndConditionsPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'CommsAndMarketing',
                    path: 'commsAndMarketing',
                    component: pageObj.Registration.CommsAndMarketing,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.marketingDetailsPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'BillingDetails',
                    path: 'billingDetails',
                    component: pageObj.Registration.BillingDetails,
                    props: true,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.paymentPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'QASSelect',
                    path: '/register/QASSelect',
                    component: pageObj.QASSelect,
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.addressSelectionPage,
                        noMoveTop: true
                    }
                },
                {
                    name: 'QASPreviousSelect',
                    path: '/register/QASSelect',
                    component: pageObj.QASSelect,
                    props: {
                        isPreviousAddress: true
                    },
                    meta: {
                        eventID: EventTrackingHandler.trackingEvents.addressSelectionPage,
                        noMoveTop: true
                    }
                }
            ]
        },
        {
            name: 'IIQQuestions',
            path: '/register/iiqQuestions',
            component: pageObj.IIQQuestions,
            meta: {
                eventID: EventTrackingHandler.trackingEvents.iiqPage,
                noMoveTop: true
            }
        },
        {
            name: 'PinEntry',
            path: '/register/pinEntry',
            component: pageObj.PinEntry,
            meta: {
                eventID: EventTrackingHandler.trackingEvents.pinEntryPage,
                noMoveTop: true
            }
        },
        {
            name: 'PinSent',
            path: '/register/pinSent',
            component: pageObj.PinSent,
            meta: {
                eventID: EventTrackingHandler.trackingEvents.pinSentPage,
                noMoveTop: true
            }
        },
        {
            name: 'UraLockout',
            path: '/register/failed-personal-details',
            component: pageObj.UraLockout,
        },
        {
            name: 'DirectorshipTaken',
            path: '/register/directorshipTaken',
            component: pageObj.DirectorshipTaken
        },
        {
            name: 'BusinessResults',
            path: '/register/businessResults',
            component: pageObj.BusinessResults,
            props: true,
            meta: {
                eventID: EventTrackingHandler.trackingEvents.businessResultsPage
            }
        },
        {
            name: 'DirectorSearch',
            path: '/register/directorSearch',
            component: pageObj.DirectorSearch,
            props: true,
            meta: {
                eventID: EventTrackingHandler.trackingEvents.directorSelectionPage
            }
        },
        {
            name: 'RegistrationComplete',
            path: '/registrationComplete',
            beforeEnter: (to, from, next) => {
                window.location.href = window.location.origin + '/registration/complete';
            }
        },

        //Account
        {
            name: 'Login',
            path: '/Login',
            beforeEnter: (to, from, next) => {
                window.location.href = window.location.origin + '/account/login';
            }
        },
        {
            name: 'Logout',
            path: '/Logout',
            beforeEnter: (to, from, next) => {
                window.location.href = window.location.origin + '/account/logoff';
            }
        },
        {
            name: 'Report',
            path: '/report',
            beforeEnter: (to, from, next) => {
                window.location.href = window.location.origin + '/report';
            }
        },
        {
            name: 'CancellationOffer',
            path: '/home/cancellationOffer',
            component: pageObj.CancellationOffer,
            beforeEnter: (to, from, next) => {
                if (!Store.state.authentication.isAuthenticated) {
                    router.push('/login-redirect', () => {});
                } else {
                    next();
                }
            },
        },

        // Footer links
        {
            name: 'FAQs',
            path: '/FAQs',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.helpFaqLink
            },
            beforeEnter: (to, from, next) => {
                window.location.href = window.location.origin + '/help';
            }
        },
        {
            name: 'ViewTermsAndConditions',
            path: '/ViewTermsAndConditions',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.termsLink
            },
            beforeEnter: (to, from, next) => {
                let link = config().TermsAndConditions;
                window.open(link, '_blank');
            }
        },
        {
            name: 'Privacy',
            path: '/privacy',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.privacyLink
            },
            beforeEnter: (to, from, next) => {
                window.open(config().PrivacyPolicy, '_blank');
            }
        },
        {
            name: 'ReportAProblem',
            path: '/reportaproblem',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.reportProblemLink
            },
            beforeEnter: (to, from, next) => {
                window.location.href = 'mailto:' + config().GeneralEnquiryEmail;
            }
        },
        {
            name: 'StatutoryReport',
            path: '/statutoryreport',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.statReportLink
            },
            beforeEnter: (to, from, next) => {
                window.location.href = config().StatutoryReport, '_blank';
            }
        },
        {
            name: 'EmailUs',
            path: '/emailUs',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.emailLink
            },
            beforeEnter: (to, from, next) => {
                window.location.href = 'mailto:' + config().GeneralEnquiryEmail;
            }
        },
        {
            name: 'SalesAndHelp',
            path: '/salesAndHelp',
            beforeEnter: (to, from, next) => {
                window.location.href = 'tel:' + config().footerDetails.supportPhoneNumber;
            }
        },

        //Social Media Links
        {
            name: 'LinkedIn',
            path: 'social/linkedin',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.linkedInLink
            },
            beforeEnter: (to, from, next) => {
                window.open('https://www.linkedin.com/company/2534/', '_blank');
            }
        },
        {
            name: 'Facebook',
            path: 'social/facebook',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.facebookLink
            },
            beforeEnter: (to, from, next) => {
                window.open('https://www.facebook.com/ExperianBusinessAssist/', '_blank');
            }
        },
        {
            name: 'YouTube',
            path: 'social/youtube',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.youTubeLink
            },
            beforeEnter: (to, from, next) => {
                window.open('https://www.youtube.com/user/ExperianSME', '_blank');
            }
        },
        {
            name: 'Twitter',
            path: 'social/twitter',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.twitterLink
            },
            beforeEnter: (to, from, next) => {
                window.open('https://twitter.com/ExperianBAssist', '_blank');
            }
        },
        {
            name: 'Blog',
            path: 'social/blog',
            meta: {
                eventID: EventTrackingHandler.trackingEvents.hubLink
            },
            beforeEnter: (to, from, next) => {
                window.open('https://www.experian.co.uk/business-express/hub/', '_blank');
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.meta.eventID !== null && to.meta.eventID !== undefined) {
        EventTrackingHandler.trackEvent({
            Event: to.meta.eventID,
            From: from.name,
            To: to.name
        });
    }

    if (to.meta.secure) {
        // Page requires authentication
        Store.dispatch(Mutations.AUTHENTICATE)
            .then((response) => {
                if (response.isAuthenticated) {
                    next();
                } else {
                    next('/login');
                }
            })
            .catch(() => {
                next('/home');
            });
    } else {
        // Page is stateful depending on authentication
        Store.dispatch(Mutations.AUTHENTICATE)
            .then(() => {
                next();
            })
            .catch(() => {
                next();
            });
    }
});

export default router;
