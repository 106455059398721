<template>
    <div class="textInputGroup">
        <input ref="textInput"
               :id="inputID"
               :autocomplete="autocomplete === true ? 'on' : 'off'"
               :maxlength="maxLength"
               v-bind:value="value"
               v-on:input="updateValue"
               :class="[getValidClass(), isDisabled()]" :type="getInputType()" required :disabled="disabled">
        <div v-if="inputType === 'password'">
            <span :class="[showPasswordClass, 'toggle-password']" v-on:click="togglePassword"></span>
        </div>
        <label :class="[getValidClass(), isDisabled()]">{{placeHolder}}</label>
        <p :class="[getValidClass(), isDisabled()]">{{getLabelText()}}</p>
    </div>
</template>

<script>

    import validationHandler from '../../../scripts/common/validationHandler';

    export default {
        name: 'TextInput',
        props: {
            placeHolder: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            autocomplete: {
                type: Boolean,
                required: false,
                default: true
            },
            inputType: {
                type: String,
                required: false,
                default: 'text'
            },
            inputID: {
                type: String,
                required: false
            },
            helpText: {
                type: String,
                required: false
            },
            invalidText: {
                type: String,
                required: false,
                default: 'Invalid input'
            },
            validation: {
                type: String,
                required: false,
                validator: function (value) {
                    try {
                        new RegExp(value);
                        return true;
                    } catch (e) {
                        return false;
                    }
                }
            },
            noValidation: {
                type: Boolean,
                required: false,
                default: false
            },
            maxLength: {
                type: Number,
                required: false
            },
            value: {}
        },
        created() {
            this.validationState = this.validationStateEnum.INITIAL;
        },
        data() {
            return {
                showPassword: false,
                showPasswordClass: 'exp-icon-eye',
                validationStateEnum: validationHandler.getValidationStates(),
                validationState: null,

                // On Change event
                onChange: undefined,
            };
        },
        methods: {
            isDisabled() {
                if (this.disabled) {
                    return 'disabled';
                }

                return '';
            },
            getInputType() {
                if (this.inputType === 'password') {
                    if (this.showPassword) return 'text';
                    return this.inputType;
                } else if (this.inputType === 'email') {
                    return 'text';
                } else {
                    return this.inputType;
                }
            },
            clearValidation() {
                this.validationState = this.validationStateEnum.INITIAL;
            },
            invalidate() {
                if (!(this.noValidation)) {
                    this.validationState = this.validationStateEnum.INVALID;
                }
            },
            validate() {
                if (this.noValidation) {
                    this.validationState = this.validationStateEnum.INITIAL;
                    return true;
                }

                if (this.value === null || this.value === undefined || this.value === '' || (this.value || '').trim() === '') {
                    this.validationState = this.validationStateEnum.INVALID;
                } else {
                    //Get default validation if not validation is provided
                    var pattern;
                    if (this.validation === null || this.validation === undefined) {
                        pattern = validationHandler.getDefaultInputValidation(this.inputType);
                    } else {
                        pattern = this.validation;
                    }

                    var regex = new RegExp(pattern);
                    if (regex.test(this.value)) {
                        this.validationState = this.validationStateEnum.VALID;
                    } else {
                        this.validationState = this.validationStateEnum.INVALID;
                    }
                }

                return this.validationState !== this.validationStateEnum.INVALID;
            },
            getValidClass() {
                switch (this.validationState) {
                    case this.validationStateEnum.VALID:
                        return 'valid';
                    case this.validationStateEnum.INVALID:
                        return 'invalid';
                    case this.validationStateEnum.INITIAL:
                    default:
                        return 'initial';
                }
            },
            getLabelText() {
                switch (this.validationState) {
                    case this.validationStateEnum.VALID:
                        return this.helpText;
                    case this.validationStateEnum.INVALID:
                        return this.invalidText;
                    case this.validationStateEnum.INITIAL:
                    default:
                        return this.helpText;
                }
            },
            togglePassword() {
                if (this.showPassword) {
                    this.showPassword = false;
                    this.showPasswordClass = 'exp-icon-eye';
                } else {
                    this.showPassword = true;
                    this.showPasswordClass = 'exp-icon-eye-off';
                }
            },
            updateValue(event) {
                this.$emit('input', event.target.value);
                this.$emit('onChange', event.target.value);

                if (this.onChange !== undefined && this.onChange !== null && typeof(this.onChange) === 'function') {
                    this.onChange(event.target.value);
                }

                //Reset validation
                this.validationState = this.validationStateEnum.INITIAL;
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    p {
        margin: 6px 4px 4px;
        font-size: 14px;
    }

    p.initial, p.valid {
        color: @experian-sapphire;
    }

    p.invalid {
        color: @experian-danger-error;
    }

    p.disabled {
        color: @experian-dark-medium-grey;
        -webkit-text-fill-color: @experian-dark-medium-grey;
    }

    .toggle-password {
        cursor: pointer;
        position: relative;
        font-size: 32px;
        color: @experian-dark-medium-grey;
        width: 32px;
        height: 32px;
        float: right;
        top: -32px;
        margin: -8px 8px -8px -8px;
        z-index: 2;
    }

    /* form starting stylings ------------------------------- */
    .textInputGroup {
        position: relative;
        margin-top: 32px;
    }

    input {
        font-size: 18px;
        padding: 10px 0 10px 0;
        display: block;
        width: 100%;
        border: none;
        background: linear-gradient(to left, @experian-light-blue 0%, @experian-purple 100%) left bottom transparent no-repeat;
        background-size: 100% 2px;
    }

    input.invalid {
        background: linear-gradient(to left, @experian-danger-error 0%, @experian-danger-error 100%) left bottom transparent no-repeat;
        background-size: 100% 2px;
        color: @experian-danger-error;
    }

    input.disabled {
        background: linear-gradient(to left, @experian-dark-medium-grey 0%, @experian-dark-medium-grey 100%) left bottom transparent no-repeat;
        background-size: 100% 2px;
        -webkit-text-fill-color: @experian-disabled-grey;
        color: @experian-disabled-grey;
    }

    input.disabled:hover {
        cursor: not-allowed;
    }

    input:focus {
        outline: none;
    }

    /* LABEL ======================================= */
    label {
        color: @experian-dark-medium-grey;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    label.invalid {
        color: @experian-danger-error;
    }

    label.disabled {
        top: -12px;
        font-size: 14px;
        color: @experian-dark-medium-grey;
    }

    /* active state */
    input:focus ~ label, input:valid ~ label {
        top: -12px;
        font-size: 14px;
        background-image: -webkit-linear-gradient(45deg, @experian-purple, @experian-light-blue);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: @experian-light-blue;
    }

        /* BOTTOM BARS ================================= */
    .bar {
        position: relative;
        display: block;
        width: 300px;
    }

    .bar:before, .bar:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: #5264AE;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    .bar:before {
        left: 50%;
    }

    .bar:after {
        right: 50%;
    }

    /* active state */
    input:focus ~ .bar:before, input:focus ~ .bar:after {
        width: 50%;
    }

</style>
