var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "header" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("span", { staticClass: "gradientText" }, [
          _c("strong", [_vm._v(_vm._s(_vm.commonProperties.customerName))]),
          _vm._v(", we're sorry to see you go!\n            "),
          _c("br"),
          _vm._v("Please help us understand the reason why.\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "spacer-line" }),
      _vm._v(" "),
      _c("ebaRadioGroup", {
        attrs: {
          items: _vm.reasons,
          valid: !(_vm.buttonClicked == true && _vm.answer == null),
        },
        on: { "option-selected": _vm.cancellationReasonSelected },
      }),
      _vm._v(" "),
      _vm.buttonClicked == true && _vm.answer == null
        ? _c("span", { staticClass: "validation-text" }, [
            _vm._v("\n        Please choose a cancellation reason\n    "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: { src: require("../../assets/Images/understand.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }