<template>
    <div>
        <ebaCommonErrorModal ref="commonErrorModal"/>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Terms and Conditions.">
            <div class="registrationText">
                We want you to know how our service works and why we need your registration details. Please state that
                you have
                read and agree to <router-link class="registrationLink" :to="{name: 'ViewTermsAndConditions'}">these
                terms</router-link>,
                it's also required to complete the registration process
            </div>
            <div class="registrationInput">
                <ebaCheckbox ref="termsAndConditionsInput"
                             v-model="registrationData.TermsAndConditions"
                             inputID="termsAndConditionsInput"
                             invalidText="You must accept the Terms and Conditions">
                    I agree to the <router-link class="registrationLink" :to="{name: 'ViewTermsAndConditions'}">terms and
                    conditions</router-link>
                </ebaCheckbox>
            </div>
            <br/>
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import {mapState} from 'vuex';
    import registrationHandler from '../../scripts/registrationHandler';
    import {get as config} from '../../scripts/common/config';
    import eventTrackingHandler from '../../scripts/common/eventTrackingHandler';

    export default {
        name: 'TermsAndConditions',
        props: {
            registrationData: {
                type: Object
            }
        },
        computed: {
            ...mapState([
                'Business',
                'Director'
            ])
        },
        methods: {
            isStepValid() {
                var self = this;

                return new Promise(function (resolve, reject) {
                    var isValid = true;

                    if (!self.$refs.termsAndConditionsInput.validate()) {
                        isValid = false;
                    }

                    resolve(isValid);
                });
            },
            handleSubmitAction() {
                return new Promise((resolve, reject) => {

                    //Setup the Ura Model
                    let dob = this.registrationData.DateOfBirth.year + '-' +
                        this.registrationData.DateOfBirth.month + '-' +
                        this.registrationData.DateOfBirth.day + 'T00:00:00.000Z';

                    let urn = this.$store.state.businessSearch.Director.urn;
                    let regNo = this.$store.state.businessSearch.Business.businessRef;


                    let uraModel = {
                        registrationNumber: regNo,
                        urn: urn,
                        firstname: this.$store.state.businessSearch.Director.firstName,
                        surname: this.$store.state.businessSearch.Director.surname,
                        middleNames: this.$store.state.businessSearch.Director.middlesNames,
                        dateOfBirth: dob,
                        currentAddress: {
                            buildingNameNumber: this.registrationData.CurrentAddress.BuildingNameNumber,
                            address: this.registrationData.CurrentAddress.Address,
                            town: this.registrationData.CurrentAddress.Town,
                            postcode: this.registrationData.CurrentAddress.Postcode
                        },
                        previousAddress: {
                            buildingNameNumber: this.registrationData.PreviousAddress.BuildingNameNumber,
                            address: this.registrationData.PreviousAddress.Address,
                            town: this.registrationData.PreviousAddress.Town,
                            postcode: this.registrationData.PreviousAddress.Postcode
                        },
                        Purpose: config().UraPurpose
                    };

                    registrationHandler.createAppointment(urn, regNo)
                        .then((resp) => {
                            if (resp) {
                                registrationHandler.uraCheck(uraModel)
                                    .then((response) => {
                                        if (response) {
                                            if (response.errorOccured === true) {
                                                this.$refs.commonErrorModal.show();
                                                reject();
                                            }

                                            //if the user is not verified send back to personal details page
                                            if (response.verified !== 1) {
                                                  eventTrackingHandler.trackEvent({
                                                  Event: eventTrackingHandler.trackingEvents.uraCheck,
                                                  Details: {
                                                      "UraCheckPassed": false
                                                  }
                                                });
                                                // Resolve invalid here to stop loading animation
                                                resolve({
                                                    invalid: true
                                                });

                                                if (response.attemptsRemaining === 0) {
                                                    eventTrackingHandler.trackEvent({
                                                  Event: eventTrackingHandler.trackingEvents.uraCheck,
                                                  Details: {
                                                      "UraLockout": true
                                                    }
                                                  });
                                                    this.$router.push({
                                                        name: 'UraLockout'
                                                    });
                                                } else {
                                                    var attemptsRemaining = response.attemptsRemaining || 0;
                                                    this.$router.push({
                                                        name: 'PersonalDetails',
                                                        params: {
                                                            showInvalidAttempt: true,
                                                            attemptsRemaining: attemptsRemaining
                                                        }
                                                    });
                                                }
                                            } else {
                                                  eventTrackingHandler.trackEvent({
                                                  Event: eventTrackingHandler.trackingEvents.uraCheck,
                                                  Details: {
                                                      "UraCheckPassed": true
                                                    }
                                                  });
                                                resolve();
                                            }
                                        } else {
                                            reject();
                                        }
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    })
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            }
        }
    };
</script>

<style lang="less" scoped>

</style>
