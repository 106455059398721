<template>
    <div style="float: left;">
        <label class="checkboxContainer">
            <slot>

            </slot>
            <input ref="checkboxInput"
                   v-bind:checked="value"
                   v-on:click="updateValue"
                   type="checkbox" :id="inputID" required>
            <span :class="[getValidClass(), 'checkmark']"></span>
            <p :class="[getValidClass()]">{{getLabelText()}}</p>
        </label>
    </div>
</template>

<script>

    import validationHandler from '../../../scripts/common/validationHandler';

    export default {
        name: 'Checkbox',
        props: {
            inputID: {
                type: String,
                required: false
            },
            helpText: {
                type: String,
                required: false
            },
            invalidText: {
                type: String,
                required: false,
                default: 'Invalid input'
            },
            validation: {
                type: String,
                required: false,
                validator: function (value) {
                    try {
                        new RegExp(value);
                        return true;
                    } catch (e) {
                        return false;
                    }
                }
            },
            noValidation: {
                type: Boolean,
                required: false,
                default: false
            },
            value: {}
        },
        data() {
            return {
                validationStateEnum: validationHandler.getValidationStates(),
                validationState: null
            };
        },
        methods: {
            validate() {
                if (this.noValidation) {
                    this.validationState = this.validationStateEnum.INITIAL;
                    return true;
                }

                if (this.$refs.checkboxInput.checked === true) {
                    this.validationState = this.validationStateEnum.VALID;
                } else {
                    this.validationState = this.validationStateEnum.INVALID;
                }

                return this.validationState !== this.validationStateEnum.INVALID;
            },
            invalidate() {
                if (!(this.noValidation)) {
                    this.validationState = this.validationStateEnum.INVALID;
                }
            },
            getValidClass() {
                switch (this.validationState) {
                    case this.validationStateEnum.VALID:
                        return 'valid';
                    case this.validationStateEnum.INVALID:
                        return 'invalid';
                    case this.validationStateEnum.INITIAL:
                    default:
                        return 'initial';
                }
            },
            getLabelText() {
                switch (this.validationState) {
                    case this.validationStateEnum.VALID:
                        return this.helpText;
                    case this.validationStateEnum.INVALID:
                        return this.invalidText;
                    case this.validationStateEnum.INITIAL:
                    default:
                        return this.helpText;
                }
            },
            updateValue() {
                this.$emit('input', this.$refs.checkboxInput.checked);

                //Reset validation
                this.validationState = this.validationStateEnum.INITIAL;
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    p {
        font-size: 14px;
        margin: -12px 0;
    }

    p.initial {
        color: @experian-sapphire;
    }

    p.invalid {
        color: @experian-danger-error;
    }

    .checkboxContainer {
        display: block;
        line-height: 33px;
        padding-top: 7px;
        position: relative;
        padding-left: 60px;
        height: 28px;
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .checkboxContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 36px;
        width: 36px;
        background: white;
        border: 2px solid @experian-purple;
        -moz-border-image: -moz-linear-gradient(top, @experian-light-blue 0%, @experian-purple 100%);
        -webkit-border-image: -webkit-linear-gradient(top, @experian-light-blue 0%, @experian-purple 100%);
        border-image: linear-gradient(to bottom, @experian-light-blue 0%, @experian-purple 100%);
        border-image-slice: 1;
    }

    .checkmark.invalid {
        -moz-border-image: none;
        -webkit-border-image: none;
        border-image: none;
        border: 2px solid @experian-danger-error;
    }

    /* On mouse-over, add a grey background color */
    .checkboxContainer:hover input ~ .checkmark {
        background-color: @experian-background-grey;
    }

    /* When the checkbox is checked, add a blue background */
    .checkboxContainer input:checked ~ .checkmark {
        background-color: @experian-success;
        border: none;
        width: 36px;
        height: 36px;
        border: 2px solid transparent;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .checkboxContainer input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkboxContainer .checkmark:after {
        left: 13.5px;
        top: 2.5px;
        width: 5px;
        height: 23px;
        border: solid white;
        border-width: 0 4px 4px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    @media only screen and (max-width : 560px){

        .checkboxContainer {
            display: block;
            line-height: 24px;
            padding: 5px 0 26px 0;
            position: relative;
            padding-left: 54px;
            height: 28px;
            font-size: 18px;
        }

    }

</style>
