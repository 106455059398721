import axios from 'axios';
import {get as config} from './common/config.js';
import Vue from 'vue';
import VueGtag from 'vue-gtag';

class CancellationHandler {

    static offerUsed(userID) {
        return new Promise((resolve, reject)=> {
            var postURL = config().CancellationEndPoint + "/offer-used";
            axios({
                method: 'POST',
                url: postURL,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    userID: userID
                }
            }).then((a) => {
                if(a.statusText == "found")
                {
                    if(userID != null)
                    {
                            Vue.use(VueGtag, {
                            config: { id:  (process.env.NODE_ENV !== "production" ? 'GTM-NVPNLWK' : "GTM-W2QB53") , 
                            debug: process.env.NODE_ENV !== 'production',
                            params: {
                                user_id: userID
                            } 
                            },
                        });
                    }
                    resolve(true);
                }  
                else
                    resolve(false);
                 
            }).catch(() => {
                reject(false);
            });
        })
    }

    static downgradePlan(userID, freeOfCharge = false) {
        return new Promise((resolve, reject) => {
            var endpoint = freeOfCharge ? "downgrade-to-free" : "downgrade-to-1250";

            var postURL = `${config().CancellationEndPoint}/${endpoint}`;
            axios({
                method: 'POST',
                url: postURL,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    UserID: userID
                }
            }).then(response => {
                if (response && response.status === 200) {
                    resolve(true);
                } else {
                    reject(false);
                }
            })
            .catch(error => {
                reject(false);
            });
        });
    }

    static getCancellationReasons() {
        return new Promise((resolve, reject)=> {
            var endpoint = "get-reasons";
            var getURL = `${config().CancellationEndPoint}/${endpoint}`;
            axios({
                method: "GET",
                url: getURL
            }).then(response=> {
                resolve(response.data);
            }).catch(response=> {
                reject();
            })
        })
    }

    static cancelSubscription(userId, answerId) {
        return new Promise((resolve, reject)=> {
            var endpoint = "cancel-subscription-new";
            var postURL = `${config().CancellationEndPoint}/${endpoint}`; 
            axios({
                method: 'POST',
                url: postURL,
                headers: {
                    'Content-Type': 'application/json'
                },
                data:{
                    userId,
                    answerId
                }
            }).then(()=> {
                resolve();
            }).catch(()=> {
                reject();
            })
        })
    }
}

export {CancellationHandler as default};
