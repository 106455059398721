<template>
    <button class="results" :disabled="disableButton" @click="directorSelect">
        <div>
            <div class="resultDetails">
                <div class="propertyLine" id="directorName">
                    {{DirectorResultObj.firstName}} {{DirectorResultObj.surname}}
                </div>
                <div class="propertyLine" id="businessName">
                    {{businessName}} ({{businessRef}})
                </div>
            </div>
            <div style="float: right;">
                <img v-show="loading === false" class="actionImage"
                     :src="require('../common/assets/Images/MoveOn.svg')"/>
                <ebaSpinner :useAlt="true" class="actionImage" v-show="loading === true"/>
            </div>
        </div>
    </button>
</template>

<script>

    import pepsAndSanctionsHandler from '../../scripts/common/pepsAndSanctionsHandler';
    import registrationHandler from '../../scripts/registrationHandler';
    import eventTrackingHandler from '../../scripts/common/eventTrackingHandler';

    import {mapActions} from 'vuex';

    export default {
        name: 'directorResult',
        props: {
            DirectorResultObj: {
                type: Object,
                required: true
            },
            IsDisabled: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                businessRef: this.$store.state.businessSearch.Business.businessRef,
                businessName: this.$store.state.businessSearch.Business.name,

                loading: false
            };
        },
        computed: {
            disableButton() {
                return this.loading === true || this.IsDisabled === true;
            }
        },
        methods: {
            ...mapActions([
                'setDirector',
                'checkPepsAndSanctions',
                'updateToPepsRoute'
            ]),

            directorSelect() {
                if (!this.IsDisabled) {
                    this.setLoading(true);

                    this.checkPepsAndSanctions(this.$store.state.businessSearch.Business)
                        .then(pepsResult => {
                            if (pepsResult === true) {
                                this.updateToPepsRoute()
                                    .then(() => {
                                        this.handleDirectorshipAppointment();
                                    })
                                    .catch((error) => {
                                        this.showCommonError(error);
                                    });
                            } else {
                                this.handleDirectorshipAppointment();
                            }
                        })
                        .catch((error) => {
                            this.showCommonError(error);
                        });
                }
            },

            showCommonError(error) {
                this.$emit('showCommonError');
                this.setLoading(false);
                if (error) {
                    console.log(error);
                }
            },

            handleDirectorshipAppointment() {
                registrationHandler.isAppointmentAvailable(this.DirectorResultObj.urn, this.businessRef)
                    .then((response) => {
                        this.setLoading(true);
                        if (response === true) {
                            eventTrackingHandler.trackEvent({
                                Event: eventTrackingHandler.trackingEvents.directorSelection,
                                Details: {
                                    "Selected Director": this.DirectorResultObj.urn + " | " +
                                        this.DirectorResultObj.firstName + ' ' + this.DirectorResultObj.surname,
                                    "Selected Business": this.businessRef + " | " + this.businessName,
                                    "Available": true
                                }
                            });
                            this.setDirector(this.DirectorResultObj)
                                .then(() => {
                                    this.$router.push({
                                        name: 'ContactDetails'
                                    });
                                });
                        } else {
                            this.$emit('showDirectorshipTaken');
                            this.setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },

            setLoading(value) {
                if (value === true) {
                    this.loading = true;
                    this.$parent.isDisabled = true;
                } else {
                    this.loading = false;
                    this.$parent.isDisabled = false;
                }
            }
        }
    };

</script>

<style lang="less" scoped>
    @import "../../components/common/assets/css/common.less";

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        width: 100%;
    }

    .results {
        text-align: left;
        padding: 8px 18px
    }

    .results:hover:enabled {
        background: @experian-background-grey;
        -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
    }

    .resultDetails {
        display: inline-block;
    }

    #directorName {
        color: @experian-purple;
        font-weight: bold;
    }

    #businessName {
        color: @experian-sapphire;
    }

    .propertyLine {
        margin: 4px 0;
    }

    .actionImage {
        width: 22px;
        height: 22px;
        margin-top: 64%;
    }

    @media only screen and (max-width: 1024px) {
        .resultDetails {
            max-width: 80%;
        }
    }

</style>
