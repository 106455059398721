import axios from 'axios';
import {get as config} from './common/config.js';

class RegistrationHandler {

    static triggerCustomerSupportEmail(model) {
        return new Promise((resolve, reject) => {
            let url = config().RegistrationAPI + 'Peps/EmailCustomerSupport';
            axios({
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    ErrorCode: 'MBPEXP001',
                    EmailAddress: model.emailAddress,
                    TelephoneNumber: model.telephoneNumber,
                    BusinessName: model.businessName,
                    BusinessRef: model.businessRef,
                    DirectorFirstName: model.directorFirstname,
                    DirectorSurname: model.directorSurname
                }
            })
                .then(response => {
                    if (response && response.status === 200) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    static getSecurityQuestions() {
        return new Promise(function (resolve, reject) {
            var getURL = config().RegistrationAPI + 'User/SecurityQuestions';
            axios.get(getURL, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static isExistingCustomer(emailAddress) {
        return new Promise(function (resolve, reject) {
            var url = config().RegistrationAPI + 'User/ExistingCustomer';
            axios({
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {Email: emailAddress}
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getAuthenticationQuestions(userID) {
        return new Promise((resolve, reject) => {
            var url = config().RegistrationAPI + 'Registration/GetAuthenticationQuestions';
            axios({
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    UserID: userID
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static submitAuthenticationAnswers(userID, result) {
        return new Promise((resolve, reject) => {
            var url = config().RegistrationAPI + 'Registration/SubmitAuthenticateAnswers';
            axios({
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    UserID: userID,
                    Result: result
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    static isAppointmentAvailable(directorUrn, businessReg) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: config().RegistrationAPI + 'Registration/IsAppointmentAvailable',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    Urn: directorUrn,
                    RegistrationNumber: businessReg
                }
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    static createAppointment(urn, regNo) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: config().RegistrationAPI + 'Registration/Appointment',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    Urn: urn,
                    RegistrationNumber: regNo
                }
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static uraCheck(uraModel) {
        return new Promise((resolve, reject) => {
            var url = config().UraAPI;
            axios({
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: uraModel
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static submitPin(UserId, Pin) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: config().PinAPI + 'Validate',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    UserId: UserId,
                    Pin: Pin,
                    PinType: 0
                }
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static requestPin(UserId) {
        return new Promise(function (resolve, reject) {
            var getURL = config().PinAPI + 'Create/' + UserId;
            axios.get(getURL)
                .then(response => {
                    if (response.status === 200) {
                        resolve(true);
                    } else if (response.status === 400 || response.status === 500) {
                        reject(false);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static pinEntryValid(userId) {
        return new Promise((resolve, reject) => {
            const getURL = config().PinAPI + 'Status/' + userId;
            axios.get(getURL)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static registrationComplete(userId) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: config().RegistrationAPI + 'Registration/complete',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: userId
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    static registerUser(userObj) {
        return new Promise(function (resolve, reject) {
            axios({
                method: 'POST',
                url: config().RegistrationAPI + 'Registration/submit',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: userObj
            })
                .then((response) => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getRegistrationRedirect(userId, customerId, registrationStatus) {
        switch (registrationStatus.toString()) {
            case '5':  // IIQ Questions
            case '9':
                return {
                    name: 'IIQQuestions',
                    params: {
                        UserId: userId,
                        CustomerId: customerId
                    }
                }
            case '8':
                return {
                    name: 'RegistrationComplete'
                }
            case '7':  // Pin Required
                return {
                    name: 'PinSent',
                    params: {
                        UserId: userId,
                        CustomerId: customerId
                    }
                }
            case '16':  // Payment Failed
                return {
                    name: 'BillingDetails',
                    params: {
                        PaymentFailed: true
                    }
                }
        }
    }

    static setupIntent() {
        return new Promise((resolve, reject)=> {
            const url = config().RegistrationAPI + 'Registration/GetStripeIntent'
            axios({
                method:'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response)=> {
                resolve(response);
            }).catch(error=> {
                reject(error);
            });
        });
    }
}

export {RegistrationHandler as default};
