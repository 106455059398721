const DEV = require('../../config/config.dev');
const LOCAL = require('../../config/config.local');
const QA = require('../../config/config.QA');
const UAT = require('../../config/config.UAT');
const PROD = require('../../config/config.prod');
const GLOBAL = require('../../config/config.global.js');

const config = {
    dev: DEV,
    local: LOCAL,
    qa: QA,
    uat: UAT,
    production: PROD
};

function install (VueObj) {

    if (config[process.env.NODE_ENV] === undefined) {
        console.log('No config was found for environment: ' + process.env.NODE_ENV);
    }

    VueObj.prototype.$config = config[process.env.NODE_ENV];
    return true;
}

// For use in non Vue scripts
function get (useGlobal) {
    if(useGlobal) {
        return GLOBAL;
    }
    return config[process.env.NODE_ENV];
}

module.exports.install = install;
module.exports.get = get;
