class RedirectHandler {

    static loginRedirect(router, registrationStep) {
        switch (registrationStep) {
            case 5: //IIQ
            case 9:
                router.push({name: 'IIQQuestions'});
                break;
            case 7: //PIN sent/entry
                router.push({name: 'PinSent'});
                break;
            case 8:
                router.push({name: 'Report'});
                break;
            default:
                router.push({name: 'Home'});
                break;
        }
    }
}

export {RedirectHandler as default};
