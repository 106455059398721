var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasValidRoute()
      ? _c(
          "div",
          [
            _c(
              "router-link",
              { attrs: { to: { name: _vm.routeName } } },
              [
                _c("ebaLogo", {
                  attrs: {
                    altText: _vm.altText,
                    "is-responsive": _vm.isResponsive,
                    source: _vm.source,
                    tabletSource: _vm.tabletSource,
                    mobileSource: _vm.mobileSource,
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", [
          _c(
            "a",
            { attrs: { href: _vm.imageLinkUrl, target: "_blank" } },
            [
              _c("ebaLogo", {
                attrs: {
                  altText: _vm.altText,
                  "is-responsive": _vm.isResponsive,
                  source: _vm.source,
                  tabletSource: _vm.tabletSource,
                  mobileSource: _vm.mobileSource,
                },
              }),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }