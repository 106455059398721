import ebaFooter from '../../components/common/footer';
import ebaHeader from '../../components/header/header';

import ebaButton from '../../components/common/input/buttons/button';
import ebaImageLink from '../../components/common/input/buttons/imageLink';
import ebaLinkText from '../../components/common/input/buttons/linkText';
import ebaTopButton from '../../components/common/input/buttons/topButton';
import ebaSwitch from '../../components/common/input/buttons/switch';

import ebaSpinner from '../../components/common/spinner.vue';
import ebaLineText from '../../components/common/lineText';

import ebaCheckbox from '../../components/common/input/checkbox';
import ebaRadioGroup from '../../components/common/input/radioGroup';
import ebaDropdown from '../../components/common/input/dropdown';
import ebaTextInput from '../../components/common/input/textinput';
import ebaSearchBar from '../../components/common/input/searchBar';
import ebaDateEntry from '../../components/common/input/dateEntry';

import ebaChevron from '../../components/common/chevron';

import ebaInfo from '../../components/common/alerts/informationBlock';
import ebaWarning from '../../components/common/alerts/warningBlock';
import ebaError from '../../components/common/alerts/errorBlock';

import ebaHeaderActions from '../../components/header/headerActionsMbp';

import ebaLogo from '../../components/common/logo';
import ebaRegistrationStep from '../../components/common/registration/registrationStepContainer';

import ebaProductInfoItem from '../../components/common/productInformation/productInformationItem';

import ebaIconInfoBlock from '../../components/common/informationBlocks/iconInfoBlock';

import ebaModal from '../../components/common/modals/modal';
import ebaPackageModal from '../../components/modals/packageModal';
import ebaLoadingModal from '../../components/common/modals/loading';
import ebaCommonErrorModal from '../../components/common/modals/commonErrorModal';
import ebaContactErrorModal from '../../components/common/modals/contactErrorModal';


import ebaCriteriaList from '../../components/common/input/criteriaList';
/*PAGES*/
import Registration from '../../components/common/registration/registration';
import ContactDetails from '../../pages/registration/contactDetailsStep';
import PersonalDetails from '../../pages/registration/personalDetails';
import Password from '../../pages/registration/password';
import SecurityQuestions from '../../pages/registration/securityStep';
import CommsAndMarketing from '../../pages/registration/commsAndMarketing';
import MemorableWord from '../../pages/registration/memorableWord';
import TermsAndConditions from '../../pages/registration/termsAndConditions';
import BillingDetails from '../../pages/registration/billingDetails'
import PreviousAddress from '../../pages/registration/previousAddressCheck';
import QASSelect from '../../pages/registration/QASSelect';
import DirectorshipTaken from '../../pages/registration/directorshipTaken';

import BusinessResults from '../../pages/registration/businessResults';
import ebaBusinessResult from '../../components/businessResults/businessResult';

import DirectorSearch from '../../pages/registration/directorSearch';
import ebaDirectorResult from '../../components/businessResults/directorResult';

import IIQQuestions from '../../pages/registration/iiqQuestions';
import PinEntry from '../../pages/registration/pinEntry';
import PinSent from '../../pages/registration/pinSent';
import UraLockout from '../../pages/registration/uraLockout';
//Home
import Home from '../../pages/home';
import Redirect from '../../pages/redirect';
import ContactCustomerSupport from '../../pages/contactCustomerSupport';
import CancellationOffer from "../../pages/cancellationOffer"

//CancellationOfferSteps
import subCacellationConfirmationStep from '../../components/cancellationOfferSteps/confirmationStep';
import subCancellationStepOne from "../../components/cancellationOfferSteps/stepOne"
import subCancellationStepTwo from "../../components/cancellationOfferSteps/stepTwo"
import subCancellationStepThree from "../../components/cancellationOfferSteps/stepThree"
import subCancellationStepFour from "../../components/cancellationOfferSteps/stepFour"

class ComponentRepository {

    static registerComponents (Vue) {

        // Layout
        Vue.component('ebaFooter', ebaFooter);
        Vue.component('ebaHeader', ebaHeader);

        // Input/Buttons
        Vue.component('ebaButton', ebaButton);
        Vue.component('ebaImageLink', ebaImageLink);
        Vue.component('ebaLinkText', ebaLinkText);
        Vue.component('ebaSwitch', ebaSwitch);
        Vue.component('ebaTopButton', ebaTopButton);

        Vue.component('ebaSpinner', ebaSpinner);
        Vue.component('ebaLineText', ebaLineText);
        Vue.component('ebaChevron', ebaChevron);

        // Input
        Vue.component('ebaCheckbox', ebaCheckbox);
        Vue.component('ebaRadioGroup', ebaRadioGroup);
        Vue.component('ebaDropdown', ebaDropdown);
        Vue.component('ebaTextInput', ebaTextInput);
        Vue.component('ebaSearchBar', ebaSearchBar);
        Vue.component('ebaDateEntry', ebaDateEntry);

        Vue.component('ebaProductInfoItem', ebaProductInfoItem);
        Vue.component('ebaIconInfoBlock', ebaIconInfoBlock);

        Vue.component('ebaModal', ebaModal);
        Vue.component('ebaPackageModal', ebaPackageModal);
        Vue.component('ebaLoadingModal', ebaLoadingModal);
        Vue.component('ebaCommonErrorModal', ebaCommonErrorModal);
        Vue.component('ebaContactErrorModal', ebaContactErrorModal);

        Vue.component('ebaHeaderActions', ebaHeaderActions);
        Vue.component('ebaLogo', ebaLogo);

        Vue.component('ebaInfo', ebaInfo);
        Vue.component('ebaWarning', ebaWarning);
        Vue.component('ebaError', ebaError);

        // Registration specific components
        Vue.component('ebaRegistrationStep', ebaRegistrationStep);
        Vue.component('ebaBusinessResult', ebaBusinessResult);
        Vue.component('ebaDirectorResult', ebaDirectorResult);

        Vue.component('ebaCriteriaList', ebaCriteriaList);

        //CancellationOffer
        Vue.component('ebaSubCancellationConfirmationStep', subCacellationConfirmationStep);
        Vue.component('ebaSubCancellationStepOne', subCancellationStepOne);
        Vue.component('ebaSubCancellationStepTwo', subCancellationStepTwo);
        Vue.component('ebaSubCancellationStepThree', subCancellationStepThree);
        Vue.component('ebaSubCancellationStepFour', subCancellationStepFour);
    }

    static registerPages () {
        return {
            Redirect: Redirect,
            Home: Home,
            ContactCustomerSupport: ContactCustomerSupport,
            CancellationOffer: CancellationOffer,

            Registration: {
                Registration: Registration,
                ContactDetails: ContactDetails,
                Password: Password,
                PersonalDetails: PersonalDetails,
                SecurityQuestions: SecurityQuestions,
                TermsAndConditions: TermsAndConditions,
                MemorableWord: MemorableWord,
                CommsAndMarketing: CommsAndMarketing,
                PreviousAddress: PreviousAddress,
                BillingDetails: BillingDetails,
            },
            DirectorSearch: DirectorSearch,
            DirectorshipTaken: DirectorshipTaken,
            BusinessResults: BusinessResults,
            IIQQuestions: IIQQuestions,
            PinEntry: PinEntry,
            PinSent: PinSent,
            UraLockout: UraLockout,
            QASSelect: QASSelect
        };
    }
}

export default ComponentRepository;
