<template>
    <ebaModal
            v-if="showModal"
            @close="showModal = false">
        <div slot="header">
            <div class="headerText gradientText">Package Details.</div>
            <div style="height: 1px; width: 100%; border-bottom: solid 1px #eee;"></div>
        </div>
        <div slot="body" class="packageBody">
            <p class="violetSubText">
                {{currentPrice}}
            </p>
            <p class="selectedDetails">What you pay today</p>
            <p class="successText">£0</p>
            <ebaInfo id="cancelInfo">
                <div id="cancelText">
                    <strong>Cancel Anytime</strong>
                </div>
            </ebaInfo>
            <div>
                <div v-show="showDirector" class="directorshipSection">
                    <p class="subTitleText">
                        Selected Directorship
                    </p>
                    <p class="selectedDetails">
                        <strong>{{directorName}}</strong>
                    </p>
                </div>
                <div v-show="showBusiness" class="businessSection">
                    <p class="subTitleText">
                        Selected Business
                    </p>
                    <p class="selectedDetails">
                        <strong>{{BusinessName}}</strong>
                    </p>
                    <p class="selectedDetails">
                        {{businessDetails}}
                    </p>
                </div>
            </div>
        </div>
    </ebaModal>
</template>

<script>

    import {get as config} from '../../scripts/common/config.js';
    import {mapState} from 'vuex';

    export default {
        name: 'PackageModal',
        props: {
            offerText: {
                type: String,
                required: false
            }
        },
        computed: {
            ...mapState([
                'Business',
                'Director'
            ]),
        },
        data() {
            return {
                showModal: false,

                showDirector: false,
                showBusiness: false,

                BusinessName: '',

                directorName: '',
                businessDetails: '',
                currentPrice: 'Free Trial then ' + config(true).offerPrice + ' per month'
            }
        },
        methods: {
            show() {
                this.showModal = true;

                if (this.$store.state.businessSearch.Director !== undefined) {
                    this.directorName = this.$store.state.businessSearch.Director.firstName + ' ' + this.$store.state.businessSearch.Director.surname;
                    this.showDirector = true;
                } else {
                    this.showDirector = false;
                }

                if (this.$store.state.businessSearch.Business !== undefined) {
                    this.businessDetails = this.getBusinessAddress();
                    this.showBusiness = true;
                } else {
                    this.showBusiness = false;
                }
            },
            getBusinessAddress() {
                var returnVal = '';

                var businessObj = this.$store.state.businessSearch.Business;

                this.BusinessName = businessObj.name + ' (' + businessObj.businessRef + ')';

                if (businessObj.locationLine1 !== null) {
                    returnVal = businessObj.locationLine1;
                }
                if (businessObj.locationLine2 !== null) {
                    returnVal += ', ' + businessObj.locationLine2;
                }
                if (businessObj.locationLine3 !== null) {
                    returnVal += ', ' + businessObj.locationLine3;
                }
                if (businessObj.postcode !== null) {
                    returnVal += ', ' + businessObj.postcode;
                }

                return returnVal;
            },
            close() {
                this.$emit('close');
            }
        }
    };

</script>

<style lang="less" scoped>

    @import '../common/assets/css/common.less';

    p {
        margin: 5px 0 5px 5px;
    }

    #cancelInfo {
        margin-top: 16px;
    }

    #cancelText {
        margin-left: 32px;
    }

    .packageBody {
        padding-bottom: 8px;
    }

    .directorshipSection {
        padding: 8px 0 0 0;
        margin: 6px 0 0 0;
    }

    .businessSection {
        padding: 8px 0;
        margin: 8px 0;
    }

    .successText {
        color: @experian-success;
        font-weight: bold;
    }

    .subTitleText {
        font-weight: bold;
        color: @experian-violet;
        font-size: 16px;
        line-height: 20px;
    }

    .selectedDetails {
        color: @experian-sapphire;
        line-height: 23px;
    }

</style>
