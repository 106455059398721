<template>
    <div>

    </div>
</template>

<script>
    import redirectHandler from '../scripts/redirectHandler.js';

    export default {
        name: 'Redirect',
        created() {
            var registrationStep = null;

            if (this.$store.state.authentication.registrationStep) {
                registrationStep = this.$store.state.authentication.registrationStep
            }

            redirectHandler.loginRedirect(this.$router, registrationStep); //let the handler redirect
        }
    }

</script>

<style>
</style>
