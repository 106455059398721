var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "radio-group" }, [
    _c("form", [
      _c(
        "div",
        { staticStyle: { float: "left" } },
        _vm._l(_vm.d_items, function (item) {
          return _c(
            "label",
            {
              key: item.id,
              class: [{ invalid: _vm.valid !== true }, "radio-container"],
            },
            [
              _vm._t("default", function () {
                return [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.description) +
                      "\n                "
                  ),
                ]
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selected,
                    expression: "selected",
                  },
                ],
                ref: "checkboxInput",
                refInFor: true,
                attrs: {
                  type: "radio",
                  name: _vm.name,
                  id: item.id,
                  required: "",
                },
                domProps: {
                  value: item.value || item.id,
                  checked: _vm._q(_vm.selected, item.value || item.id),
                },
                on: {
                  change: function ($event) {
                    _vm.selected = item.value || item.id
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "checkmark" }),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(item.text))]),
            ],
            2
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }