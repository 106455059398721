<template>
    <div>
        <ebaCommonErrorModal ref="commonErrorModal"/>
        <section class="headerSection">
            <p class='headerText gradientText heading' style="margin-bottom: 0px !important;font-size: 18px !important;">
                See how banks &amp; suppliers view your business credit score with Experian - *Free for {{trialPeriodText}}s
            </p>
            <p class='nonLimButton' style="margin-bottom: 25px !important;font-size: 21px !important;" data-v-56b687e3>
            Review your Business Credit Score with our Credit Review Service. 96% success rate in increasing a Business Credit Score & Risk Rating.
            </p>
            <ebaSearchBar
                    ref="searchBar"
                    placeholderText="Enter your business name or registration number"
                    @searchClick="BusinessSearch"></ebaSearchBar>
            <div v-show="!isAuthenticated" class="nonLimButton haveAccount"> 
                Already have an account? <router-link :to="{name: 'Login'}"><strong>Log in</strong></router-link>
            </div>
            <div class="nonLimButton">
                <router-link :to="{name: 'NonLimitedReport'}">OR Find My Non-Limited Business</router-link>
            </div>
            <div style="font-size: 10px; padding:0 10px;">
                A monthly fee of {{ price }} applies after your Free Trial. You
                may cancel during your free trial without charge.
            </div>
        </section>
        <section>
            <ebaLineText text='See how we can help you improve &amp; grow'
                         style="margin-top:38px; margin-bottom: 15px;"></ebaLineText>
            <ebaChevron/>

            <div class="iconInfoContainer">
                <ebaIconInfoBlock class="whiteBackgroundVioletText"
                                  :iconSource="require('../components/common/assets/images/eye.svg')">
                    View your Experian business credit score
                </ebaIconInfoBlock>
                <ebaIconInfoBlock class="whiteBackgroundVioletText middleInfoItem"
                                  :iconSource="require('../components/common/assets/images/dial.svg')">
                    See what's affecting your score and how to improve it
                </ebaIconInfoBlock>
                <ebaIconInfoBlock class="whiteBackgroundVioletText"
                                  :iconSource="require('../components/common/assets/images/graph.svg')">
                    Unlock new sources of finance to grow your business
                </ebaIconInfoBlock>
            </div>
            <div class="sectionDivider">
            </div>
        </section>
        <section class="signUpSection">
            <p style="text-align: center; font-size:16px;margin-bottom: 24px; margin-top:38px;" class="lightBlue">What
                you'll need to sign up</p>
            <div class="iconInfoContainer">
                <ebaIconInfoBlock class="lightBlueWhiteText"
                                  :iconSource="require('../assets/Images/Icons/Briefcase.svg')" :smallIcon="true">
                    You'll need to be an owner or director of the company you're searching for
                </ebaIconInfoBlock>
                <ebaIconInfoBlock class="lightBlueWhiteText" :iconSource="require('../assets/Images/Icons/House.svg')"
                                  :smallIcon="true">
                    Your home address, phone, email and date of birth
                </ebaIconInfoBlock>
                <ebaIconInfoBlock class="lightBlueWhiteText" :iconSource="require('../assets/Images/Icons/Cards.svg')"
                                  :smallIcon="true">
                    Valid debit or credit card
                </ebaIconInfoBlock>
            </div>
        </section>
    </div>
</template>

<script>

    import {get as config} from '../scripts/common/config.js';
    import {mapActions, mapGetters} from 'vuex';
    import businessSearchHandler from '../scripts/businessSearchHandler';
    import LoginHandler from '../scripts/common/loginHandler';

    export default {
        name: 'Home',
        data() {
            return {
                price: config(true).offerPrice,
                searchUrl: config().BusinessSearchAPI,
                trialPeriodText: config(true).UiVariables.TrialPeriodText
            };
        },
        computed: {
          ...mapGetters([
              'isAuthenticated'
          ])  
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.clearExistingData();
                next();
            });
        },
        methods: {

            ...mapActions([
                'clearExistingData'
            ]),

            BusinessSearch(searchText) {
                this.$refs.searchBar.loading = true;
                businessSearchHandler.searchBusiness(searchText)
                    .then((response) => {
                        this.$refs.searchBar.loading = false;

                        if (response.data) {
                            if (response.data.Flagged === true) {
                                this.$store.state.BusinessSearch.Flagged = true;
                            }

                            this.$router.push({
                                name: 'BusinessResults',
                                params: {
                                    SearchPhrase: searchText,
                                    SearchResults: response.data
                                }
                            });
                        }
                    })
                    .catch(() => {
                        this.$refs.searchBar.loading = false;
                        this.$refs.commonErrorModal.show();
                    });
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../components/common/assets/css/common.less';
    @import '../components/common/assets/css/fontIcons.less';

    .headerSection {
        text-align: center;
    }

    .lightBlue {
        color: @experian-light-blue;
    }

    .iconInfoContainer {
        margin: 0 auto;
        max-width: 1020px;
        text-align: center;
    }

    .nonLimButton {
        color: @experian-light-blue;
        font-size: 18px;
        margin: 20px 0 10px 0;

        &.haveAccount {
            font-size: 14px;
        }
    }

    .sectionDivider {
        width: 60%;
        margin: 40px auto;
        border-bottom: solid 1px #eee;
    }

    .middleInfoItem {
        border-left: 1px solid @experian-light-grey;
        border-right: 1px solid @experian-light-grey;
    }

    .signUpSection {
        margin-bottom: 56px;
    }

    /* phone portrait */
    @media only screen and (max-width: 568px) {

        button {
            font-size: 160px !important;
        }

        .sectionDivider {
            margin-top: 20px;
        }

        .middleInfoItem {
            border: none;
        }

        .heading {
            white-space: normal;
        }
    }

    /*medium phone portrait*/
    @media only screen and (min-width: 321px) and (max-width: 567px) {

        .headerSection {
            padding: 0 20px;
        }

        .middleInfoItem {
            border-right: none;
        }
    }

    /*medium phone to tablet landscape */
    @media only screen and (min-width: 568px) and (max-width: 1024px) {
        .signUpSection {
            margin-bottom: 36px;
        }
    }

    /*medium-large phone landscape*/
    @media only screen and (min-width: 568px) and (max-width: 736px) {
        .middleInfoItem {
            border: none;
        }
    }
</style>
