import axios from 'axios';
import eventTrackingHandler from './common/eventTrackingHandler';

import {get as config} from './common/config.js';

class BusinessSearchHandler {

    static searchBusiness(searchText) {
        var searchModel = {
            'businessRef': '',
            'businessName': '',
            'postTown': null,
            'postcode': null,
            'searchCorporates': true,
            'excludeDissolved': false,
            'searchNonCorps': false
        };

        //companies house company registration number (CRN) regex
        //can be either 2 letters following by 6 digits or 8 digits
        //https://www.rapidformations.co.uk/blog/what-is-a-company-registration-number/
        var regNoRegex = new RegExp('[a-zA-Z0-9]{2}[0-9]{6}')
        var encodedSearchText = escape(searchText);

        if (searchText.match(regNoRegex)) {
            searchModel.businessRef = encodedSearchText;
        } else {
            searchModel.businessName = encodedSearchText;
        }

        eventTrackingHandler.trackEvent({
           Event: eventTrackingHandler.trackingEvents.businessSearchPage,
           Details: {
               "SearchQuery": searchText,
               "ReferrerProductCode": config().ProductCode
           }
        });

        return new Promise(function (resolve, reject) {
            callBusinessSearchApi(searchModel)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    static searchDirector(businessRef) {
        return new Promise(function (resolve, reject) {
            axios.get(config().DirectorSearchAPI + businessRef, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

function callBusinessSearchApi(searchModel) {
    return new Promise(function (resolve, reject) {
        axios({
            method: 'POST',
            url: config().BusinessSearchAPI,
            headers: {
                'Content-Type': 'application/json'
            },
            data: searchModel
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export {BusinessSearchHandler as default};
