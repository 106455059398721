var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showProgress,
              expression: "showProgress",
            },
          ],
          staticClass: "stepCounter",
        },
        [
          _vm._v(
            "\n            " + _vm._s(_vm.percentage) + "% complete\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "gradientText stepHeader" }, [
        _vm._v("\n            " + _vm._s(_vm.stepTitle) + "\n        "),
      ]),
      _vm._v(" "),
      _c("br", { staticStyle: { clear: "both" } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "stepSeperator" }),
    _vm._v(" "),
    _c(
      "form",
      {
        staticStyle: { width: "100%" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }