<template>
    <transition name="modal">
        <div class="modal-mask" @click="$emit('close', 'clickAway')">
            <div class="modal-wrapper">
                <div class="modal-container" @click.stop :style="setModalSize()">
                    <div class="modal-header" v-show="showHeader">
                        <ebaButton v-show="showTopClose === true"
                                style="margin-top:12px;"
                                style-type="flat"
                                class="modal-default-button"
                                v-on:click.native="$emit('close')">
                            Close
                        </ebaButton>
                        <slot name="header">
                        </slot>
                    </div>

                    <div class="modal-body">
                            <slot name="body">
                                default body
                            </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <div>

                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Modal",
        props: {
            showTopClose: {
                type: Boolean,
                default: true
            },
            showHeader: {
                type: Boolean,
                deafult: true
            },
            width: {
                type: Number,
                required: false,
            },
            height: {
                type: Number,
                required: false
            }
        },
        methods: {
            setModalSize() {
                var styleObj = {};
                if (this.width !== undefined) {
                    styleObj.width = this.width + 'px';
                }

                if (this.height !== undefined) {
                    styleObj.height = this.height + 'px';
                }
                return styleObj;
            },
        }
    }
</script>

<style lang="less" scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        vertical-align: middle;
    }

    .modal-container {
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        width: 400px;
        position: relative;
        margin:5% auto 0px; //fallback
        margin: 2.5vh auto 0px;
        overflow: auto;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-header {
        height: 20%;
    }

    .modal-body {
        height: 70%;
        padding: 20px 20px 0 20px;
    }

    .modal-footer {
        height: 10%;
    }

    .modal-default-button {
        float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    /* phone portrait */
    @media only screen and (max-width: 740px) {

        .modal-container {
            width: 100% !important;
            left: 0;
            margin-top: 35%;
            overflow: auto;
        }

        .modal-mask {
            overflow: auto;
        }

    }

    /*iPhone X landscape */
    @media only screen and (min-width: 769px) and (max-width: 812px) {
        .modal-mask {
            overflow: auto;
        }
    }


</style>
