import { render, staticRenderFns } from "./password.vue?vue&type=template&id=321a92dd&scoped=true"
import script from "./password.vue?vue&type=script&lang=js"
export * from "./password.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../Server/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321a92dd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\data\\jenkins_home\\workspace\\Web_Applications\\Deploy_MBPUX_Site\\Server\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('321a92dd')) {
      api.createRecord('321a92dd', component.options)
    } else {
      api.reload('321a92dd', component.options)
    }
    module.hot.accept("./password.vue?vue&type=template&id=321a92dd&scoped=true", function () {
      api.rerender('321a92dd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/registration/password.vue"
export default component.exports