var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "registrationPage" },
    [
      _c("ebaCommonErrorModal", { ref: "commonErrorModal" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "registrationContainer" },
        [
          _c(
            "ebaRegistrationStep",
            {
              attrs: { "step-title": _vm.QuestionTitle, showStepNumber: false },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c("input", {
                    attrs: { type: "hidden", name: "hdQuestionID" },
                    domProps: { value: _vm.QuestionID },
                  }),
                  _vm._v(" "),
                  _c("ebaDropdown", {
                    ref: "question",
                    attrs: {
                      inputID: "iiqQuestion",
                      disabled: _vm.IsDropdownDisabled,
                      "place-holder": _vm.QuestionPlaceHolder,
                      "help-text": _vm.QuestionHelpText,
                      invalidText: "You must select an answer",
                      "data-set": _vm.QuestionAnswers,
                    },
                    model: {
                      value: _vm.DropdownModel,
                      callback: function ($$v) {
                        _vm.DropdownModel = $$v
                      },
                      expression: "DropdownModel",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "navigationButton" },
                [
                  _c(
                    "ebaButton",
                    {
                      attrs: {
                        "style-type": "raised",
                        isLoading: _vm.IsLoading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.submitIIQ.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.ButtonText) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("br", { staticStyle: { clear: "both" } }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }