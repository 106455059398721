<template>
    <div>
        <span>
            <input ref="searchBar" class="searchInput" type="text" :placeholder="placeholderText" v-on:keyup.enter="fireMethod">
            <button id="btnSearch" name="btnSearch" class="searchButton" v-on:click="fireMethod">
                <i v-show="loading === false" class="exp-icon-magnify centerIcon"></i>
                <ebaSpinner v-show="loading === true" class="centerIcon"></ebaSpinner>
            </button>
        </span>
    </div>
</template>

<script>

    export default {
        name: 'SearchBar',
        props: {
            placeholderText: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                loading: false
            };
        },
        methods: {
            fireMethod() {
                if (!this.isLoading) {
                    var textVal = this.$refs.searchBar.value;
                    if (textVal !== undefined && textVal !== null && textVal.trim() !== '') {
                        this.$emit('searchClick', this.$refs.searchBar.value);
                    }
                }
            }
        }
    };

</script>

<style lang='less' scoped>

    @import '../assets/css/common.less';
    @import '../assets/css/fontIcons.less';

    .searchInput {
        max-width: 470px;
        width: 70%;
        padding: 2px 2px 2px 10px;
        height: 32px;
        vertical-align: top;
        font-size: 18px;
        border: 2px solid @experian-purple;
        -moz-border-image: -moz-linear-gradient(top, @experian-light-blue 0%, @experian-purple 100%);
        -webkit-border-image: -webkit-linear-gradient(top, @experian-light-blue 0%, @experian-purple 100%);
        border-image: linear-gradient(to bottom, @experian-light-blue 0%, @experian-purple 100%);
        border-image-slice: 1;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .centerIcon {
        width: 100%;
        margin: 0 auto;
    }

    .searchButton {

        padding: 0;
        outline: none;

        width: 40px;
        height: 40px;
        margin-left: -6px;

        background-color: @experian-dark-blue;
        font-size: 18px;
        border: none;
        color: #fff;
    }

    .searchButton :hover {
        cursor: pointer;
    }

</style>
