<template>
    <div class="extraInformation">
        <div v-show="showArrow === true" class="arrow-up"></div>
        <slot>Warning</slot>
    </div>
</template>

<script>

    export default {
        name: 'warningBlock',
        props: {
            showArrow: {
                type: Boolean,
                default: true
            }
        }
    };

</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    .extraInformation {
        background: @experian-warning;
        color: white;
        padding: 16px;
        text-align: center;
        line-height: 28px;
        margin-top: 42px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
    }

    .arrow-up {
        float: right;
        position: relative;
        right: 32px;
        top: -30px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid @experian-warning;
    }

</style>
