<template>
    <div>
        <div>
            <span v-show="showProgress" class="stepCounter">
                {{percentage}}&#37; complete
            </span>
            <span class="gradientText stepHeader">
                {{stepTitle}}
            </span>
            <br style="clear:both"/>
        </div>
        <div class="stepSeperator"></div>
        <form v-on:submit.prevent style="width: 100%;">
            <slot>
            </slot>
        </form>
        <br>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'RegistrationStepContainer',
        created() {
            // Always clear down custom text when created
            this.$emit('clearCustomProceedText');
        },
        mounted() {
            document.addEventListener('DOMContentLoaded', this.updateSteps());
        },
        props: {
            stepTitle: {
                type: String,
                required: true
            },
            showStepNumber: {
                type: Boolean,
                default: true
            }
        },
        data: function () {
            return {
                stepNumber: '-1',
                percentage: undefined,
                showProgress: true
            };
        },
        computed: {
            ...mapGetters([
                'getStepLength',
                'getCurrentStep',
                'getCurrentStepIndex'
            ])
        },
        methods: {
            updateSteps () {
                this.stepNumber = this.$store.getters.getCurrentStepFromName(this.$route.name);
                this.$store.dispatch('setStepFromName', this.$route.name);

                if (this.stepNumber !== undefined) {
                    // Forgery check
                    if (this.getCurrentStep !== this.$route.name) {
                        this.$router.push({
                            name: this.getCurrentStep
                        });
                    }
                }

                this.getStepPercentage();
                this.showPercentage();
            },
            getStepPercentage() {
                this.percentage = Math.floor(((this.stepNumber - 1) / this.getStepLength) * 100);
            },
            showPercentage() {
                if (this.percentage === undefined || this.showStepNumber === false) {
                    this.showProgress = false;
                } else {
                    this.showProgress = true;
                }
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    .stepHeader {
        font-size: 22px;
        float: left;
        width: 80%;
    }

    .stepCounter {
        font-size: 12px;
        color: @experian-dark-grey;
        float: right;
    }

    .stepSeperator {
        margin-top: 6px;
        margin-bottom: 12px;
        width: 100%;
        height: 1px;
        border-bottom: 2px solid @experian-medium-grey;
    }

    @media only screen and (max-width: 560px) {

        .stepHeader {
            font-size: 22px;
            width: 100%;
        }

        .stepCounter {
            float: left;
            clear: left;
            margin-top: 0;
            margin-bottom: 8px;
        }

    }

</style>
