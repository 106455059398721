<template>
    <ebaModal
    :showTopClose="false" 
    :showHeader="false"
    :class="{confirmation: isConfirmation}">
        <div class="body" slot="body">
            <component
                ref="stepComponent"
                v-bind:is="currentComponent"
                v-on:generateNavButtons="generateNavButtons"
                v-on:action="action"
                v-bind:commonProperties="commonProperties"
            />
        </div>
        <div class="footer" slot="footer">
            <ebaButton
                :ref="button.type"
                v-for="(button, index) in buttons"
                v-bind:key="index"
                :styleType="button.type == 'primary' ?'raised': 'flat'"
                :isLoading="button.isLoading"
                @click.native="button.action"
            >{{button.text}}</ebaButton>
        </div>
    </ebaModal>
</template>

<script>
import cancellationHandler from "../scripts/cancellationHandler";
import { mapGetters } from "vuex";

export default {
    name: "CancellationOffer",
    data: function() {
        return {
            currentComponent: null,
            commonProperties: {
                isAuthenticated: false,
                isOfferUsed: false,
                customerName: "Unknown",
                cancellationReasons: []
            },
            buttons: [],
            steps: ["ebaSubCancellationStepOne"],
            showModal: true,
            isConfirmation: false,
            isLoading: false
        };
    },
    computed: {
        isAuthenticated: {
            get: function() {
                var isAuth = this.$store.getters.isAuthenticated;
                if (isAuth == true) {
                    this.commonProperties.isAuthenticated = isAuth;
                    var customerName = this.$store.state.authentication
                        .userName;
                    this.commonProperties.customerName =
                        customerName.charAt(0).toUpperCase() +
                        customerName.slice(1).toLowerCase();  
                }
                return isAuth;
            }
        }
    },
    watch: {
        isAuthenticated(newVal) {
            if(newVal == false)
                this.redirectToReport();
        }
    },
    mounted() {
        this.currentComponent = this.steps[0];
    },
    methods: {
        generateNavButtons(params) {
            this.buttons = params;
        },
        action(params) {
            if (Array.isArray(params)) {
                if (params.length > 1) {
                    this[params[0]](params[1]);
                } else {
                    this[params[0]]();
                }
            }
        },
        progressToStep(step) {
            this.currentComponent = step;

        },
        redirectToReport() {
            window.location.href = "/report";
        },
        setConfirmation() {
            this.isConfirmation = true;
        },
        downgradePlan(toFree = false) {
            this.$refs.primary[0].isLoading = true;
            const context = this;
            cancellationHandler.downgradePlan(this.$store.state.authentication.userID, toFree)
                .then(success => {
                    context.$refs.primary[0].is_Loading = false;
        
                    if(toFree)
                        context.commonProperties.confirmationType = "downgradePlanToFree";
                    else 
                        context.commonProperties.confirmationType = "downgradePlanTo1250";
                    context.commonProperties.success = success;

                    context.action([
                        "progressToStep",
                        "ebaSubCancellationConfirmationStep",
                    ]);

                })
                .catch(error => {
                    context.$refs.primary[0].is_Loading = false;

                    context.commonProperties.confirmationType = null;
                    context.commonProperties.success = false;

                    context.action([
                        "progressToStep",
                        "ebaSubCancellationConfirmationStep",
                    ]);
                });
        },
        closeAccount(answer) {
            this.$refs.primary[0].isLoading = true; 
            const context = this;
            cancellationHandler.cancelSubscription(this.$store.state.authentication.userID, answer)
            .then(()=>{
                window.location.href = "/accountdetails";
            })
            .catch(()=> {
                context.action([
                    "progressToStep",
                    "ebaSubCancellationConfirmationStep",
                ]);
                context.commonProperties.confirmationType = null;
                context.commonProperties.success = false;
            });
        },
        getCancellationReasons(){
            const context = this;
            cancellationHandler.getCancellationReasons()
            .then(val=> {
                this.commonProperties.cancellationReasons = val.map(i=> {
                    return {id: i.ReasonId, text: i.Reason}
                })
            })
            .catch(()=> {
                context.$emit("action", [
                    "progressToStep",
                    "ebaSubCancellationConfirmationStep",
                ]);
            })
        }
    }
};
</script>
<style lang="less">
.modal-wrapper {
    .modal-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        max-height: 95vh;
        width: 38vw;

        @media screen and (max-width: 1025px) {
            width: 75vw;
        }

        @media screen and (max-width: 560px) {
            //font-size: 14px;
        }

        .modal-body {
            padding: 1.5em 2em 0 2em;
        }
    }
}
</style>


<style lang="less" scoped>
@import "../components/common/assets/css/colours.less";
@deep: ~">>>";
.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    & @{deep} .header {
        padding-bottom: 1em;
        text-align: center;
        .image,
        img {
            height: 4em;
            margin-bottom: 1em;
        }
    }

    & @{deep} span {
        display: block;
        width: 100%;
        line-height: 1.4em;
    }

    & @{deep} .text {
        margin-top: 1em;
        span {
            color: @experian-sapphire;
            padding-bottom: 0;
            padding-top: 0;
        }

        & > div {
            padding-bottom: 0.5em;
        }
    }

    & @{deep} .spacer-line {
        border-bottom: 2px solid @experian-light-grey;
        margin-left: -2em;
        margin-right: -2em;
    }

    .footer {
        float: right;
        padding-top:2em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 1em;
    }
}
</style>
