import { render, staticRenderFns } from "./previousAddressCheck.vue?vue&type=template&id=51c8374d&scoped=true"
import script from "./previousAddressCheck.vue?vue&type=script&lang=js"
export * from "./previousAddressCheck.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../Server/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c8374d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\data\\jenkins_home\\workspace\\Web_Applications\\Deploy_MBPUX_Site\\Server\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51c8374d')) {
      api.createRecord('51c8374d', component.options)
    } else {
      api.reload('51c8374d', component.options)
    }
    module.hot.accept("./previousAddressCheck.vue?vue&type=template&id=51c8374d&scoped=true", function () {
      api.rerender('51c8374d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/registration/previousAddressCheck.vue"
export default component.exports