<template>
    <div>
        <div v-if="isResponsive === false">
            <img :src="source" :alt="altText">
        </div>
        <div v-else>
            <div v-show="$mq === 'mobile'">
                <img :src="mobileSource" :alt="altText">
            </div>
            <div v-show="$mq === 'tablet'">
                <img :src="tabletSource" :alt="altText">
            </div>
            <div v-show="$mq === 'desktop'">
                <img :src="source" :alt="altText">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: {
            isResponsive: {
                type: Boolean,
                required: false,
                default: false
            },
            source: {
                type: String,
                required: true
            },
            tabletSource: {
                type: String,
                required: false
            },
            mobileSource: {
                type: String,
                required: false
            },
            altText: {
                type: String,
                required: true
            }
        }
    };

</script>

<style scoped>

</style>


