<template>
    <button @click="moveToTop" v-if="visible">
        <img class="upImage" :src="require('../../assets/images/backToTop.svg')"/>
    </button>
</template>

<script>

    export default {
        name: 'TopButton',
        mounted() {
            this.checkScroll();
            window.onscroll = this.checkScroll;
        },
        data() {
            return {
                visible: false,
                timeoutHandler: null
            };
        },
        methods: {
            checkScroll() {
                window.clearTimeout(this.timeoutHandler);

                if (window.scrollY > 0) {
                    this.visible = true;
                    this.timeoutHandler = window.setTimeout(() => {
                        this.visible = false;
                    }, 2000);
                } else {
                    this.visible = false;
                }
            },
            moveToTop() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    };

</script>

<style lang="less" scoped>

    @import '../../assets/css/common.less';

    .upImage {
        color: white;
    }

    button {
        position: fixed ;
        bottom: 20px;
        right: 30px;
        z-index: 99;
        border: none;
        outline: none;
        width: 60px;
        height: 60px;
        color: white;
        cursor: pointer;
        padding: 6px 14px;
        margin: 6px;
        font-size: 18px;
        border-radius: 36px;
        -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
        background: @experian-dark-blue;
    }

    button:active {
        -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
    }

    button:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 5px;
        background: @experian-white-rgb;
        opacity: 0;
        border-radius: 6px;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
    }

    button:focus:not(:active)::after {
        animation: ripple 1s ease-out;
    }

    button:hover {
        background: @experian-light-blue;
    }

    button:disabled {
        color: #FFFFFF;
        background: @experian-dark-medium-grey;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
        cursor: not-allowed;
    }

</style>
