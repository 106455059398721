var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      {
        class: ["option", _vm.isTrueSelected, _vm.setValidity],
        attrs: { id: "true" },
        on: {
          click: function ($event) {
            return _vm.select(true)
          },
        },
      },
      [_vm._v("\n        " + _vm._s(_vm.trueText) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: ["option", _vm.isFalseSelected, _vm.setValidity],
        attrs: { id: "false" },
        on: {
          click: function ($event) {
            return _vm.select(false)
          },
        },
      },
      [_vm._v("\n        " + _vm._s(_vm.falseText) + "\n    ")]
    ),
    _vm._v(" "),
    _c("p", { class: [_vm.getTextClass()] }, [
      _vm._v("\n        " + _vm._s(_vm.getText()) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }