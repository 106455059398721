<template>
    <div class="cardContainer page">
        <ebaCommonErrorModal ref="commonErrorModal"/>
        <div class="headerTextContainer" v-show="topMessage !== undefined">
            <p v-html="topMessage" class="registrationHeaderText gradientText"></p>
        </div>
        <div class="card">
            <div>
                <router-view v-on:requestCustomProceedText="handleCustomProceedText"
                             v-on:clearCustomProceedText="clearCustomProceedText"
                             v-on:setCustomHeaderText="handleCustomerHeaderText"
                             v-on:clearHeaderText="clearHeaderText"
                             :registrationData="regModel"
                             style="width: 100%;"/>
            </div>
            <div class="cardNavigationButton">
                <ebaButton :is-disabled="isDisabled" style-type="flat" v-on:click.native="previousClick"
                           v-show=showPrevious>
                    Previous
                </ebaButton>
                <ebaButton :is-disabled="isDisabled" :is-loading="isDisabled"
                           v-on:click.native="proceedClick" style-type="raised">
                    {{progressButtonText}}
                </ebaButton>
            </div>
            <br style="clear:both"/>
        </div>
    </div>
</template>

<script>
    // External Script imports
    import {mapGetters, mapActions, mapState} from 'vuex';
    import RegistrationHandler from '../../../scripts/registrationHandler';
    import * as RegStepMutations from '../../../vuex/common/modules/registration/registrationMutations';
    import SecurityHandler from '../../../scripts/common/antiForgeryHandler';
    import LoginHandler from '../../../scripts/common/loginHandler';
    import Store from '../../../vuex/store';
    import Vue from 'vue';

    //Models
    import RegistrationModel from '../../../scripts/models/registrationModel';

    export default {
        name: 'Registration',
        services: ['$http'],
        computed: {
            // Map our getters from Vuex
            ...mapGetters([
                'getCurrentStep',
                'getPreviousExist',
                'isFinalStep',
                'getFormToken',
                'getIsNextEnabled'
            ]),
            isDisabled: function() {
                var isDisabled = !this.getIsNextEnabled;
                if(typeof(isDisabled) == 'undefined') {
                    isDisabled = true;
                }
                return isDisabled;
            },
        },
        beforeRouteEnter: function(to, from, next) {
            // Point the next route to include our first Registration Step
            next(vm => {
                var self = vm;
                //Update next button state
                
                Vue.nextTick().then(()=> {
                    vm.updateIsNextEnabled({isNextEnabled: true});

                    //Get the current registration step (If first hit it will default to the first step)
                self.registrationRoute.name = self.getCurrentStep;

                //Wire up buttons, text, etc...
                self.updateContainer();

                next(self.registrationRoute);
                })
            });
        },
        beforeRouteUpdate: function(to, from, next) {
                //Update next button state
                let self = this;
                Vue.nextTick().then(function() {
                    self.updateIsNextEnabled({isNextEnabled: true});
                    next();
                })
        },
        data() {
            return {
                // Object to store next route
                registrationRoute: {
                    name: ''
                },

                //Handle next and previous button content/visiblity
                showPrevious: false,
                isFinalRegStep: false,
                progressButtonText: 'Continue',
                defaultProgressButtonText: 'Continue',

                //Handle next and previous button clicks
                nextStep: '',
                previousStep: '',

                topMessage: undefined,

                //Registration Model
                regModel: new RegistrationModel(),

                hasCustomProceedText: false
            };
        },
        methods: {
            ...mapActions([
                'moveToNextStep',
                'moveToPreviousStep',
                'setPageToken',
                'updateIsNextEnabled'
            ]),

            handleCustomProceedText(data) {
                if (data.text) {
                    this.progressButtonText = data.text;
                    this.hasCustomProceedText = true;
                }
            },

            clearCustomProceedText() {
                this.progressButtonText = this.defaultProgressButtonText;
                this.hasCustomProceedText = false;
            },

            handleCustomerHeaderText(data) {
                this.topMessage = data.text;
            },

            clearHeaderText() {
                this.topMessage = undefined;
            },

            proceedClick() {
                var self = this;
                this.updateIsNextEnabled({isNextEnabled: false});

                // Get the current step from the page children
                // Children 0 and 1 are the next/previous buttons
                var registrationStep = this.getRegistrationStep();
                if (registrationStep == null) throw 'Unable to find Registration Step';
                registrationStep.isStepValid()
                    .then((result) => {
                        if (result == true) {
                            self.handlePostStepValidation(registrationStep, result);
                        } else {
                            this.updateIsNextEnabled({isNextEnabled: true});
                        }
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            },

            getRegistrationStep() {
                for (var i = 0; i < this.$children.length; i++) {
                    var child = this.$children[i];
                    if (child.$refs.RegistrationStep !== undefined) {
                        return child;
                    }
                }
            },

            handlePostStepValidation(registrationStep, valid) {
                // Handle any post validation actions
                if (registrationStep.handleSubmitAction !== undefined) {
                    registrationStep.handleSubmitAction()
                        .then((response) => {

                            // Handle submit action failed, do not progress but update UI
                            if (response && response.invalid === true) {
                                this.progressStepper(false);
                            } else {
                                // If this is the first step, create page token
                                if (!this.getPreviousExist) {
                                    this.setPageToken().then(() => {
                                        this.progressStepper(valid);
                                    });
                                } else { // Else progress
                                    this.progressStepper(valid);
                                }
                            }
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                } else {
                    if (!this.getPreviousExist) {
                        this.setPageToken().then(() => {
                            this.progressStepper(valid);
                        });
                    } else { // Else progress
                        this.progressStepper(valid);
                    }
                }
            },

            progressStepper(valid) {
                if (valid) {
                    if (!this.isFinalRegStep) {
                        this.moveToNextStep()
                            .then((nextStep) => {
                                // Dont move to the next step if this is the final step
                                this.$router.push({
                                    name: nextStep
                                });

                                this.updateContainer();
                            })
                            .catch((error) => {
                                this.handleError(error);
                            });
                    } else {
                        var self = this;

                        var childStep = this.getRegistrationStep();
                        childStep.PaymentFailedFlag = false;

                        // Validate anti forgery to check for timeout
                        //SecurityHandler.validateAntiForgeryToken(this.getFormToken)
                        //    .then((resp) => {
                        //       if (resp) {
                                    RegistrationHandler.registerUser(self.regModel.getJSON())
                                        .then((response) => {
                                            var error = {
                                                valid: true,
                                                message: '',
                                                data: undefined
                                            };

                                            if (response.data) {
                                                const userId = response.data.userId;
                                                const customerId = response.data.customerId;
                                                const registrationStatus = response.data.registrationStatus;

                                                // Require all values
                                                if (userId && customerId && registrationStatus) {
                                                    const redirectObj = RegistrationHandler.getRegistrationRedirect(userId, customerId, registrationStatus);
                                                    LoginHandler.login(self.regModel.EmailAddress, self.regModel.Password, false)
                                                        .then((response) => {
                                                            if (response.Success === true) {
                                                                if (redirectObj) {
                                                                    self.$router.push(redirectObj);
                                                                } else {
                                                                    error.valid = false;
                                                                    error.message = 'Unable to perform redirect based on Registraiton Status: ' + registrationStatus;
                                                                    error.data = response.Success;
                                                                }
                                                            }
                                                            else {
                                                                error.valid = false;
                                                                error.message = response.Message;
                                                                error.data = response.Success;
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            error.valid = false;
                                                            error.message = 'Login error';
                                                            error.data = error;
                                                        });
                                                } else {
                                                    error.valid = false;
                                                    error.message = 'Invalid data from API';
                                                    error.data = response.data;
                                                }
                                            } else {
                                                error.valid = false;
                                                error.message = 'No response data from API';
                                                error.data = response;
                                            }

                                            if (error.valid === false) {
                                                self.handleError(error);
                                            }
                                        })
                                        .catch((error) => {
                                            // Reset the anti forgery token as the other one will have been consumed
                                            self.setPageToken()
                                                .then(() => {
                                                    if (error.response.data === 'PreAuthFailed' || error.response.data === 'PaymentFailed') {
                                                        self.isButtonLoading = false;

                                                        var childStep = this.getRegistrationStep();
                                                        childStep.PaymentFailedFlag = true;

                                                        window.scroll({
                                                            top: 0,
                                                            left: 0,
                                                            behavior: 'smooth'
                                                        });
                                                    } else {
                                                        self.handleError(error);
                                                    }
                                                });
                                        });
                                //} else {
                                 //   this.handleError('Unable to validate AntiForgery token. Response: ' + resp);
                               // }
                            // })
                            // .catch(() => {
                            //    alert('Session expired');
                            //    window.location.reload();
                            // });
                    }
                } else {
                    // Not progressing, so stop the spinner
                    this.isButtonLoading = false;
                }
            },

            previousClick() {
                this.moveToPreviousStep()
                    .then((previousStep) => {
                        //Do another check just to make sure the previous button is not disabled
                        if (this.showPrevious) {
                            this.$router.push({
                                name: previousStep
                            });
                        }

                        this.updateContainer();
                    });
            },

            updateContainer() {
                //Check to see if a previous step exists
                this.showPrevious = this.getPreviousExist;
                //Check to see if this the final step
                if (this.isFinalStep) {
                    this.isFinalRegStep = true;
                    this.progressButtonText = 'Finish';
                } else {
                    this.isFinalRegStep = false;
                    if (!this.hasCustomProceedText) {
                        this.progressButtonText = 'Continue';
                    }

                    // Reset flag for next step
                    this.hasCustomProceedText = false;
                }
            },

            handleError(error) {
                if (error) {
                    console.log(error);
                }

                this.$refs.commonErrorModal.show();
                this.isButtonLoading = false;
            }
        }
    };

</script>

<style lang="less">

    .headerTextContainer {
        width: 50%;
        margin: 0 auto;
    }

    .registrationHeaderText {
        text-align: center;
        margin: 0 auto 8px auto;
        padding: 4px 0 12px 0;
        font-size: 22px;
        line-height: 28px;
        width: 100%;
    }
/* Mobile */
    @media only screen and (max-width: 560px) {
       .headerTextContainer {
        width: 90%;
        margin: 0 auto;
    }
    }
</style>
