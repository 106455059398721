var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "results",
      attrs: { disabled: _vm.disableButton },
      on: { click: _vm.directorSelect },
    },
    [
      _c("div", [
        _c("div", { staticClass: "resultDetails" }, [
          _c(
            "div",
            { staticClass: "propertyLine", attrs: { id: "directorName" } },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.DirectorResultObj.firstName) +
                  " " +
                  _vm._s(_vm.DirectorResultObj.surname) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "propertyLine", attrs: { id: "businessName" } },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.businessName) +
                  " (" +
                  _vm._s(_vm.businessRef) +
                  ")\n            "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { float: "right" } },
          [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading === false,
                  expression: "loading === false",
                },
              ],
              staticClass: "actionImage",
              attrs: { src: require("../common/assets/Images/MoveOn.svg") },
            }),
            _vm._v(" "),
            _c("ebaSpinner", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading === true,
                  expression: "loading === true",
                },
              ],
              staticClass: "actionImage",
              attrs: { useAlt: true },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }