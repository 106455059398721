import * as types from './businessSearchMutations';

var BusinessModel = {
    businessRef: '',
    locationLine1: '',
    locationLine2: '',
    locationLine3: '',
    locationLine4: '',
    name: '',
    postcode: ''
}

var DirectorModel = {
    firstName: '',
    middlesNames: '',
    officerId: '',
    surname: '',
    urn: ''
}

//Initialise the state as the first step in the registrationOrder array
let state = {
    Business: BusinessModel,
    Director: DirectorModel,
    HasDirector: false,
    HasBusiness: false,
    Flagged: false
};

const getters = {
    getDirectorName: state => {
        if (state.Director) {
            return state.Director.firstName + ' ' + state.Director.surname;
        }

        return undefined;
    },
    showPackageDetails: state => {
        if (state.HasDirector === true && state.HasBusiness === true) {
            return true;
        }

        return false;
    }
};

const mutations = {
    setBusinessState(state, payload) {
        state.Business = payload;
        state.HasBusiness = true;
    },
    setDirectorState(state, payload) {
        state.Director = payload;
        state.HasDirector = true;
    },
    clearExistingDataMutation(state) {
        state.Director = DirectorModel;
        state.Business = BusinessModel;

        state.HasBusiness = false;
        state.HasDirector = false;

        state.Flagged = undefined;
    }
};

const actions = {
    setBusiness({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            try {
                commit(types.SET_BUSINESS, payload);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    },
    setDirector({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            try {
                commit(types.SET_DIRECTOR, payload);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    },
    clearExistingData({commit}) {
        return new Promise((resolve, reject) => {
            try {
                commit(types.CLEAR_EXISTING_DATA);
                resolve();
            } catch (error) {
                reject(error);
            }
        })
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};

