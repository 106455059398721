<template>
    <div class="registrationPage">
        <div class="registrationContainer">
            <div class="body">
                <div class="alertIcon">
                    <img :src="require('../../components/common/assets/images/alert.svg')" alt="Alert"/>
                </div>
                <h3 class="gradientText">We encountered an issue processing your details.</h3>
                <div class="informationText">
                    This may be caused by one of the following reasons:
                    <ol class="reasonList">
                        <li>
                            You may have entered your Home Address or Date of Birth incorrectly. For security
                            reasons, please wait 24 hours and try registering again.<br>
                        </li>
                        <li>
                            The Home Address you have entered for your directorship may be incorrect at Companies
                            House.
                            <a href="http://www.companieshouse.gov.uk/forms/generalForms/CH01_change_of_directors_details.pdf"
                               class="addressLink" target="_blank">Find out how to change your address</a>
                            <br>
                        </li>
                        <li>
                            If your Home Address details are correct and you have verified this with Companies
                            House, please call us on <a class="addressLink"
                                                        :href="'tel:' + configObj.footerDetails.supportPhoneNumber">{{configObj.footerDetails.supportPhoneNumber}}</a>
                            {{configObj.footerDetails.callChargeText}},
                            Mon - Thurs 8:30am to 5:30pm, Fri 8:30am to 5:00pm.<br>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {get as config} from '../../scripts/common/config';

    export default {
        name: 'UraLockout',
        props: {
            registrationData: {
                type: Object
            }
        },
        data() {
            return {
                configObj: config()
            }
        }
    };

</script>

<style scoped lang="less">

    @import '../../components/common/assets/css/common.less';

    .addressLink {
        color: @experian-sapphire;
    }

    .sapphireText {
        color: @experian-sapphire;
    }

    .alertIcon {
        text-align: center;
    }

    .alertIcon img {
        padding: 10px;
        width: 100px;
    }

    .detailSection {
        float: left;
        margin: 8px 72px 16px 0;
    }

    .detailHeading {
        font-weight: bold;
        color: @experian-dark-grey;
        font-size: 14px;
        margin: 14px 0;
    }

    .reasonList {

        li:not(:last-child) {
            margin-bottom: 12px;
        }

        padding-left: 20px;
    }

    .registrationContainer {
        width: 45%;
        margin: 0 auto 54px auto;
        padding: 32px 32px 24px 32px;
        -webkit-box-shadow: 0 0 8px @experian-dark-medium-grey;
        -moz-box-shadow: 0 0 8px @experian-dark-medium-grey;
        box-shadow: 0 0 8px @experian-dark-medium-grey;
        z-index: 11;
    }

    @media only screen and (max-width: 1024px) {
        .registrationContainer {
            width: 80%;
            margin: 0 auto 48px auto;
        }
    }

    @media only screen and (max-width: 560px) {

        .registrationContainer {
            width: 90%;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
    }

</style>
