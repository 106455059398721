import Vue from 'vue';
import VueGtm from 'vue-gtm';
import App from './app.vue';
import store from './vuex/store';
import router from './router';
import {sync} from 'vuex-router-sync';
import interceptorsSetup from './scripts/common/interceptors';

import Vuec from 'vue-container';
import inject from './scripts/service/dependenyInjection';
import ComponentRepository from './scripts/service/componentRepository';

// Global error handling hook
Vue.config.errorHandler = function (err, vm, info) {
    console.log('Error: ');
    console.log(err);

    if (process.env.NODE_ENV === 'qa' || process.env.NODE_ENV === 'dev') {
        console.log('VM: ');
        console.log(vm);

        console.log('Info: ');
        console.log(info);
    }
};

Vue.config.performance = true;

function installPlugins() {

    // Import dependency injection
    Vue.use(Vuec);
    inject(Vue);

    Vue.use(VueGtm, {
        id: (process.env.NODE_ENV !== "production" ? 'GTM-NVPNLWK' : "GTM-W2QB53"),
        debug: process.env.NODE_ENV !== 'production',
        vueRouter: router
    });
    /* Plugins */
    // Import config for global use
    Vue.use(require('./scripts/common/config.js').install(Vue));

    //Import Media Query Handler
    Vue.use(require('./scripts/common/mediaQueryHandler.js').install);

    // Project CSS
    require('./assets/css/site.less');
    require('./components/common/assets/css/common.less');
    require('./components/common/assets/css/fontIcons.less');

    interceptorsSetup();
}

export function createApp() {
    sync(store, router);

    //Register all components globally
    ComponentRepository.registerComponents(Vue);

    //Include external libraries here. If you dont use process.browser, the require will try to run on the
    //Server. As it has no reference to the browser window, it will not work
    if (process.browser) {
        installPlugins();
    }

    const app = new Vue({
        router,
        store,
        render: h => h(App)
    });

    return {app, router, store};
}
