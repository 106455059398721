var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "header" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("span", { staticClass: "gradientText" }, [
        _c("strong", [_vm._v(_vm._s(_vm.commonProperties.customerName))]),
        _vm._v(", we are sorry you want to end your membership."),
        _c("br"),
        _vm._v(
          "\n             If you cancel you will lose these premium benefits.\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "spacer-line" }),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: { src: require("../../assets/Images/companionWarning.svg") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("div", [
        _c("span", { staticClass: "gradientText" }, [
          _c("strong", [_vm._v("See what lenders see with daily updates")]),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n                The most accurate and fully comprehensive view of your own business credit profile.\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticClass: "gradientText" }, [
          _c("strong", [_vm._v("Monitoring with automatic alerts")]),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n                Receive alerts about any changes to your business credit report, so you can act quickly to reduce any negative impact to your score.\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticClass: "gradientText" }, [
          _c("strong", [
            _vm._v("Premium customer support (phone, email and chat)"),
          ]),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n                On-going support to help make your business credit score the best it can be.\n            "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }