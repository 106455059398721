var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "span",
      { attrs: { value: _vm.value } },
      [
        _c("ebaTextInput", {
          ref: "dayInput",
          staticClass: "datePart",
          attrs: {
            id: "day",
            inputType: "number",
            inputID: "dayInput",
            invalidText: "",
            "place-holder": "DD",
          },
          on: { onChange: _vm.updateValue },
          model: {
            value: _vm.value.day,
            callback: function ($$v) {
              _vm.$set(_vm.value, "day", $$v)
            },
            expression: "value.day",
          },
        }),
        _vm._v(" "),
        _c("ebaTextInput", {
          ref: "monthInput",
          staticClass: "datePart",
          attrs: {
            id: "month",
            inputType: "number",
            inputID: "monthInput",
            invalidText: "",
            "place-holder": "MM",
          },
          on: { onChange: _vm.updateValue },
          model: {
            value: _vm.value.month,
            callback: function ($$v) {
              _vm.$set(_vm.value, "month", $$v)
            },
            expression: "value.month",
          },
        }),
        _vm._v(" "),
        _c("ebaTextInput", {
          ref: "yearInput",
          staticClass: "datePart",
          attrs: {
            id: "year",
            inputType: "number",
            inputID: "yearInput",
            invalidText: "",
            "place-holder": "YYYY",
          },
          on: { onChange: _vm.updateValue },
          model: {
            value: _vm.value.year,
            callback: function ($$v) {
              _vm.$set(_vm.value, "year", $$v)
            },
            expression: "value.year",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _c("p", { class: [_vm.getValidClass()], attrs: { id: "infoText" } }, [
        _vm._v(_vm._s(_vm.getLabelText())),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }