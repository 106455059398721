import {get as config} from './common/config.js';
import axios from "axios";

class AuthenticationHandler {

    static authenticate() {
        return new Promise(function (resolve, reject) {
            axios({
                method: 'POST',
                url: config().AuthenticationEndpoint,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {}
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

export {AuthenticationHandler as default};
