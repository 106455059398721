var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "spinner",
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 66 66",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        class: _vm.useAltSpinner(),
        attrs: {
          fill: "none",
          "stroke-width": "6",
          "stroke-linecap": "round",
          cx: "33",
          cy: "33",
          r: "30",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }