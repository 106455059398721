var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasValidRoute()
    ? _c(
        "span",
        [
          _c(
            "router-link",
            { attrs: { to: _vm.routeName } },
            [_vm._t("default")],
            2
          ),
        ],
        1
      )
    : _c("span", [
        _c(
          "a",
          { attrs: { href: _vm.linkUrl, target: _vm.target } },
          [_vm._t("default")],
          2
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }