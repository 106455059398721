<template>
    <div class="container">
        <div class="header">
            <div class="image">
                <img src="../../assets/Images/understand.svg" />
            </div>
            <span class="gradientText">
                <strong>{{commonProperties.customerName}}</strong>, we're sorry to see you go!
                <br />Please help us understand the reason why.
            </span>
        </div>
        <div class="spacer-line"></div> 
        <ebaRadioGroup
            :items="reasons"
            v-on:option-selected="cancellationReasonSelected"
            :valid="!(buttonClicked == true && answer == null)">
        </ebaRadioGroup>
        <span class="validation-text" v-if="(buttonClicked == true && answer == null)">
            Please choose a cancellation reason
        </span>
    </div>
</template>

<script>
import cancellationHandler from "../../scripts/cancellationHandler";
export default {
    name: "StepFour",
    data() {
        return {
            customerName: "",
            reasons: [],
            answer: null,
            buttonClicked: false, 
        };
    },
    props: {
        commonProperties: {
            default: null,
            type: Object
        }
    },
    mounted() {
        const context = this;
        var buttons = [
            {
                type: "secondary",
                text: "View offer",
                action() {
                    context.$emit("action", [
                        "progressToStep", 
                        "ebaSubCancellationStepTwo"
                    ]);
                }
            },
            {
                type: "primary",
                text: "Close account",
                action() {
                    context.buttonClicked = true;
                    if(context.answer != null) {
                        context.$emit("action", [
                            "closeAccount",
                            context.answer
                        ]);
                    }
                    else{
                        context.invalid = true;
                    }
                }
            }
        ]
        if(this.commonProperties.isOfferUsed) {
           buttons = buttons.filter(a=> a.type != "secondary");
        }

        this.$emit("generateNavButtons", buttons);
        this.reasons = this.commonProperties.cancellationReasons;
    },
    methods: {
        cancellationReasonSelected(val) {
            this.answer = val;
            console.log(val);
        }
    }
};
</script>
<style lang="less" scoped>
    @import "../../components/common/assets/css/colours.less";
    .validation-text {
        font-size: 0.75em;
        color: @experian-danger-error;
    }
</style>

