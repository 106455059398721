<template>
    <div>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Have you lived at this address for at least 12 months?"
                             :showStepNumber="false">
            <div>
                <ebaSwitch ref="recentAddress"
                           v-model="registrationData.IsRecentAddress"
                           v-on:onChange="switchOnChange"/>
            </div>
        </ebaRegistrationStep>
        <ebaRegistrationStep v-show="showAddressEntry" ref="RegistrationStep"
                             step-title="What's your previous home address?" :showStepNumber="false">
            <div>
                <ebaTextInput ref="houseNumberName"
                              autocomplete='address-line1'
                              v-model="registrationData.SearchPreviousHouseNumberName"
                              inputID="houseNumberNameInput"
                              invalidText="You must specify a Building Number/Name"
                              place-holder="Building Number/Name"
                              :validation="anyCharacterValidationRegex"/>
                <ebaTextInput ref="postCode"
                              autocomplete='postal-code'
                              v-model="registrationData.SearchPreviousPostcode"
                              inputID="postcodeInput"
                              invalidText="You must specify a valid postcode"
                              place-holder="Postcode"
                              :validation="postcodeValidationRegex"/>
            </div>
            <br style="clear:both">
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import {mapActions} from 'vuex';
    import validationHandler from '../../scripts/common/validationHandler';

    export default {
        name: 'previousAddressCheck',
        props: {
            registrationData: {
                type: Object
            }
        },
        data() {
            return {
                showAddressEntry: false,
                anyCharacterValidationRegex: '',
                postcodeValidationRegex: ''
            };
        },
        mounted() {
            this.$emit('clearHeaderText');
            this.$emit('clearCustomProceedText');

            this.anyCharacterValidationRegex = validationHandler.getValidAnyCharacterPattern();
            this.postcodeValidationRegex = validationHandler.getValidPostcodePattern();
        },
        methods: {

            ...mapActions([
                'setupPreviousAddress',
                'removePreviousAddress'
            ]),

            switchOnChange(event) {
                if (event === false) {
                    this.showAddressEntry = true;

                    this.$refs.houseNumberName.clearValidation();
                    this.$refs.postCode.clearValidation();

                    this.$emit('requestCustomProceedText', {text: 'Search'});
                } else {
                    this.showAddressEntry = false;
                    this.$emit('clearCustomProceedText');
                }
            },
            isStepValid: function () {
                return new Promise(function (resolve, reject) {
                    var isValid = true;

                    if (this.$refs.recentAddress.value === false) {
                        if (!this.$refs.recentAddress.validate()) {
                            isValid = false;
                        }

                        if (!this.$refs.houseNumberName.validate()) {
                            isValid = false;
                        }

                        if (!this.$refs.postCode.validate()) {
                            isValid = false;
                        }
                    }

                    resolve(isValid);
                }.bind(this));
            },
            handleSubmitAction() {
                return new Promise(function (resolve) {
                    if (this.$refs.recentAddress.value === false) {
                        this.setupPreviousAddress()
                            .then(() => {
                                // Clear any existing data
                                this.registrationData.PreviousAddressResults = [];

                                var queryString = this.$refs.houseNumberName.value + ',' + this.$refs.postCode.value;

                                // Get address information
                                this.$services.$http({
                                    method: 'GET',
                                    url: encodeURI(this.$config.QASApi + '/query/' + queryString),
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(response => {
                                        if (response.status === 200) {
                                            var data = response.data;
                                            var QASResults = [];
                                            for (var i = 0; i < data.Count; i++) {
                                                var suggestion = data.SuggestionList.$values[i];
                                                QASResults.push({
                                                    name: suggestion.Suggestion,
                                                    value: suggestion.QasId
                                                });
                                            }

                                            this.registrationData.PreviousAddressResults = QASResults;

                                            resolve();
                                        }
                                    })
                                    .catch(error => {
                                        if (error.response) {
                                            console.log(error.response.data);
                                        } else {
                                            console.log(error);
                                        }

                                        resolve();
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                                resolve();
                            });
                    } else {
                        this.removePreviousAddress()
                            .then(() => {

                                // Reset data
                                this.registrationData.SearchPreviousHouseNumberName = '';
                                this.registrationData.SearchPreviousPostcode = '';

                                this.registrationData.PreviousAddress.BuildingNameNumber = '';
                                this.registrationData.PreviousAddress.Address = '';
                                this.registrationData.PreviousAddress.Town = '';
                                this.registrationData.PreviousAddress.Postcode = '';

                                resolve();
                            })
                    }
                }.bind(this));
            }
        }
    };

</script>

<style lang="less" scoped>

</style>

