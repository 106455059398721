class validationHandler {

    static getValidationStates() {
        return Object.freeze({
            INVALID: -1,
            INITIAL: 0,
            VALID: 1
        });
    }

    static getDefaultInputValidation(inputType) {
        switch (inputType.toLowerCase()) {
            case 'text':
                return this.getTextAndNumbersPattern();
            case 'password':
                return this.getValidPasswordPattern();
            case 'number':
                return this.getValidNumberPattern();
            case 'email':
                return this.getValidEmailAddressPattern();
            case 'tel':
                return this.getValidTelephonePattern();
            default:
                return '';
        }
    }

    static getTextAndNumbersPattern() {
        return '^[a-zA-Z0-9]*$';
    }

    static getTextAndNumbersSpacesPattern() {
        return '^[a-zA-Z 0-9]*$';
    }

    static getTextNumbersSpacesHyphensPattern() {
        return '^[a-zA-Z 0-9-]*$';
    }

    static getValidPasswordPattern() {
        return '(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!.*\\s)(?=.*[0-9])(?=.{8,50})';
    }
    static getValidPasswordPatternMBP() {
        return '^(?=.*[a-z])((?=.*[A-Z])|(?=.*[$@$!%*#?&]))(?=.*[0-9]{2})(?=.*[^\da-zA-Z]).{8,}$';
    }

    static getValidEmailAddressPattern() {
        return '^([a-zA-Z0-9_\\-\\.\\+\\-\\\']+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,8})$';
    }

    static getValidNumberPattern() {
        return '^[0-9]*\\S$';
    }

    static getValidTelephonePattern() {
        return '^(([\\+]?)[0-9]{0,2})([0-9\\\s()]{6,17})$';
    }

    static getValidNamePattern () {
        return '^[a-zA-Z 0-9\'-.áéíóúäöÁÉÍÓÚ]*$';
    }

    static getValidAddressPattern () {
        return '^[\\w().\\/,.!\'& -]*$';
    }

    static getValidAnyCharacterPattern () {
        return '^(?!\\s*$).+';
    }

    static getValidPostcodePattern () {
        return '^[a-zA-z]{1,2}[0-9]{1,2}[a-zA-Z]{0,1}[ ]{0,1}[0-9]{1}[a-zA-z]{2}$';
    }

    static getSecurityQuestionPattern () {
        return '^[a-zA-Z 0-9&-]*$';
    }

    //to ensure all array values are unique
    //e.g. password, memorable word and security answer are different
    static arrayUniqueValueCheck(value, arr) {
        var lowerVal = value.toLowerCase();
        for (var i = 0; i < arr.length; i++) {
            if (lowerVal.indexOf(arr[i].toLowerCase()) >= 0) {
                return false;
            }
        }
        return true;
    }

    //for use with confirm inputs, e.g. confirm password, memorable word etc.
    static valueMatchCheck(value1, value2) {
        if (value1.toLowerCase() === value2.toLowerCase()) {
            return true;
        }

        return false;
    }

    static valueMatchCheckCaseSensitive(value1, value2) {
        if (value1 === value2) {
            return true;
        }

        return false;
    }

    //for use with passwords to ensure no consecutive numbers or characters are entered
    //value = string
    //charLen = consecutive length check for string characters, e.g. 4 for characters
    //numLen = consecutive length check for numerical characters, e.g. 2 for numbers (1 only returns 1 char)
    static consecutiveCharacterCheck(value, charLen, numLen) {
        var stringLength = value.length;
        var subString = '';
        for (var i = 0; i < stringLength; i++) {

            var numCheck = isNaN(value.substring(i, i + 1));

            if (!numCheck && i < i + numLen) {
                subString = value.substring(i, i + numLen);
            } else if (i < i + charLen) {
                subString = value.substring(i, i + charLen);
            }
            
            //"...new Set(subString)"" gets only the unique characters in the substring. the dots are required
            //therefore if there's only 1 unique character return false;
            if (String.prototype.concat(...new Set(subString)).length === 1) {
                return false;
            }
        }

        return true;
    }

    static repeatingCharacterCheck(value) {
        var storedCharacter = '';
        var repeatedCharacterString = '';
        var stringLength = value.length;

        for (var i = 0; i < stringLength; i++) {
            var character = value.substring(i, i + 1);

            if (storedCharacter === character) {
                repeatedCharacterString += character;
            } else {
                storedCharacter = character;
                repeatedCharacterString = character;
            }

            if (repeatedCharacterString.Length >= 4) {
                return true;
            }
        }

        return false;
    }
    static consecutiveNumberCheck(value) {
        var stringLength = value.length;
        var storedChar = null;

        for (var i = 0; i < stringLength; i++) {            

            if (isNaN(value.substring(i, i + 1))) {
                storedChar = null;
                continue;
            }

            var number = parseInt(value.substring(i, i + 1));

            if (i === 0) {
                storedChar = number;
                continue;
            }

            if (storedChar !== null && (number === storedChar + 1 || number === storedChar - 1)) {
                return true;
            } else {
                storedChar = number;
            }
        }
    }
    
    //to ensure the value does not include any of the array values
    //e.g. emailaddress is not included in password 
    static arrayContainValueCheck(value, arr) {
        var lowerVal = value.toLowerCase();
        for (var i = 0; i < arr.length; i++) {
            var res = arr[i].split("@");
            if (lowerVal.search(arr[i].toLowerCase()) >= 0 || lowerVal.search(res[0].toLowerCase()) >= 0) {
                return false;
            }
        }
        return true;
    }
}

export { validationHandler as default };
