import { render, staticRenderFns } from "./redirect.vue?vue&type=template&id=985e2340"
import script from "./redirect.vue?vue&type=script&lang=js"
export * from "./redirect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../Server/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\data\\jenkins_home\\workspace\\Web_Applications\\Deploy_MBPUX_Site\\Server\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('985e2340')) {
      api.createRecord('985e2340', component.options)
    } else {
      api.reload('985e2340', component.options)
    }
    module.hot.accept("./redirect.vue?vue&type=template&id=985e2340", function () {
      api.rerender('985e2340', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/redirect.vue"
export default component.exports