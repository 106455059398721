import Store from '../../vuex/store';

class RegistrationModel {

    EmailAddress = null;
    Telephone = null;

    Title = null;
    FullName = null;

    DateOfBirth = {
        day: '',
        month: '',
        year: ''
    };

    SearchHouseNumberName = '';
    SearchPostcode = '';
    CurrentAddressResults = [];
    CurrentAddress = {
        FlatNumber: '',
        BuildingNameNumber: '',
        Address: '',
        Town: '',
        Postcode: ''
    };

    IsRecentAddress = true;

    SearchPreviousHouseNumberName = '';
    SearchPreviousPostcode = '';
    PreviousAddressResults = [];
    PreviousAddress = {
        FlatNumber: '',
        BuildingNameNumber: '',
        Address: '',
        Town: '',
        Postcode: ''
    };

    MemorableWordDetails = {
        MemorableWord: '',
        ConfirmMemorableWord: '',
        MemorableHint: ''
    };

    SecurityQuestionDetails = {
        SecurityQuestion: '',
        SecurityAnswer: '',
        ConfirmSecurityAnswer: ''
    };

    Password = '';
    ConfirmPassword = '';

    BillingDetails = {
        NameOrNumber: '',
        Street: '',
        TownOrCity: '',
        Postcode: ''
    };

    ProductCode = "";
    ProductVariantSku = "";

    MarketingOptIn = false;
    ThirdPartyOptIn = false;
    TermsAndConditions = false;

    PaymentMethodId = null;

    getJSON() {
        var returnObj = {
            DirectorDetails: Store.state.businessSearch.Director,
            BusinessDetails: Store.state.businessSearch.Business,
            Title: this.Title,
            FullName: this.FullName,
            DateOfBirth: this.DateOfBirth,
            Telephone: this.Telephone,
            EmailAddress: this.EmailAddress,
            Password: this.Password,
            ConfirmPassword: this.ConfirmPassword,
            MemorableWordDetails: this.MemorableWordDetails,
            SecurityQuestionDetails: this.SecurityQuestionDetails,
            CurrentAddress: this.CurrentAddress,
            TermsAndConditions: this.TermsAndConditions,
            MarketingOptIn: this.MarketingOptIn,
            ThirdPartyOptIn: this.ThirdPartyOptIn,
            ProductCode: this.ProductCode,
            ProductVariantSku: this.ProductVariantSku,
            BillingDetails: this.BillingDetails,
            PaymentMethodId: this.PaymentMethodId
        };

        if (!this.IsRecentAddress) {
            returnObj["PreviousAddress"] = this.PreviousAddress;
        }

        return returnObj;
    }
}

export {RegistrationModel as default};
