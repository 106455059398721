<template>
        <ul>
            <li class="listStyle" v-for="criteria in criteriaList" :key="criteria.id">
                <span v-if="criteria.isLoading">
                    <ebaSpinner class="listIcon" :useAlt="true"/>
                    <span :class="['listDescription', criteria.showInvalid ? 'invalid' : criteria.fullfilled ? 'valid' : 'initial']">
                        {{criteria.description}}
                    </span>
                </span>
                <span v-else>
                    <span v-if="criteria.showInvalid">
                        <span v-show="criteria.fullfilled === false" class="listIcon">
                            <img :src="require('../assets/images/error.svg')"/>
                        </span>
                    </span>
                    <span v-else>
                        <span v-show="criteria.fullfilled === false" class="listIcon">
                            <img :src="require('../assets/images/initialCriteria.svg')"/>
                        </span>
                        <span v-show="criteria.fullfilled === true" class="listIcon">
                            <img :src="require('../assets/images/success.svg')"/>
                        </span>
                    </span>
                    <span :class="['listDescription', criteria.showInvalid ? 'invalid' : criteria.fullfilled ? 'valid' : 'initial']">
                        {{criteria.description}}
                    </span>
                </span>
            </li>
        </ul>
</template>

<script>
    export default {
        name: "criteriaList",
        data() {
            return {
                criteriaList: [],
                reference: undefined,
            };
        },
        methods: {
            setObjectReference(obj) {
                this.reference = obj;
                this.reference.onChange = (value) => {
                    this.checkCriteria(value);
                }
            },
            setCriteria(criteria) {
                if (criteria && criteria.length > 0) {
                    for (let i = 0; i < criteria.length; i++) {
                        this.criteriaList.push({
                            id: i,
                            description: criteria[i].description,
                            requirement: criteria[i].requirement,
                            fullfilled: false,
                            isLoading: false,
                            showInvalid: false
                        });
                    }
                }
            },
            checkCriteria(value) {
                return new Promise((resolve, reject) => {
                    if (this.criteriaList.length > 0) {
                        for (let i = 0; i < this.criteriaList.length; i++) {
                            if (typeof (this.criteriaList[i].requirement) === 'function') {
                                this.criteriaList[i].isLoading = true;
                                this.criteriaList[i].showInvalid = false;
                                let promise = this.criteriaList[i].requirement(value)
                                    .then(() => {
                                        this.criteriaList[i].fullfilled = true;
                                        this.criteriaList[i].showInvalid = false;
                                    })
                                    .catch(() => {
                                        this.criteriaList[i].fullfilled = false;
                                    })
                                    .finally(() => {
                                        this.criteriaList[i].isLoading = false;
                                        resolve();
                                    });
                            }
                        }
                    }
                });
            },
            invalidateCriteria() {
                if (this.criteriaList.length > 0) {
                    for (let i = 0; i < this.criteriaList.length; i++) {
                        if (this.criteriaList[i].fullfilled === false) {
                            this.criteriaList[i].showInvalid = true;
                        } else {
                            this.criteriaList[i].showInvalid = false;
                        }
                    }
                }
            },
            isCriteriaValid() {
                let self = this;
                let value = self.reference.value;

                return new Promise((resolve) => {
                    //Recheck all criteria
                    self.checkCriteria(value)
                        .finally(() => {
                            let result = true;
                            for (let i = 0; i < this.criteriaList.length; i++) {
                                if (this.criteriaList[i].fullfilled === false) {
                                    this.invalidateCriteria();
                                    result = false;
                                }
                            }

                            resolve(result);
                        });
                })
            }
        }
    }
</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    ul {
        display: block;
        margin-left: 4px;
        padding: 0;
        text-align: left;
    }

    .initial {
        color: @experian-dark-medium-grey;
    }

    .valid {
        color: @experian-success;
    }

    .invalid {
        color: @experian-danger-error;
    }

    .listStyle {
        list-style: none;
        text-align: left;
        height: 20px;
        margin-bottom: 6px;
        display: inline-block;
        padding: 4px 12px 0 4px;
    }

    .listIcon {
        margin-right: 4px;
        height: 20px;
    }

    .listIcon img {
        width: 20px;
        height: 20px;
        padding-bottom: 4px;
        vertical-align: middle;
    }

    .listDescription {
        min-height: 20px;
        line-height: 20px;
        text-align: right;
    }

    @media only screen and (max-width: 560px) {

        .listStyle {
            width: 100%;
        }
    }

</style>
