<template>
    <div class="container">
        <div class="header">
            <div class="image">
                <img src="../../assets/Images/companionDanger.svg" />
            </div>
            <span class="gradientText">
                <strong>{{commonProperties.customerName}}</strong>, has it really got to end like this?
                <br />Before you go, we're sure our free product can help...
            </span>
        </div>
        <div class="spacer-line"></div>
        <div class="text">
            <div>
                <span class="gradientText">
                    <strong>Basic credit profile updated monthly</strong>
                </span>

                <span>A detailed view of your own business credit profile.</span>
            </div>
            <div>
                <span class="gradientText">
                    <strong>Basic email support</strong>
                </span>
                <span>Email support to help you and your business.</span>
            </div>

            <div>
                <span class="gradientText">
                    <strong>Upgrade to premium anytime and enjoy...</strong>
                </span>
                <span>The most accurate and fully comprehensive view of your own business credit profile.</span>
            </div>
            <div>
                <span>Receive alerts about any changes so you can act quickly to reduce any negative impact to your credit score.</span>
                
            </div>
            <div>
                <span>Premium customer support (phone, email and chat).</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StepThree",
    data() {
        return {
            customerName: ""
        };
    },
    props: {
        commonProperties: {
            default: null,
            type: Object
        }
    },
    mounted() {
        const context = this;
        this.$emit("generateNavButtons", [
            {
                type: "secondary",
                text: "No thanks",
                action() {
                    context.$emit("action", [
                        "progressToStep",
                        "ebaSubCancellationStepFour"
                    ]);
                }
            },
            {
                type: "primary",
                text: "Use free",
                action() {
                    context.$emit("action", ["downgradePlan", true]);
                }
            }
        ]);

        context.$emit("action", [
                        "getCancellationReasons"
                    ]);
    }
};
</script>

<style lang="less" scoped>
@import "../../components/common/assets/css/common.less";
</style>

