var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    {
      class: [_vm.getTypeClass(), _vm.setIsPromo()],
      on: {
        blur: function ($event) {
          return _vm.test()
        },
      },
    },
    [
      _c("tbody", [
        _c(
          "tr",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasPromo,
                expression: "hasPromo",
              },
            ],
          },
          [
            _c("td", { staticStyle: { padding: "0" } }, [
              _c("div", { staticClass: "promoTop" }, [
                _c("p", { staticClass: "promoText" }, [
                  _vm._v(_vm._s(_vm.promoText)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "arrow-down" }),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("tr", [
          _c("td", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.imageSource !== undefined &&
                      _vm.imageSource !== null &&
                      _vm.showImage === true,
                    expression:
                      "imageSource !== undefined && imageSource !== null && showImage === true",
                  },
                ],
                staticClass: "image",
              },
              [
                _c("img", {
                  attrs: { src: _vm.imageSource, alt: "productImage" },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c(
            "td",
            {
              staticClass: "titleText",
              staticStyle: { "font-weight": "bold" },
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.titleText) + "\n            "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "infoText" }, [
            _c(
              "div",
              {
                staticClass: "threeColContent",
                staticStyle: { "min-height": "40px" },
              },
              [_vm._t("default")],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c(
            "td",
            [
              _c(
                "ebaButton",
                {
                  staticClass: "learnMoreButton",
                  attrs: {
                    "style-type": "raised",
                    "route-name": _vm.buttonRoute,
                  },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }