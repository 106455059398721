import {get as config} from './config.js';
import axios from "axios";

class LoginHandler {

    static login(username, password, rememberMe) {
        return new Promise(function (resolve, reject) {
            axios({
                method: 'POST',
                url: config().LoginEndpoint,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    username: username,
                    password: password,
                    rememberMe: rememberMe
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

export {LoginHandler as default};
