const breakpoints = {
    mobile: 560,
    tablet: 1024,
    desktop: Number.MAX_SAFE_INTEGER
};

function hasOwnProperty (obj, prop) {
    const proto = obj.__proto__ || obj.constructor.prototype;
    return (prop in obj) && (!(prop in proto) || proto[prop] !== obj[prop]);
}

const install = function (Vue) {
    if (window === null || window === undefined) throw ('Window is undefined, make sure this is initialised within a browser');

    const reactiveComponent = new Vue({
        data : {
            mediaObj: {
                currentMediaQuery: ''
            }
        }
    });

    function updateCurrentMediaQuery (windowWidth) {
        if (windowWidth <= breakpoints.mobile) {
            reactiveComponent.mediaObj.currentMediaQuery = 'mobile';
        } else if (windowWidth > breakpoints.mobile && windowWidth <= breakpoints.tablet) {
            reactiveComponent.mediaObj.currentMediaQuery = 'tablet';
        } else {
            reactiveComponent.mediaObj.currentMediaQuery = 'desktop';
        }
    }

    //Do an initial update
    updateCurrentMediaQuery(window.innerWidth);

    if (!hasOwnProperty(Vue.prototype, '$mq')) {
        Object.defineProperty(Vue.prototype, '$mq', {
            get () {
                return reactiveComponent.mediaObj.currentMediaQuery;
            }
        });
    }

    window.onload = function () {
        updateCurrentMediaQuery(window.innerWidth);
    };

    window.onresize = function () {
        updateCurrentMediaQuery(window.innerWidth);
    };
};

module.exports.install = install;

