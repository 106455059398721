<template>
    <div :class="useSmallContainer()">
        <div v-show="iconSource">
           <img :src="iconSource" :class="useSmallIcons()">
        </div>
         <slot></slot>
     </div>
</template>

<script>
    export default {
        name: 'iconInfoBlock',
        props: {
            iconSource : {
                type: String,
                required: false
            },
            smallIcon : {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            useSmallIcons: function() {
                if (this.smallIcon) {
                    return 'smallInfoIcon';
                }

                return 'infoIcon';
            },
            useSmallContainer: function() {
                if (this.smallIcon) {
                    return 'smallContainer';
                }

                return 'container';
            }
        }
    };
</script>

<style lang='less' scoped>

    @import '../assets/css/common.less';
    @import '../assets/css/fontIcons.less';

    .smallContainer {
        height: 128px;
        width: 170px;
        border-radius:5px;
        text-align:center;
        display: inline-block;
        margin: 0 46.5px;
        padding: 0 32.5px;
        vertical-align:top;
        font-size: 14px;
    }

    .container {
        height: 220px;
        width: 250px;
        border-radius:5px;
        text-align:center;
        display: inline-block;
        margin: 0 16px;
        padding: 0 26px;
        vertical-align:top;
        font-size: 14px;
        line-height: 23px;
        font-size: 16px;
    }

    .smallInfoIcon {
        height:36px;
        width: 36px;
        margin: 14px 0 10px 0;
    }

    .infoIcon { 
        height: 120px;
        width: 120px;
        margin: 14px 0 10px 0;
    }

     @media only screen and (max-width : 560px){
    .smallContainer {
        margin: 10px auto;
        padding: 10px 32.5px;
    }

    .container {
        margin: 10px 16px;
        width: 220px;
     }

    .smallInfoIcon {
        height:36px;
        width: 36px;
        margin: 14px 0 10px 0;
    }

    .infoIcon { 
        height: 120px;
        width: 120px;
        margin: 14px 0 10px 0;
    }
     }

     @media only screen and (min-width: 561px) and (max-width : 1024px) {
        .container {
        margin: 10px 10px;
        width: 180px;
     }

      .smallContainer { 
           margin: 10px;
           padding: 0 28px;
      }
    }
</style>