var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        staticClass: "modal-mask",
        on: {
          click: function ($event) {
            return _vm.$emit("close", "clickAway")
          },
        },
      },
      [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "modal-container",
              style: _vm.setModalSize(),
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showHeader,
                      expression: "showHeader",
                    },
                  ],
                  staticClass: "modal-header",
                },
                [
                  _c(
                    "ebaButton",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showTopClose === true,
                          expression: "showTopClose === true",
                        },
                      ],
                      staticClass: "modal-default-button",
                      staticStyle: { "margin-top": "12px" },
                      attrs: { "style-type": "flat" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Close\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._t("header"),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm._t("body", function () {
                    return [
                      _vm._v(
                        "\n                            default body\n                        "
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer", function () {
                    return [_c("div")]
                  }),
                ],
                2
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }