<template>
    <div class="cardContainer">
        <ebaCommonErrorModal ref="commonErrorModal"/>
        <ebaLoadingModal ref="loadingModal"/>
        <div class="card">
            <ebaRegistrationStep step-title="Personal Identification Number (PIN) Required." :showStepNumber="false">
                <ebaWarning class="infoBox" v-show="hasFailed === true" :showArrow="true">
                    <strong>The personal identification number (PIN) is incorrect, {{attemptsRemaining}} {{attemptsRemaining < 2
                        ? 'attempt' : 'attempts'}} left.</strong>
                </ebaWarning>
                    <div class="bodyContainer">
                        <div class="cardBodyText">
                            To ensure the security of your business data we have sent a PIN verification letter to the
                            address that you have entered during the registration process.
                            <br/>
                            <br/>
                            If you have received your PIN, please enter it and you will be given access to your Experian My
                            Business Profile credit report showing your full business credit
                            report including your business score.
                        </div>
                            <ebaTextInput ref="pinEntryField" inputID="pinEntryField"
                                          input-type="text"
                                          invalidText="Please provide your personal identification number (PIN)"
                                          helpText="Please note it could take up to 7 days for a PIN letter to arrive"
                                          :validation="pinNumberRegex"
                                          place-holder="Enter your PIN here"
                                          v-model="personalIdentificationNumber"/>
                        <ebaInfo style="margin-top: 42px">
                            <strong>Your subscription will NOT start &amp; payment will not be taken until you have
                                completed the registration process.</strong>
                        </ebaInfo>
                    </div>
                    <div class="cardNavigationButton">
                        <ebaButton style-type="raised" :is-disabled="isLoading" :isLoading="isLoading" v-on:click.native="submitPin">
                            Submit
                        </ebaButton>
                    </div>
                <br style="clear: both;"/>
            </ebaRegistrationStep>
        </div>
    </div>
</template>

<script>

    import validationHandler from '../../scripts/common/validationHandler';
    import registrationHandler from '../../scripts/registrationHandler';
    import eventTrackingHandler from '../../scripts/common/eventTrackingHandler';

    export default {
        name: 'pinEntry',
        mounted() {
            var self = this;
            const userID = self.$store.state.authentication.userID;

            // Invalid data, redirect to home
            if (!userID) {
                self.$router.push({
                    name: 'Home'
                });
            } else {

                self.$refs.loadingModal.show();
                // Check to see if they are able to enter there pin yet
                registrationHandler.pinEntryValid(userID)
                    .then(response => {
                        if (response === false) {
                            // Pin can now be entered, push to Pin Entry
                            self.$router.push({
                                name: 'PinSent'
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        self.$refs.commonErrorModal.show();
                    })
                    .finally(() => {
                        self.$refs.loadingModal.close();
                    });

                self.$refs.pinEntryField.$el.getElementsByTagName('input')[0].focus();
                self.pinNumberRegex = validationHandler.getTextAndNumbersSpacesPattern();

                self.$refs.pinEntryField.$el.onkeyup = function (evt) {
                    if (evt.key === 'Enter') {
                        self.submitPin();
                    }
                };
            }
        },
        data() {
            return {
                personalIdentificationNumber: '',
                pinNumberRegex: '',
                isLoading: false,

                hasFailed: false,
                attemptsRemaining: 3
            };
        },
        methods: {
            submitPin() {
                const self = this;

                return new Promise(function (resolve, reject) {
                    var isValid = true;

                    self.$refs.pinEntryField.clearValidation();

                    if (!self.$refs.pinEntryField.validate()) {
                        isValid = false;
                    }

                    self.isLoading = true;
                    self.hasFailed = false;

                    const userID = self.$store.state.authentication.userID;
                    registrationHandler.submitPin(userID, self.$refs.pinEntryField.value)
                        .then((response) => {
                            if (response.isPinValid === true) {
                                eventTrackingHandler.trackEvent({
                                    Event: eventTrackingHandler.trackingEvents.pinEntrySubmit,
                                    Details: {
                                        "PinMatched": true
                                    }
                                });
                                registrationHandler.registrationComplete(userID)
                                    .then(resp => {
                                        self.isLoading = false;
                                        if (resp === true) {
                                           self.$router.push({
                                               name: 'RegistrationComplete'
                                           });
                                       } else {
                                           console.log('Invalid response from Registration Complete');
                                           self.isLoading = false;
                                           self.$refs.commonErrorModal.show();
                                       }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                        self.isLoading = false;
                                        self.$refs.commonErrorModal.show();
                                    });
                            } else {
                                eventTrackingHandler.trackEvent({
                                    Event: eventTrackingHandler.trackingEvents.pinEntrySubmit,
                                    Details: {
                                        "PinMatched": false
                                    }
                                });
                                if (response.isLocked) {
                                    eventTrackingHandler.trackEvent({
                                    Event: eventTrackingHandler.trackingEvents.pinEntrySubmit,
                                    Details: {
                                        "PinLocked": true
                                    }
                                });
                                    self.$router.push({
                                        name: 'PinSent'
                                    });
                                } else {
                                    self.hasFailed = true;
                                    self.attemptsRemaining = response.attemptsRemaining;
                                    self.isLoading = false;
                                }
                            }

                            resolve(isValid);
                        })
                        .catch((error) => {
                            console.log(error);
                            self.isLoading = false;

                            self.$refs.commonErrorModal.show();

                            reject(error);
                        });
                });
            }
        }
    };

</script>

<style scoped lang="less">

    @import '../../components/common/assets/css/common.less';

    .infoBox {
        margin-bottom: 42px;
    }

</style>
