<template>
    <div>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Tell us about you.">
            <div>
                <ebaWarning v-show="showInvalidAttempt">
                    <div class="errorText">
                        <p class="heading"><strong>Having difficulty?</strong></p>
                        <p>You may need to check your home address details registered at the UK Companies House to ensure it’s the same as what you are entering.</p>
                        <p>You can check this by looking at your details held at Companies House.</p>
                        <p>Follow this <a :href="WebFilingLink" target="_blank"><strong>Link</strong></a>. to the Companies House Web Filing Service and log in. If you are unsure of your log in details your accountant may be able to help.</p>
                        <p>Once logged in find your name under 'Directors & Secretaries' and click edit. This will show you 3 addresses, the registered office, the correspondence address, and your
                        <strong>home address</strong>. It’s the 
                        <strong>home address</strong>
                        that needs to match the details you are keying into the My Business Profile registration form.</p>
                        <p><strong>Still having difficulty?</strong> Give us a call on 0345 045 8081 or email at <a :href="EBEInfoEmail">ebe.info@experian.com</a></p>
                    </div>
                </ebaWarning>
                <ebaDropdown ref="title" inputID="titleInput"
                             place-holder="Title"
                             invalidText="You must select a title"
                             v-model="registrationData.Title"
                             :data-set="titles"/>
                <ebaTextInput ref="fullNameInput" inputID="fullNameinput"
                              input-type="text"
                              place-holder="Full Name"
                              :validation="fullNameValidation"
                              v-model="registrationData.FullName"
                              :disabled="true"/>
            </div>
            <br style="clear:both">
        </ebaRegistrationStep>
        <ebaRegistrationStep ref="RegistrationStep" step-title="What's your date of birth?" :showStepNumber="false">
            <div>
                <ebaDateEntry ref="dateOfBirthEntry" id="dateOfBirth"
                              v-model="registrationData.DateOfBirth"
                              invalidText="You must provide your date of birth"/>
            </div>
            <br style="clear:both">
        </ebaRegistrationStep>
        <ebaRegistrationStep ref="RegistrationStep" step-title="What's your home address?"
                             :showStepNumber="false">
            <div>
                <ebaTextInput ref="houseNumberName"
                              autocomplete='address-line1'
                              v-model="registrationData.SearchHouseNumberName"
                              inputID="houseNumberNameInput"
                              invalidText="You must specify a Building Number/Name"
                              place-holder="Building Number/Name"
                              :validation="anyCharacterValidationRegex"/>
                <ebaTextInput ref="postCode"
                              autocomplete='postal-code'
                              v-model="registrationData.SearchPostcode"
                              inputID="postcodeInput"
                              invalidText="You must specify a valid postcode"
                              place-holder="Postcode"
                              :validation="postcodeValidationRegex"/>
            </div>
            <br style="clear:both">
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex';
    import validationHandler from '../../scripts/common/validationHandler';
    import {get as config} from '../../scripts/common/config.js';

    export default {
        name: 'personalDetails',
        props: {
            registrationData: {
                type: Object
            },
            attemptsRemaining: {
                type: Number,
                default: 2
            },
            showInvalidAttempt: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                titles: [
                    {name: 'MR', value: 'MR'},
                    {name: 'MRS', value: 'MRS'},
                    {name: 'MS', value: 'MS'},
                    {name: 'MISS', value: 'MISS'},
                    {name: 'DR', value: 'DR'}
                ],

                fullNameValidation: '',
                anyCharacterValidationRegex: '',
                postcodeValidationRegex: '',
                WebFilingLink : config().WebFilingLink,
                EBEInfoEmail : 'mailto:' + config().GeneralEbeEnquiryEmail
            };
        },
        computed: {
            // Map our getters from Vuex
            ...mapGetters([
                'getDirectorName'
            ])
        },
        mounted() {

            this.fullNameValidation = validationHandler.getValidNamePattern();
            this.anyCharacterValidationRegex = validationHandler.getValidAnyCharacterPattern();
            this.postcodeValidationRegex = validationHandler.getValidPostcodePattern();

            this.registrationData.FullName = this.getDirectorName;

            this.$emit('requestCustomProceedText', {text: 'Search'});
            this.$emit('setCustomHeaderText', {
                text: 'To provide you access to your Business Credit Report,<br/> we need to verify your details.'
            });
        },
        methods: {
            isStepValid: function () {
                var self = this;

                return new Promise(function (resolve, reject) {
                    var isValid = true;

                    if (!self.$refs.title.validate()) {
                        isValid = false;
                    }

                    if (!self.$refs.dateOfBirthEntry.validate()) {
                        isValid = false;
                    }
                    if (!self.$refs.houseNumberName.validate()) {
                        isValid = false;
                    }
                    if (!self.$refs.postCode.validate()) {
                        isValid = false;
                    }

                    resolve(isValid);
                });
            },
            handleSubmitAction() {
                var self = this;
                return new Promise(function (resolve, reject) {
                    // Clear any existing data
                    self.registrationData.AddressSearchResults = [];

                    var queryString = self.$refs.houseNumberName.value + ',' + self.$refs.postCode.value;

                    // Get address information
                    self.$services.$http({
                        method: 'GET',
                        url: encodeURI(self.$config.QASApi + '/query/' + queryString),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => {
                            if (response.status === 200) {
                                var data = response.data;
                                var QASResults = [];
                                for (var i = 0; i < data.Count; i++) {
                                    var suggestion = data.SuggestionList.$values[i];
                                    QASResults.push({
                                        name: suggestion.Suggestion,
                                        value: suggestion.QasId
                                    });
                                }

                                self.registrationData.CurrentAddressResults = QASResults;

                                resolve();
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                console.log(error.response.data);
                            } else {
                                console.log(error);
                            }

                            resolve();
                        });
                });
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../../components/common/assets/css/common.less';

    .subtext {
        margin: 0 4px 0 0;
        text-align: left;
        color: @experian-dark-medium-grey;
        font-size: 12px;
    }

    .errorText {
        text-align: center;
        font-size: small;
        line-height: 1.5;
    }
    .heading{
        font-size: medium;
    }

</style>
