var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cardContainer" },
    [
      _c("ebaCommonErrorModal", { ref: "commonErrorModal" }),
      _vm._v(" "),
      _c("ebaLoadingModal", { ref: "loadingModal" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "ebaRegistrationStep",
            {
              attrs: {
                "step-title": "Personal Identification Number (PIN) Required.",
                showStepNumber: false,
              },
            },
            [
              _c(
                "ebaWarning",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasFailed === true,
                      expression: "hasFailed === true",
                    },
                  ],
                  staticClass: "infoBox",
                  attrs: { showArrow: true },
                },
                [
                  _c("strong", [
                    _vm._v(
                      "The personal identification number (PIN) is incorrect, " +
                        _vm._s(_vm.attemptsRemaining) +
                        " " +
                        _vm._s(
                          _vm.attemptsRemaining < 2 ? "attempt" : "attempts"
                        ) +
                        " left."
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bodyContainer" },
                [
                  _c("div", { staticClass: "cardBodyText" }, [
                    _vm._v(
                      "\n                        To ensure the security of your business data we have sent a PIN verification letter to the\n                        address that you have entered during the registration process.\n                        "
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n                        If you have received your PIN, please enter it and you will be given access to your Experian My\n                        Business Profile credit report showing your full business credit\n                        report including your business score.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ebaTextInput", {
                    ref: "pinEntryField",
                    attrs: {
                      inputID: "pinEntryField",
                      "input-type": "text",
                      invalidText:
                        "Please provide your personal identification number (PIN)",
                      helpText:
                        "Please note it could take up to 7 days for a PIN letter to arrive",
                      validation: _vm.pinNumberRegex,
                      "place-holder": "Enter your PIN here",
                    },
                    model: {
                      value: _vm.personalIdentificationNumber,
                      callback: function ($$v) {
                        _vm.personalIdentificationNumber = $$v
                      },
                      expression: "personalIdentificationNumber",
                    },
                  }),
                  _vm._v(" "),
                  _c("ebaInfo", { staticStyle: { "margin-top": "42px" } }, [
                    _c("strong", [
                      _vm._v(
                        "Your subscription will NOT start & payment will not be taken until you have\n                            completed the registration process."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cardNavigationButton" },
                [
                  _c(
                    "ebaButton",
                    {
                      attrs: {
                        "style-type": "raised",
                        "is-disabled": _vm.isLoading,
                        isLoading: _vm.isLoading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.submitPin.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Submit\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("br", { staticStyle: { clear: "both" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }