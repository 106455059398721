<template>
    <div class="searchContainer">
        <ebaCommonErrorModal ref="commonErrorModal"/>
        <ebaCommonErrorModal ref="directorshipTakenModal" :showCloseFooter="true" :showEmailFooter="false">
            <div class="directorTaken">
                <h3 class="gradientText" id="directorTakenHeader">We encountered an issue processing your details.</h3>
                <p class="informationText">
                    Please check that you have selected the correct business and directorship.<br><br>
                    If you have already registered for your Experian My Business Profile Credit Report please
                    <router-link class="registrationLink" :to="{name: 'Login'}">Login</router-link>
                    .
                    <br><br>
                    If your selections are correct please contact our Customer Service Team on 0345 045 8081 (Calls charged at standard rate plus your phone company's access charge - please see FAQ's)
                </p>
            </div>
        </ebaCommonErrorModal>
        <section>
            <ebaSearchBar
                    ref="searchBar"
                    class="searchBar"
                    placeholderText="Enter your business name or registration number"
                    @searchClick="BusinessSearch"/>
            <div class="resultsCount gradientText headerText" v-show="ShowResultSummary">
                {{getSearchResultsLengthPhrase}}
            </div>
        </section>
        <section>
            <div class="resultsContainer">
                <ul class="resultsList">
                    <li :class="['resultListItem', 'resultBorder', showBottomBorder(result)]" v-for="result in results"
                        :key="result.businessRef">
                        <ebaBusinessResult :BusinessResultObj="result"
                                           :IsDisabled="isDisabled"
                                           v-on:showCommonError="showCommonError"
                                           v-on:showDirectorshipTaken="showDirectorshipModal"/>
                    </li>
                </ul>
            </div>
        </section>
        <section class="businessNotShown">
            <ebaButton routeName="FAQs" styleType="raised">Business not shown?</ebaButton>
        </section>
    </div>
</template>

<script>

    import businessSearchHandler from '../../scripts/businessSearchHandler';
    import {get as config} from '../../scripts/common/config';

    export default {
        name: "businessResults",
        props: {
            SearchPhrase: {
                type: String,
                required: true
            },
            SearchResults: {
                type: Array,
                required: true
            }
        },
        mounted() {
            if (this.SearchPhrase === undefined) {
                this.$router.push({
                    name: 'Home'
                });
            }

            // Assign these props onto data, as these will change if they re-search
            this.phrase = this.SearchPhrase;
            this.results = this.SearchResults;

            this.ShowResultSummary = true;
        },
        data() {
            return {
                phrase: '',
                results: [],
                ShowResultSummary: false,

                supportEmail: config().GeneralEnquiryEmail,
                supportSubject: config().GeneralEnquirySubject,

                isDisabled: false,
            };
        },
        computed: {
            getSearchResultsLengthPhrase() {
                if (this.results !== undefined) {
                    var length = this.results.length || 0;
                    if (length === 0) {
                        return "There are " + length + " results for " + this.toUpperCase(this.phrase);
                    } else if (length === 1) {
                        return "There is " + length + " result for " + this.toUpperCase(this.phrase) + ", please select your business";
                    } else {
                        return "There are " + length + " results for " + this.toUpperCase(this.phrase) + ", please select your business";
                    }
                }
            }
        },
        methods: {
            BusinessSearch(searchText) {
                this.$refs.searchBar.loading = true;
                businessSearchHandler.searchBusiness(searchText)
                    .then((response) => {
                        this.$refs.searchBar.loading = false;

                        this.phrase = searchText;
                        this.results = response.data;
                    })
                    .catch(() => {
                        this.$refs.searchBar.loading = false;
                        this.showCommonError();
                    });
            },
            toUpperCase(value) {
                if (value !== null && value !== undefined && typeof value === "string") {
                    return value.toUpperCase();
                }

                return value;
            },
            showBottomBorder(result) {
                if (this.results.length !== 0 && this.results !== undefined) {
                    if (result.businessRef === this.results.slice(-1)[0].businessRef) {
                        return 'resultBorderLast';
                    }
                }
            },
            buildContactHref() {
                return 'mailto:' + this.supportEmail + '?Subject=' + this.supportSubject;
            },
            showCommonError() {
                this.$refs.commonErrorModal.show();
            },
            showDirectorshipModal() {
                this.$refs.directorshipTakenModal.show();
            }
        }
    };
</script>

<style lang="less" scoped>

    @import "../../components/common/assets/css/common.less";

    .searchContainer {
        text-align: center;
    }

    .searchBar {
        margin-top: 32px;
    }

    .resultsCount {
        line-height: 24px;
    }

    .headerText {
        margin: 28px 20px 28px 20px;
    }

    .resultsContainer {
        width: 100%;
    }

    .resultBorder {
        border-top: 1px solid @experian-light-grey;
    }

    .resultBorderLast {
        border-bottom: 1px solid @experian-light-grey;
    }

    .resultsList {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .businessNotShown {
        margin: 24px;
    }

    .directorTaken {
        width: 100%;
        text-align: left;
    }

    #directorTakenHeader {
        margin-bottom: 0;
    }

    .directorshipTakenText {
        margin: 0;
    }

</style>
