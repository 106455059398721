var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "extraInformation" },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showArrow === true,
            expression: "showArrow === true",
          },
        ],
        staticClass: "arrow-up",
      }),
      _vm._v(" "),
      _vm._t("default", function () {
        return [_vm._v("Error")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }