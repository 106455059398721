<template>
    <div id="app">
        <ebaTopButton v-show="showTopButton"/>
        <div class="container">
            <div class="header">
                <ebaHeader/>
            </div>
            <router-view/>
            <div class="footer">
                <ebaFooter>
                    <template slot="promotionalOffer">
                        {{promotionalText}}
                        <a id="TAndCLink" :href="offerTermsAndConditions" target="_blank">
                            Terms and Conditions
                        </a>
                        apply.
                    </template>
                </ebaFooter>
            </div>
        </div>
    </div>
</template>

<script>
    import {get as config} from './scripts/common/config.js';
    import EventTrackingHandler from './scripts/common/eventTrackingHandler.js';

    export default {
        name: 'App',
        data() {
            return {
                promotionalText: `*New customers only. Free trial begins upon successful registration. A monthly fee of ${config(true).offerPrice} applies after your free trial. 
                You may cancel during your free trial without charge.`,
                
                offerTermsAndConditions: config().TermsAndConditions
            };
        },
        computed: {
            showTopButton() {
                if (this.$route.meta.noMoveTop === true) {
                    return false;
                }

                return true;
            }
        },
        mounted() {
            setInterval(()=> {
                this.$store.dispatch("authenticate");
            },5000)
        }
    };

</script>

<style lang="less">

    @import "components/common/assets/css/common";

</style>