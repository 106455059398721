<template>
    <div class="container">
        <div class="header">
            <div class="image">
                <img src="../../assets/Images/companionWarning.svg"/>
            </div>
            <span class="gradientText">
                <strong>{{commonProperties.customerName}}</strong>, we are sorry you want to end your membership.<br/>
                 If you cancel you will lose these premium benefits.
            </span>
        </div>
        <div class="spacer-line"></div>
        <div class="text">
            <div>
                <span class="gradientText">
                    <strong>See what lenders see with daily updates</strong>
                </span>
                <span>
                    The most accurate and fully comprehensive view of your own business credit profile.
                </span>
            </div>
            <div>
                <span class="gradientText">
                    <strong>Monitoring with automatic alerts</strong>
                </span>
                <span>
                    Receive alerts about any changes to your business credit report, so you can act quickly to reduce any negative impact to your score.
                </span>
            </div>
            <div>
                <span class="gradientText">
                    <strong>Premium customer support (phone, email and chat)</strong>
                </span>
                <span>
                    On-going support to help make your business credit score the best it can be.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import cancellationHandler from "../../scripts/cancellationHandler";
    export default {
        name: 'StepOne',
        props: 
        {
            commonProperties: {
                default: null,
                type: Object
            }   
        }
        ,
        data() {
            return {
                customerName: ""
            }
        },
        mounted() {
            const context = this;
            const emitButtons = (offerUsed)=> { this.$emit("generateNavButtons", [
                    {
                        type: "secondary",
                        text: "No thanks",
                        action() {
                            if(offerUsed){
                                context.$emit("action", ["progressToStep", "ebaSubCancellationStepThree"])
                            } else {
                                context.$emit("action", ["progressToStep", "ebaSubCancellationStepTwo"]);
                            }
                        }
                    },
                    {
                        type: "primary",
                        text: "Keep benefits",
                        action() {
                            context.$emit("action", ["redirectToReport"]);
                        }
                    }
                ])
            };
            
            cancellationHandler.offerUsed(this.$store.state.authentication.userID)
                .then((used) => {
                    this.commonProperties.isOfferUsed = used;
                    emitButtons(used);
                }).catch(()=> {
                    this.commonProperties.isOfferUsed = false;
                    emitButtons(false);
                });
        },
    }

</script>
