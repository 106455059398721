var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdownContainer" }, [
    _c("div", { staticClass: "select" }, [
      _c(
        "select",
        {
          ref: "dropdownInput",
          class: ["select-text", _vm.getValidClass()],
          attrs: { id: _vm.inputID, disabled: _vm.disabled, required: "" },
          domProps: { value: _vm.value },
          on: { change: _vm.updateValue },
        },
        [
          _c("option", { attrs: { value: "", disabled: "", selected: "" } }),
          _vm._v(" "),
          _vm._l(_vm.dataSet, function (value) {
            return _c(
              "option",
              {
                staticClass: "dropdownOption",
                domProps: { value: value.value },
              },
              [
                _vm._v(
                  "\n                " + _vm._s(value.name) + "\n            "
                ),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("span", { staticClass: "select-highlight" }),
      _vm._v(" "),
      _c("span", { staticClass: "select-bar" }),
      _vm._v(" "),
      _c("label", { class: ["select-label", _vm.getValidClass()] }, [
        _vm._v(_vm._s(_vm.placeHolder)),
      ]),
    ]),
    _vm._v(" "),
    _c("p", { class: ["helpText", _vm.getValidClass()] }, [
      _vm._v(_vm._s(_vm.getLabelText())),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }