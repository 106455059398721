var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ebaContactErrorModal", {
        ref: "contactErrorModal",
        attrs: {
          errorCode: "EXP-MBP-002",
          showEmailFooter: true,
          showCloseFooter: false,
        },
      }),
      _vm._v(" "),
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: { "step-title": "Please tell us your contact details." },
        },
        [
          _c(
            "div",
            [
              _c("ebaTextInput", {
                ref: "emailAddressInput",
                attrs: {
                  inputID: "emailAddressInput",
                  "input-type": "email",
                  invalidText: _vm.invalidEmailAddress,
                  "place-holder": "Email Address",
                },
                model: {
                  value: _vm.registrationData.EmailAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "EmailAddress", $$v)
                  },
                  expression: "registrationData.EmailAddress",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "telephoneInput",
                attrs: {
                  inputID: "telephoneNumberInput",
                  "input-type": "tel",
                  invalidText: "You must specify a valid Telephone Number",
                  "place-holder": "Telephone",
                  validation: _vm.telephoneValidationRegex,
                },
                model: {
                  value: _vm.registrationData.Telephone,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "Telephone", $$v)
                  },
                  expression: "registrationData.Telephone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("br", { staticStyle: { clear: "both" } }),
          _vm._v(" "),
          _c("p", { staticClass: "subtext" }, [
            _vm._v(
              "We will only use the data you provide to us when creating your account for up to 30 days\n            and it will be subsequently deleted"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }