<template>
    <span v-if="hasRouteName()" :class="{btnDisabled: isDisabled}" v-on:click.prevent>
        <router-link :class="[isDisabled ? 'btnDisabled' : '', getTypeClass()]" :to="{name: routeName}" tag="button">
            <slot v-if="!is_Loading">
            </slot>
            <div v-else>
                <ebaSpinner/>
            </div>
        </router-link>
    </span>
    <span v-else :class="{btnDisabled: isDisabled}" v-on:click.prevent>
        <button :class="getTypeClass()" type="button" :disabled="isDisabled" :name="buttonName">
            <slot v-if="!is_Loading">
            </slot>
            <div v-else>
                <ebaSpinner />
            </div>
        </button>
    </span>
</template>

<script>
    export default {
        name: 'Button',
        props: {
            styleType: {
                type: String,
                required: false,
                default: 'flat'
            },
            buttonName: {
                type: String,
                required: false
            },
            isDisabled: {
                type: Boolean,
                required: false
            },
            isLoading: {
                type: Boolean,
                required: false
            },
            routeName: {
                type: String,
                required: false,
                default: null
            }
        },
        data() {
            return {
                is_Loading: this.isLoading
            };
        },
        watch: {
            isLoading: function() {
                this.is_Loading = this.isLoading;
            }
        },
        methods: {
            getTypeClass() {
                switch (this.$props.styleType.toLowerCase()) {
                    case 'flat':
                        return 'btnFlat';
                    case 'raised':
                        return 'btnRaised';
                }
            },
            hasRouteName() {
                return this.routeName !== undefined && this.routeName !== null && this.routeName !== '';
            }
        }
    };
</script>

<style scoped>

</style>
