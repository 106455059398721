var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasRouteName()
    ? _c(
        "span",
        {
          class: { btnDisabled: _vm.isDisabled },
          on: {
            click: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "router-link",
            {
              class: [_vm.isDisabled ? "btnDisabled" : "", _vm.getTypeClass()],
              attrs: { to: { name: _vm.routeName }, tag: "button" },
            },
            [
              !_vm.is_Loading
                ? _vm._t("default")
                : _c("div", [_c("ebaSpinner")], 1),
            ],
            2
          ),
        ],
        1
      )
    : _c(
        "span",
        {
          class: { btnDisabled: _vm.isDisabled },
          on: {
            click: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "button",
            {
              class: _vm.getTypeClass(),
              attrs: {
                type: "button",
                disabled: _vm.isDisabled,
                name: _vm.buttonName,
              },
            },
            [
              !_vm.is_Loading
                ? _vm._t("default")
                : _c("div", [_c("ebaSpinner")], 1),
            ],
            2
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }