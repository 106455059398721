<template>
    <ebaModal
            v-if="showModal"
            @close="showModal = false; clickAway()"
            :showTopClose="false"
            :width="720">
        <div class="body" slot="body">
            <div class="alertIcon">
                <img :src="require('../assets/images/alert.svg')" alt="Warning!"/>
            </div>
            <slot>
                <h3 class="gradientText errorMessage">
                    Sorry, there was a problem submitting your request.
                    <br/>
                    Please try again later or contact us and quote code <strong>{{ errorCode }}</strong>
                </h3>
                <h3 class="errorMessage" id="contactMessage">
                    <a :href="buildContactHref()" target="_top"><strong>{{supportEmail}}</strong></a>
                    <br>
                    <router-link id="anchorTelNo" :to="{name: 'SalesAndHelp'}"><strong>{{supportPhoneNumber}}</strong></router-link>
                </h3>
            </slot>
        </div>
        <div class="footer" slot="footer">
            <div v-show="showEmailFooter === true">
                <ebaButton class="continueCTA" styleType="raised" @click.native="createEmail">Email us</ebaButton>
            </div>
            <div v-show="showCloseFooter === true">
                <ebaButton class="continueCTA" styleType="raised" @click.native="close">Close</ebaButton>
            </div>
            <span id="errorCode">
                {{errorCode}}
            </span>
        </div>
    </ebaModal>
</template>

<script>

    import {get as config} from '../../../scripts/common/config';
    import eventTrackingHandler from '../../../scripts/common/eventTrackingHandler';

    export default {
        name: 'contactErrorModal',
        props: {
            showEmailFooter: {
                type: Boolean,
                default: true
            },
            showCloseFooter: {
                type: Boolean,
                default: false
            },
            errorCode: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                showModal: false,
                supportEmail: config().GeneralEnquiryEmail,
                supportSubject: config().GeneralEnquirySubject,
                supportPhoneNumber: config().footerDetails.supportPhoneNumber,
                clickAwayCallback: null
            }
        },
        methods: {
            continueClick() {
                this.showModal = false;
            },
            show() {
                eventTrackingHandler.trackEvent({
                    Event: eventTrackingHandler.trackingEvents.genericError,
                    Details: {
                        "Application": 'MBP',
                        "ErrorCode" : this.$props.errorCode
                    }
                });
                this.showModal = true;
            },
            close() {
                this.showModal = false;
            },
            buildContactHref() {
                return 'mailto:' + this.supportEmail + '?Subject=' + this.supportSubject + ' (' + this.$props.errorCode + ')';
            },
            createEmail() {
                eventTrackingHandler.trackEvent({
                    Event: eventTrackingHandler.trackingEvents.genericErrorEmailCustomerSupport
                });
                window.location = this.buildContactHref();
            },
            clickAway() {
                this.showModal = false;
                if (typeof this.clickAwayCallback === "function") {
                    this.clickAwayCallback();
                }
            }
        }
    };

</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    .continueCTA {
        padding: 0 32px 24px 32px;
        margin: 6px;
        float: right;
    }

    .body {
        padding: 20px 20px 0 20px;
    }

    .alertIcon {
        text-align: center;
    }

    .alertIcon img {
        padding: 10px;
        width: 100px;
    }

    .errorMessage {
        width: 100%;
        text-align: center;

        margin: 6px 10px 0 10px;
        font-weight: normal;
        line-height: 34px;
    }

    #errorCode {
        position: relative;
        top: 48px;
        left: 18px;
        color: @experian-medium-grey;
        font-size: 10px;
    }

    #contactMessage {
        padding-bottom: 20px;
        color: @experian-sapphire
    }

</style>
