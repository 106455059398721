var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    _vm._l(_vm.criteriaList, function (criteria) {
      return _c("li", { key: criteria.id, staticClass: "listStyle" }, [
        criteria.isLoading
          ? _c(
              "span",
              [
                _c("ebaSpinner", {
                  staticClass: "listIcon",
                  attrs: { useAlt: true },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    class: [
                      "listDescription",
                      criteria.showInvalid
                        ? "invalid"
                        : criteria.fullfilled
                        ? "valid"
                        : "initial",
                    ],
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(criteria.description) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            )
          : _c("span", [
              criteria.showInvalid
                ? _c("span", [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: criteria.fullfilled === false,
                            expression: "criteria.fullfilled === false",
                          },
                        ],
                        staticClass: "listIcon",
                      },
                      [
                        _c("img", {
                          attrs: { src: require("../assets/images/error.svg") },
                        }),
                      ]
                    ),
                  ])
                : _c("span", [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: criteria.fullfilled === false,
                            expression: "criteria.fullfilled === false",
                          },
                        ],
                        staticClass: "listIcon",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../assets/images/initialCriteria.svg"),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: criteria.fullfilled === true,
                            expression: "criteria.fullfilled === true",
                          },
                        ],
                        staticClass: "listIcon",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../assets/images/success.svg"),
                          },
                        }),
                      ]
                    ),
                  ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: [
                    "listDescription",
                    criteria.showInvalid
                      ? "invalid"
                      : criteria.fullfilled
                      ? "valid"
                      : "initial",
                  ],
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(criteria.description) +
                      "\n            "
                  ),
                ]
              ),
            ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }