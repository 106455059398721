<template>
    <table v-on:blur="test()" :class="[getTypeClass(), setIsPromo()]">
        <tbody>
            <tr v-show="hasPromo">
                <td style="padding: 0;">
                    <div class="promoTop">
                        <p class="promoText">{{promoText}}</p>
                        <div class="arrow-down"></div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="image" v-show="imageSource !== undefined && imageSource !== null && showImage === true">
                        <img :src="imageSource" alt="productImage">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="titleText" style="font-weight: bold;">
                    {{titleText}}
                </td>
            </tr>
            <tr>
                <td class="infoText">
                    <div class="threeColContent" style="min-height: 40px;">
                        <slot>

                        </slot>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <ebaButton class="learnMoreButton" style-type="raised" :route-name="buttonRoute">{{buttonText}}</ebaButton>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>

    export default {
        name: 'productInformationItem',
        props: {
            imageSource: {
                type: String,
                required: false
            },
            showImage: {
                type: Boolean,
                default: false
            },
            titleText: {
                type: String,
                required: false
            },
            buttonText: {
                type: String,
                required: true
            },
            buttonRoute: {
                type: String,
                required: true
            },
            hasPromo: {
                type: Boolean,
                required: false,
                default: false
            },
            promoText: {
                type: String,
                required: false
            },
            middleItem: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            getTypeClass: function () {
                var returnVal = '';

                if (this.middleItem === true) {
                    returnVal = 'tblContent tblContentCentralBorder';
                } else {
                    returnVal = 'tblContent';
                }

                return returnVal;
            },
            setIsPromo: function () {
                if (this.hasPromo === true) {
                    return 'promo';
                }

                return '';
            }
        }
    };

</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    .promo {
        border: 1px solid @experian-pink;
        -webkit-box-shadow: 2.5px 2px 6px @experian-dark-grey;
        -moz-box-shadow: 2.5px 2px 6px @experian-dark-grey;
        box-shadow: 2.5px 2px 6px @experian-dark-medium-grey;
        margin-bottom: 2px;
        padding: 0;
    }

    .promoTop {
        height: 32px;
        background: @experian-pink;
        margin: 0px;
        padding: 0px;
    }

    .promoText {
        margin: 0;
        color: white;
        line-height: 30px;
        font-weight: bold;
    }

    .arrow-down {
        float: right;
        position: relative;
        right: 32px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;

        border-top: 15px solid @experian-pink;
    }

    .image {
        width: 120px;
        height: 120px;
        padding: 8px;
        margin: 0 auto;
    }

    .image img {
        width: inherit;
        height: inherit;
    }

    .tblContent {
        max-width: 350px;
        line-height: 23px;
        margin: auto;
        padding-bottom: 16px;
    }
    
    .tblContentCentralBorder {
        padding: 0px 32.5px 0px 32.5px;
        margin: 0px 32.5px 0px 32.5px;
        border-right: 1px solid @experian-light-grey;
        border-left: 1px solid @experian-light-grey;
    }

    .threeColContent {
        width: 250px;
        padding: 8px 0;
    }

    .titleText {
        color: @experian-purple;
    }

    .infoText {
        color: @experian-sapphire;
    }

    table tbody td tr {
        height: 100%;
    }

    /* Tablet */
    @media only screen and (max-width: 1124px) {
        .tblContentCentralBorder{
            border: none;
        }
    }
    
    /* Mobile */
    @media only screen and (max-width: 560px) {
        .tblContentCentralBorder{
            border: none;
        }
    }    

</style>
