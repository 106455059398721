<template>
    <div class="dropdownContainer">
        <div class="select">
            <select ref="dropdownInput" :id="inputID"
                    v-bind:value="value"
                    v-on:change="updateValue"
                    :disabled="disabled"
                    :class="['select-text', getValidClass()]" required>
                <option value="" disabled selected></option>
                <option class="dropdownOption" v-for="value in dataSet" v-bind:value="value.value">
                    {{value.name}}
                </option>
            </select>
            <span class="select-highlight"></span>
            <span class="select-bar"></span>
            <label :class="['select-label', getValidClass()]">{{placeHolder}}</label>
        </div>
        <p :class="['helpText', getValidClass()]">{{getLabelText()}}</p>
    </div>
</template>

<script>

    import validationHandler from '../../../scripts/common/validationHandler';

    export default {
        name: 'Dropdown',
        props: {
            inputID: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false
            },
            helpText: {
                type: String,
                required: false
            },
            invalidText: {
                type: String,
                required: false,
                default: 'Invalid input'
            },
            placeHolder: {
                type: String,
                required: true
            },
            dataSet: {
                type: Array,
                required: true
            },
            noValidation: {
                type: Boolean,
                required: false,
                default: false
            },
            value: {}
        },
        data() {
            return {
                activeClass: 'placeholder',
                validationStateEnum: validationHandler.getValidationStates(),
                validationState: null
            };
        },
        methods: {
            updateValue(event) {
                this.$emit('input', event.target.value);

                this.validationState = this.validationStateEnum.INITIAL;
            },
            getLabelText() {
                switch (this.validationState) {
                    case this.validationStateEnum.VALID:
                        return this.helpText;
                    case this.validationStateEnum.INVALID:
                        return this.invalidText;
                    case this.validationStateEnum.INITIAL:
                    default:
                        return this.helpText;
                }
            },
            invalidate() {
                this.validationState = this.validationStateEnum.INVALID;
            },
            validate() {
                if (this.noValidate) {
                    this.validationState = this.validationStateEnum.INITIAL;
                    return true;
                }

                if (this.value === '' || this.value === null || this.value === undefined) {
                    this.validationState = this.validationStateEnum.INVALID;
                } else {
                    this.validationState = this.validationStateEnum.VALID;
                }

                return this.validationState !== this.validationStateEnum.INVALID;
            },
            getValidClass() {
                switch (this.validationState) {
                    case this.validationStateEnum.INVALID:
                        return 'invalid';
                    case this.validationStateEnum.VALID:
                        return 'valid';
                    case this.validationStateEnum.INITIAL:
                    default:
                        return 'initial';
                }
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    .dropdownContainer {
        position:relative;
        margin-top: 32px;
    }

    .dropdownOption {
        color: @experian-sapphire;
    }

    .helpText {
        color: @experian-sapphire;
        margin: 4px;
        font-size: 14px;
    }

    .helpText.invalid {
        color: @experian-danger-error
    }

    .select {
        position: relative;
        width: 100%;
    }

    .select-text {
        position: relative;
        background-color: transparent;
        width: 100%;
        padding: 10px 10px 10px 5px;
        font-size: 18px;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid transparent;
        border-image: linear-gradient(to left, @experian-light-blue 0%, @experian-purple 100%);
        border-image-slice: 1;
    }

    .select-text.invalid {
        border-image: linear-gradient(to left, @experian-danger-error 0%, @experian-danger-error 100%);
        border-image-slice: 1;
    }

    /* Remove focus */
    .select-text:focus {
        outline: none;
        border-bottom: 1px solid rgba(0,0,0,0);
    }

    /* Use custom arrow */
    .select .select-text {
        appearance: none;
        -webkit-appearance:none;
    }

    .select:after {
        position: absolute;
        top: 18px;
        right: 10px;

        /* Styling the down arrow */
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid rgba(0, 0, 0, 0.12);
        pointer-events: none;
    }


    /* LABEL ======================================= */
    .select-label {
        color: @experian-dark-medium-grey;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all;
        transition:0.2s ease all;
        -moz-transition:0.2s ease all;
        -webkit-transition:0.2s ease all;
    }

    /* active state */
    .select-text:focus ~ .select-label, .select-text:valid ~ .select-label {
        top: -12px;
        transition: 0.2s ease all;
        font-size:14px;
        background-image: -webkit-linear-gradient(45deg, @experian-purple, @experian-light-blue);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: @experian-light-blue;
    }

    .select-label.invalid {
        color: @experian-danger-error;
    }

    /* BOTTOM BARS ================================= */
    .select-bar {
        position: relative;
        display: block;
        width: 100%;
    }

    .select-bar:before, .select-bar:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        border: none;
        transition: 0.2s ease all;
    }

    .select-bar:before {
        left: 50%;
    }

    .select-bar:after {
        right: 50%;
    }

    /* active state */
    .select-text:focus ~ .select-bar:before, .select-text:focus ~ .select-bar:after {
        width: 50%;
    }

    /* HIGHLIGHTER ================================== */
    .select-highlight {
        position: absolute;
        height: 60%;
        width: 100px;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
    }

</style>