import { render, staticRenderFns } from "./personalDetails.vue?vue&type=template&id=7d81df10&scoped=true"
import script from "./personalDetails.vue?vue&type=script&lang=js"
export * from "./personalDetails.vue?vue&type=script&lang=js"
import style0 from "./personalDetails.vue?vue&type=style&index=0&id=7d81df10&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../Server/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d81df10",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\data\\jenkins_home\\workspace\\Web_Applications\\Deploy_MBPUX_Site\\Server\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d81df10')) {
      api.createRecord('7d81df10', component.options)
    } else {
      api.reload('7d81df10', component.options)
    }
    module.hot.accept("./personalDetails.vue?vue&type=template&id=7d81df10&scoped=true", function () {
      api.rerender('7d81df10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/registration/personalDetails.vue"
export default component.exports