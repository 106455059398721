<template>
    <div class="card-authentication registration-background">
        <ebaRegistrationStep ref="RegistrationStep" step-title="Card Details.">
            <div>
                <ebaError v-show="PaymentFailedFlag === true">
                    <strong>
                        There was an error trying to process your payment. No charges have been made.
                        <br>
                        Please check your details or try a different card.
                    </strong>
                </ebaError>
                <ebaError v-show="paymentError">
                    <strong>{{paymentError}}</strong>
                </ebaError>
                <div class="stripeContainer">
                    <div class="stripe-field">
                        <ebaTextInput ref="cardNameRef"
                                      inputID="cardNameInput"
                                      place-holder="Name on Card"
                                      v-model="cardName"
                                      :validation="cardNameRegex"/>
                        <p v-show="cardNameError !== undefined" class="card-errors">{{cardNameError}}</p>
                    </div>
                    <div class="stripe-field">
                        <label class="stripe-label">Card Number</label>
                        <div ref="cardNumberElement" id="cardNumberElement"></div>
                    </div>
                    <div class="stripe-field">
                        <label class="stripe-label">Expiry Date</label>
                        <div id="cardExpiryElement" ref="cardExpiryElement"></div>
                    </div>
                    <div class="stripe-field">
                        <label class="stripe-label">Security Code</label>
                        <div id="cardCvcElement" ref="cardCvcElement"></div>
                    </div>
                    <ebaInfo>
                        <p>
                            If you cancel before the end of your trial you won’t be charged
                        </p>
                    </ebaInfo>
                    <div>
                        <img :src="require('../../components/common/assets/images/payment/powered_by_stripe.svg')"
                             class="stripeLogo"
                             alt="Powered by Stripe"/>
                        <div class="cardLogoContainer">
                            <img :src="require('../../components/common/assets/images/payment/visa.svg')"
                                 class="cardLogo"
                                 alt="visa"/>
                            <img :src="require('../../components/common/assets/images/payment/americanexpress.svg')"
                                 class="cardLogo"
                                 alt="maestro"/>
                            <img :src="require('../../components/common/assets/images/payment/mastercard.svg')"
                                 class="cardLogo"
                                 alt="mastercard"/>
                        </div>
                        <br style="clear: both;"/>
                    </div>
                </div>
            </div>
        </ebaRegistrationStep>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Billing Address." :showStepNumber="false">
            <div>
                <ebaTextInput ref="billingName"
                              v-model="registrationData.BillingDetails.NameOrNumber"
                              :invalidText="billingNameErrorMessage"
                              :validation="specialCharactersRegex"
                              inputID="billingName"
                              place-holder="Name or Number"/>
                <ebaTextInput ref="billingStreet"
                              v-model="registrationData.BillingDetails.Street"
                              inputID="billingStreet"
                              :no-validation="true"
                              place-holder="Street"/>
                <ebaTextInput ref="billingTownOrCity"
                              v-model="registrationData.BillingDetails.TownOrCity"
                              :invalidText="billingTownErrorMessage"
                              :validation="specialCharactersRegex"
                              inputID="billingTownOrCity"
                              place-holder="Town or City"/>
                <ebaTextInput ref="billingPostcode"
                              v-model="registrationData.BillingDetails.Postcode"
                              invalidText="You must specify a postcode"
                              inputID="billingPostcode"
                              place-holder="Postcode"
                              :validation="postcodeRegex"/>
            </div>
            <br style="clear:both">
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import {get as config} from '../../scripts/common/config';
    import validationHandler from '../../scripts/common/validationHandler';
    import eventTrackingHandler from '../../scripts/common/eventTrackingHandler';
    import registrationHandler from '../../scripts/registrationHandler';

    export default {
        name: 'billingDetailsStep',
        props: {
            registrationData: {
                type: Object
            },
            PaymentFailed: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.$emit('setCustomHeaderText', {
                text: `Thanks ${this.$store.state.businessSearch.Director.firstName}. We need your payment card to verify your details for your <b>free business credit score access</b>. After that, we charge per month so there's no obligation to continue and <b>you can cancel online anytime</b>.`
            });

            this.$emit('requestCustomProceedText', {
                text: 'Finish'
            });

            this.PaymentFailedFlag = this.PaymentFailed || false;

            this.stripe = Stripe(config().StripePublishableKey);
            this.elements = this.stripe.elements();

            this.cardNumberElement = undefined;
            this.cardExpiryElement = undefined;
            this.cardCvcElement = undefined;

            let stripeStyle = {
                base: {
                    color: '#555',
                    lineHeight: '24px',
                    fontFamily: 'Roboto, Arial, Helvetica, SansSerif',
                    fontSize: '18px'
                }
            };

            //add stripe elements with styling. This styling has to be applied at this point. Setting it in a class
            //is more work once the element is on the page
            this.cardNumberElement = this.elements.create('cardNumber', {style: stripeStyle});
            this.cardNumberElement.mount(this.$refs.cardNumberElement);

            this.cardExpiryElement = this.elements.create('cardExpiry', {style: stripeStyle});
            this.cardExpiryElement.mount(this.$refs.cardExpiryElement);

            this.cardCvcElement = this.elements.create('cardCvc', {style: stripeStyle});
            this.cardCvcElement.mount(this.$refs.cardCvcElement);

            //add additional class for gradient underline on stripe elements
            document.getElementById('cardNumberElement').classList.add('stripe-element');
            document.getElementById('cardExpiryElement').classList.add('stripe-element');
            document.getElementById('cardCvcElement').classList.add('stripe-element');

            this.cardNameRegex = validationHandler.getValidNamePattern();
            this.specialCharactersRegex = validationHandler.getValidAddressPattern();
            this.postcodeRegex = validationHandler.getValidPostcodePattern();
        },
        data() {
            return {
                cardNameRegex: '',
                stripeKey: config().StripePublishableKey,

                stripeOptions: {
                    hidePostalCode: true
                },

                stripe: undefined,
                elements: undefined,
                clientSecret: undefined,
                cardNumberElement: undefined,
                cardExpiryElement: undefined,
                cardCvcElement: undefined,

                complete: false,
                showError: false,
                error: {},

                cardName: '',
                cardNameRegex: validationHandler.getValidNamePattern(),

                PaymentFailedFlag: false,

                cardNameError: undefined,
                cardNumberError: undefined,
                cardExpiryError: undefined,
                cardCvcError: undefined,
                paymentError: undefined,

                billingNameErrorMessage: '',
                billingStreetErrorMessage: '',
                billingTownErrorMessage: '',

                specialCharactersRegex: '',
                postcodeRegex: '',
            };
        },
        methods: {
            createToken(self) {
                return new Promise(function (resolve, reject) {
                    let cardDetails = {
                        name: self.$refs.cardNameRef.value,
                        address: {
                            line1: self.registrationData.BillingDetails.NameOrNumber,
                            line2: self.registrationData.BillingDetails.Street,
                            city: self.registrationData.BillingDetails.TownOrCity,
                            postal_code: self.registrationData.BillingDetails.Postcode
                        }
                    };

                    registrationHandler.setupIntent().then((intent)=> {
                        if(typeof intent.data.clientSecret != 'undefined'){
                            self.clientSecret = intent.data.clientSecret

                            self.stripe.handleCardSetup(
                                self.clientSecret, 
                                self.cardNumberElement, 
                                {
                                    payment_method_data: {
                                        billing_details: cardDetails
                                    }
                                }
                            ).then((data) => {
                                resolve(data);
                            }).catch((error) => {
                                reject(error);
                            }); 
                        }else {
                            reject(intent.data)
                        }
                    });
                });
            },
            isStepValid: function () {
                return new Promise(function (resolve, reject) {

                    var self = this;

                    var isValid = true;

                    // Reset card errors
                    self.cardNameError = undefined;
                    self.paymentError = undefined;
                    self.showError = false;

                    // Reset value
                    self.registrationData.PaymentMethodId = undefined;

                    // Create a token to see if the data is valid
                    self.createToken(self)
                        .then((response) => {

                            if (response.error) {
                                if(response.error.message.indexOf("We are unable to authenticate")> -1) {
                                    this.PaymentFailedFlag = false;
                                    self.paymentError = response.error.message;
                                    window.scrollTo(0, 0);
                                }else {
                                    this.PaymentFailedFlag = true;
                                    self.paymentError = undefined;
                                }
                                    
                                resolve(false);
                                return;
                            } else {
                                self.registrationData.PaymentMethodId = response.setupIntent.payment_method;
                            }

                            if (self.showError === true) {
                                isValid = false;
                            }

                            var cardName = self.cardName;
                            var regex = new RegExp(self.cardNameRegex);

                            if (!cardName || !regex.test(cardName)) {
                                isValid = false;
                                self.cardNameError = 'You must provide the name on your card';
                            }

                            // Card valid, now validate billing address
                            if (!self.$refs.billingName.validate()) {
                                if ((self.$refs.billingName.value || '').trim() === '' || self.$refs.billingName.value === null) {
                                    self.billingNameErrorMessage = 'You must specify a Building Number/Name';
                                } else {
                                    self.billingNameErrorMessage = 'There are invalid characters in your building number/name';
                                }

                                self.$refs.billingName.invalidate();
                                isValid = false;
                            }

                            if (!self.$refs.billingStreet.validate()) {
                                if ((self.$refs.billingStreet.value || '').trim() === '' || self.$refs.billingStreet.value === null) {
                                    self.billingStreetErrorMessage = 'You must specify an address';
                                } else {
                                    self.billingStreetErrorMessage = 'There are invalid characters in your address';
                                }

                                self.$refs.billingStreet.invalidate();
                                isValid = false;
                            }

                            if (!self.$refs.billingTownOrCity.validate()) {
                                if ((self.$refs.billingTownOrCity.value || '').trim() === '' || self.$refs.billingTownOrCity.value === null) {
                                    self.billingTownErrorMessage = 'You must specify a town';
                                } else {
                                    self.billingTownErrorMessage = 'There are invalid characters in your town';
                                }

                                self.$refs.billingTownOrCity.invalidate();
                                isValid = false;
                            }

                            if (!self.$refs.billingPostcode.validate()) {
                                isValid = false;
                            }

                            resolve(isValid);
                        })
                        .catch((error) => {
                            resolve(false);
                        });

                }.bind(this));
            }
        }
    };
</script>

<style lang="less" scoped>

    @import "../../components/common/assets/css/common.less";

    .stripeContainer {
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        padding: 8px 30px 30px 30px;
        margin: 30px 0;
        border: 1px solid transparent;
        box-shadow: 0 1px 3px 0 #dddddd;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .stripe-element {
        background: linear-gradient(to left, #406EB3 0%, #982881 100%) left bottom transparent no-repeat;
        background-size: 100% 2px;
        padding: 10px 0;
    }

    .stripe-element::placeholder {
        color: #AAB7C4;
        font-family: Helvetica Neue, serif;
        font-weight: bold;
        font-size: 14px;
    }

    .stripeLogo {
        width: 200px;
        float: right;
        margin: 28px 0 0 -14px;
    }

    .cardLogoContainer {
        text-align: center;
        height: 44px;
        float: right;
        margin: 28px 24px 0 -14px;
    }

    .cardLogo {
        height: 44px;
        width: 70px;
        margin: 0 2px;
    }

    .card-errors {
        color: @experian-danger-error;
        font-size: 14px;
        margin: 6px 4px 4px;
    }

    .stripe-label {
        top: -12px;
        font-size: 14px;
        background-image: -webkit-linear-gradient(45deg, #982881, #406EB3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #406EB3;
    }

    .stripe-field {
        margin-bottom: 32px;
    }

    /* Tablet */
    @media only screen and (max-width: 644px) {

        .stripeContainer {
            padding-bottom: 8px;
        }

        .stripeLogo {
            width: 200px;
            height: 44px;
            display: block;
            float: none;
            margin: 28px auto -12px;
        }

        .cardLogoContainer {
            width: 100%;
            height: 100%;
            float: none;
            display: block;
            margin: 28px auto 0;
        }

        .cardLogo {
            height: 44px;
            width: 70px;
            margin: 2px 2px;
        }
    }

</style>
