<template>
    <button class="results" :disabled="disableButton" @click="directorSelect">
        <div>
            <div class="resultDetails">
                <div class="propertyLine" id="businessName">
                    {{BusinessResultObj.name}} ({{BusinessResultObj.businessRef}})
                </div>
                <div class="propertyLine" id="address">
                    {{getBusinessAddress()}}
                </div>
                <div class="propertyLine" id="directorCount">
                    <strong>{{showDirectorCount}}</strong>
                </div>
            </div>
            <div style="float: right;">
                <img v-show="loading === false" class="actionImage"
                     :src="require('../common/assets/Images/MoveOn.svg')"/>
                <ebaSpinner :useAlt="true" class="actionImage" v-show="loading === true"/>
            </div>
        </div>
    </button>
</template>

<script>

    import businessSearchHandler from '../../scripts/businessSearchHandler';
    import registrationHandler from '../../scripts/registrationHandler';
    import pepsAndSanctionsHandler from '../../scripts/common/pepsAndSanctionsHandler';
    import eventTrackingHandler from '../../scripts/common/eventTrackingHandler';

    import {mapActions} from 'vuex';

    export default {
        name: 'businessResult',
        props: {
            BusinessResultObj: {
                type: Object,
                required: true
            },
            IsDisabled: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            businessSearchHandler.searchDirector(this.BusinessResultObj.businessRef)
                .then((response) => {
                    if (response.data.length > 0) {
                        var directorsList = response.data;
                        this.Directors = directorsList;
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        computed: {
            showDirectorCount() {
                var numOfDirectors = this.Directors.length || 0;

                if (numOfDirectors === 0) {
                    this.disableLink = true;
                    return 'No directors found';
                } else if (numOfDirectors === 1) {
                    return this.Directors[0].firstName + ' ' + this.Directors[0].surname;
                } else if (numOfDirectors > 1) {
                    return numOfDirectors + ' known directors';
                }
            },
            disableButton() {
                if (this.loading === true || this.IsDisabled === true) {
                    return true;
                }
                return false;
            }
        },
        data() {
            return {
                Directors: [],
                loading: true
            }
        },
        methods: {

            ...mapActions([
                'setBusiness',
                'setDirector',
                'checkPepsAndSanctions',
                'updateToPepsRoute'
            ]),

            getBusinessAddress() {
                var returnVal = '';

                if (this.BusinessResultObj.locationLine1 !== null) {
                    returnVal = this.BusinessResultObj.locationLine1;
                }
                if (this.BusinessResultObj.locationLine2 !== null) {
                    returnVal += ', ' + this.BusinessResultObj.locationLine2;
                }
                if (this.BusinessResultObj.locationLine3 !== null) {
                    returnVal += ', ' + this.BusinessResultObj.locationLine3;
                }
                if (this.BusinessResultObj.postcode !== null && this.BusinessResultObj.postcode !== '') {
                    returnVal += ', ' + this.BusinessResultObj.postcode;
                }

                return returnVal;
            },

            directorSelect() {

                if (!this.IsDisabled) {
                    this.setBusiness(this.BusinessResultObj)
                        .then(() => {
                            if (this.Directors.length === 1) {
                                this.setLoading(true);

                                //Check peps and sanctions. Store the result
                                this.checkPepsAndSanctions(this.BusinessResultObj)
                                    .then(pepsResult => {
                                        //Check directorship not already taken
                                        if (pepsResult === true) {
                                            this.updateToPepsRoute()
                                                .then(() => {
                                                    this.handleDirectorshipAppointment();
                                                })
                                                .catch((error) => {
                                                    this.showCommonError(error);
                                                });
                                        } else {
                                            this.handleDirectorshipAppointment();
                                        }
                                    })
                                    .catch((error) => {
                                        this.showCommonError(error);
                                    });
                            } else {
                                this.$router.push({
                                    name: 'DirectorSearch',
                                    params: {
                                        Directors: this.Directors
                                    }
                                });
                            }
                        });
                }
            },

            showCommonError(error) {
                this.$emit('showCommonError');
                this.setLoading(false);
                if (error) {
                    console.log(error);
                }
            },

            handleDirectorshipAppointment() {
                registrationHandler.isAppointmentAvailable(this.Directors[0].urn, this.BusinessResultObj.businessRef)
                    .then((response) => {
                        if (response === true) {
                            eventTrackingHandler.trackEvent({
                                Event: eventTrackingHandler.trackingEvents.directorSelection,
                                Details: {
                                    "Selected Director": this.Directors[0].urn + " | " +
                                        this.Directors[0].firstName + ' ' + this.Directors[0].surname,
                                    "Selected Business": this.BusinessResultObj.businessRef + " | " +
                                        this.BusinessResultObj.businessName,
                                    "Available": true
                                }
                            });
                            this.setLoading(false);
                            this.setDirector(this.Directors[0])
                                .then(() => {
                                    this.$router.push({
                                        name: 'ContactDetails'
                                    });
                                });
                        } else {
                            eventTrackingHandler.trackEvent({
                                Event: eventTrackingHandler.trackingEvents.directorSelection,
                                Details: {
                                    "Selected Director": this.DirectorResultObj.urn + " | " +
                                        this.DirectorResultObj.firstName + ' ' + this.DirectorResultObj.surname,
                                    "Selected Business": this.businessRef + " | " + this.businessName,
                                    "Available": false
                                }
                            });
                            this.$emit('showDirectorshipTaken');
                            this.setLoading(false);
                        }
                    })
                    .catch((error) => {
                        this.showCommonError(error);
                    });
            },

            setLoading(value) {
                if (value === true) {
                    this.loading = true;
                    this.$parent.isDisabled = true;
                } else {
                    this.loading = false;
                    this.$parent.isDisabled = false;
                }
            }
        }
    };

</script>

<style lang="less" scoped>
    @import "../../components/common/assets/css/common.less";

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        width: 100%;
    }

    .results {
        text-align: left;
        padding: 8px 18px
    }

    .results:hover:enabled {
        background: @experian-background-grey;
        -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
    }

    .resultDetails {
        display: inline-block;
    }

    #businessName {
        color: @experian-purple;
        font-weight: bold;
    }

    #address {
        color: @experian-sapphire;
    }

    .propertyLine {
        margin: 4px 0;
    }

    .actionImage {
        width: 22px;
        height: 22px;
        margin-top: 100%;
    }

    @media only screen and (max-width: 1024px) {
        .resultDetails {
            max-width: 80%;
        }
    }

</style>
