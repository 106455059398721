var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isResponsive === false
      ? _c("div", [_c("img", { attrs: { src: _vm.source, alt: _vm.altText } })])
      : _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$mq === "mobile",
                  expression: "$mq === 'mobile'",
                },
              ],
            },
            [_c("img", { attrs: { src: _vm.mobileSource, alt: _vm.altText } })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$mq === "tablet",
                  expression: "$mq === 'tablet'",
                },
              ],
            },
            [_c("img", { attrs: { src: _vm.tabletSource, alt: _vm.altText } })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$mq === "desktop",
                  expression: "$mq === 'desktop'",
                },
              ],
            },
            [_c("img", { attrs: { src: _vm.source, alt: _vm.altText } })]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }