<template>
    <svg class="spinner" :width="width" :height="height" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle :class="useAltSpinner()" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
</template>

<script>

    export default {
        name: 'spinner',
        props: {
            width: {
                type: String,
                required: false,
                default: '18px'
            },
            height: {
                type: String,
                required: false,
                default: '18px'
            },
            useAlt: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            useAltSpinner() {
                if (this.useAlt) {
                    return 'pathSapphire';
                }

                return 'path';
            }
        }
    };

</script>

