<template>
    <div class="outerContainer">
        <div class="headerContainer">
            <div class="experianLogo">
                <ebaImageLink alt-text="ExperianLogo" route-name="Home"
                           :isResponsive="true"
                           :source="require('../common/assets/images/ExperianLogo.svg')"
                           :tabletSource="require('../common/assets/images/ExperianLogo.svg')"
                           :mobileSource="require('../common/assets/images/ExperianLogoSmall.svg')" />
            </div>
            <div class="headerActions">
                <ebaHeaderActions></ebaHeaderActions>
            </div>
            <br style="clear: both;"/>
            <div class="productImage">
                <ebaLogo alt-text="ProspectorLogo" :isResponsive="true"
                            :source="require('../../assets/Images/ProductIcon.svg')"
                            :tabletSource="require('../../assets/Images/ProductIconSmall.svg')"
                            :mobileSource="require('../../assets/Images/ProductIconSmall.svg')"/>
                <div class="productText">
                    My Business Profile
                </div>
            </div>
        </div>
        <div class="seperator"></div>
    </div>
</template>

<script>

    export default {
        name: 'Header'
    };

</script>

<style lang="less">

    @import '../common/assets/css/common.less';

    .outerContainer {
        height: 212px
    }

    .headerContainer {
        position: relative;
        background: transparent;
        height: 76px;
        width: 100%;
    }

    .experianLogo {
        float: left;
        padding-top: 4px;
        margin: 16px;
        width: 120px;
        height: 38px;
    }

    .headerActions {
        float: right;
        margin: 16px;
        height: 48px;
    }

    .productImage {
        position: relative;
        top: -109px;
        margin-left: auto;
        margin-right: auto;
        width: 470px;
        height: 248px;
        z-index: -1;
    }

    .productText {
        position: relative;
        top: -135px;
        text-align: center;
        font-size: 22px;
        color: @experian-pink;
    }

    .seperator {
        height: 1px;
        width: 100%;
        -webkit-box-shadow: 0 2px 3px @experian-dark-medium-grey;
        -moz-box-shadow: 0 2px 3px @experian-dark-medium-grey ;
        box-shadow: 0 2px 3px @experian-dark-medium-grey ;
        z-index: 1;
        background: transparent;
    }

    /* Tablet */
    @media only screen and (max-width: 1024px) {

        .productImage {
            top: -106px;
        }
    }

    /* Mobile */
    @media only screen and (max-width: 560px) {

        .outerContainer {
            height: 180px;
        }

        .experianLogo {
            width: 30px;
            height: 38px;
            margin: 20px;
            padding: 0;
        }

        .productImage {
            clear: both;
            top: -86px;
            max-width: 320px;
            height: 166px;
        }

        .productText {
            font-size: 18px;
            top: -96px;
        }

        .headerActions {
            margin: 22px 20px 22px 0;
        }

        .headerActions button {
            margin: 2px;
        }
    }

</style>


