<template>
    <div class="radio-group">
        <form>
            <div style="float: left;">
                <label :class="[{'invalid': valid !== true}, 'radio-container']" v-for="item in d_items" :key="item.id">
                    <slot>
                        {{item.description}}
                    </slot>
                    <input ref="checkboxInput"
                        type="radio" 
                        :name="name"
                        :value="item.value || item.id"
                        :id="item.id"  
                        v-model="selected"
                        required>
                    <div class="checkmark"></div>
                    <p>{{item.text}}</p>
                </label>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'RadioGroup',
        props: {
            items: {
                type: Array,
                required: true,
                default: []
            },
            name: {
                type:String,
                required: false,
                default: 'radioGroupSelect',
                
            },
            valid: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data() {
            return {
                d_items: this.items,
                selected:null
            };
        },
        watch: {
            items: {
                handler: function(val) {
                    this.d_items = val;
                },
                deep: true
            },
            selected: function(val) {
                this.$emit('option-selected', val)
            }            
        },
        methods: {
            setInvalid() {
                this.valid = false;
            },
            setValid() {
                this.valid = true;
            },
        }
    };
</script>

<style lang="less" scoped>
    @import '../assets/css/colours.less';
    @checkmark-size: 40px;

    .radio-group {
        margin-top:2em;
    }

    .radio-container {
        position: relative;
        min-height: @checkmark-size;
        cursor: pointer;
        user-select: none;
        margin-bottom:1em;
        display:flex;
        align-items: center;

        &:last-of-type{
            margin-bottom:0em;
        }

        &:hover .checkmark {
            background-color: @experian-background-grey;
        }
        
        &.invalid {
            .checkmark {
                -moz-border-image: none;
                -webkit-border-image: none;
                border-image: none;
                border: 2px solid @experian-danger-error;
            }
        }

        /* When the checkbox is checked, add a blue background */
        & input:checked ~ .checkmark {
            background-color: @experian-success;
            border: none;
            width: @checkmark-size;
            height: @checkmark-size;
            border: 2px solid transparent;
        }
        /* Show the checkmark when checked */
        & input:checked ~ .checkmark:after {
            display: block;
        }

        p {
            color: @experian-sapphire;
            margin: 0;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        & .checkmark:after {
            left: 13.5px;
            top: 2.5px;
            width: 5px;
            height: 23px;
            border: solid white;
            border-width: 0 4px 4px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    /* Create a custom checkbox */
    .checkmark {

        height: @checkmark-size !important;
        width: @checkmark-size !important;
        background: white;
        border: 2px solid @experian-purple;
        box-sizing: border-box;
        -moz-border-image: -moz-linear-gradient(top, @experian-light-blue 0%, @experian-purple 100%);
        -webkit-border-image: -webkit-linear-gradient(top, @experian-light-blue 0%, @experian-purple 100%);
        border-image: linear-gradient(to bottom, @experian-light-blue 0%, @experian-purple 100%);
        border-image-slice: 1;
        margin-right: 1em;
        position:relative;
        flex-shrink: 0;


        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    
</style>
