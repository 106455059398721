<template>
    <div>
        <ebaRegistrationStep ref="RegistrationStep" step-title="For added security please enter a memorable word.">
            <div style="margin-top: 20px;">
                <ebaTextInput ref="memorableWordInput"
                              v-model="registrationData.MemorableWordDetails.MemorableWord"
                              helpText="You will need this memorable word when you login to your account"
                              input-type="password"
                              inputID="memorableWordInput"
                              place-holder="Enter memorable word"/>
                <ebaCriteriaList ref="memorableWordList"/>
                <ebaTextInput ref="memorableWordConfirmationInput"
                              v-model="registrationData.MemorableWordDetails.ConfirmMemorableWord"
                              input-type="password"
                              inputID="memorableWordConfirmInput"
                              place-holder="Confirm memorable word"/>
                <ebaCriteriaList ref="confirmMemorableWordList"/>
                <ebaTextInput ref="memorableWordReminderInput"
                              v-model="registrationData.MemorableWordDetails.MemorableHint"
                              :autocomplete="false"
                              place-holder="Enter memorable word hint"
                              inputID="memorableWordReminderInput"/>
                <ebaCriteriaList ref="memorableWordHintList"/>
            </div>
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import validationHandler from '../../scripts/common/validationHandler';

    export default {
        name: 'MemorableWord',
        props: {
            registrationData: {
                type: Object
            }
        },
        mounted() {
            var self = this;
            this.$emit('clearCustomProceedText');

            // Memorable word
            this.memorableWordCriteria.push({
                description: '8 or more characters',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        var regex = new RegExp('^.{8,56}$');
                        if (regex.test(value.trim())) {
                            resolve();
                        } else {
                            reject();
                        }
                    });
                }
            });

            this.memorableWordCriteria.push({
                description: 'Must NOT be the same as your email address or password',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        //Check password and email not used
                        let checkArr = [
                            self.registrationData.EmailAddress,
                            self.registrationData.Password
                        ]

                        if (validationHandler.arrayUniqueValueCheck(value, checkArr) === false) {
                            reject();
                        }

                        resolve();
                    });
                }
            });

            this.$refs.memorableWordList.setObjectReference(this.$refs.memorableWordInput);
            this.$refs.memorableWordList.setCriteria(this.memorableWordCriteria);

            // Confirm memorable word
            this.confirmMemorableWordCriteria.push({
                description: 'Memorable words must match',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        let result = false;
                        var regex = new RegExp('^.{1,56}$');

                        if (regex.test(value.trim())) {
                            result = true;
                        } else {
                            result = false;
                        }

                        if (self.registrationData.MemorableWordDetails.MemorableWord !== self.registrationData.MemorableWordDetails.ConfirmMemorableWord
                            || result === false) {
                            reject();
                        }

                        resolve();
                    });
                }
            });

            this.$refs.confirmMemorableWordList.setObjectReference(this.$refs.memorableWordConfirmationInput);
            this.$refs.confirmMemorableWordList.setCriteria(this.confirmMemorableWordCriteria);

            // Memorable word hint
            this.memorableWordHintCriteria.push({
                description: 'Must NOT be blank',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        var regex = new RegExp('^.{1,56}$');
                        if (regex.test(value.trim())) {
                            resolve();
                        } else {
                            reject();
                        }
                    });
                }
            });

            this.memorableWordHintCriteria.push({
                description: 'Must NOT be the same as your memorable word',
                requirement: (value) => {
                    return new Promise((resolve, reject) => {
                        if (validationHandler.valueMatchCheck(self.registrationData.MemorableWordDetails.MemorableWord, value) === true) {
                            reject();
                        }

                        resolve();
                    });
                }
            });

            this.$refs.memorableWordHintList.setObjectReference(this.$refs.memorableWordReminderInput);
            this.$refs.memorableWordHintList.setCriteria(this.memorableWordHintCriteria);

            this.$refs.memorableWordInput.$el.getElementsByTagName('input')[0].focus();
        },
        data: function () {
            return {
                memorableWordCriteria: [],
                confirmMemorableWordCriteria: [],
                memorableWordHintCriteria: []
            };
        },
        methods: {
            isStepValid: function () {
                let self = this;

                return new Promise((resolve) => {
                    let memorableWordValidPromise = self.$refs.memorableWordList.isCriteriaValid();
                    let confirmMemorableWordValidPromise = self.$refs.confirmMemorableWordList.isCriteriaValid();
                    let memorableWordHintValidPromise = self.$refs.memorableWordHintList.isCriteriaValid();

                    Promise.all([
                        memorableWordValidPromise,
                        confirmMemorableWordValidPromise,
                        memorableWordHintValidPromise
                    ])
                        .then((result) => {
                            if (result.indexOf(false) >= 0) {
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                        });
                });
            }
        }
    };
</script>

<style lang="less" scoped>

</style>
