var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "divFooter" } }, [
    _c("div", { staticClass: "FooterContactUs" }, [_vm._v("Contact Us")]),
    _vm._v(" "),
    _c("div", { staticClass: "FooterViolet" }, [
      _vm._v(
        "If you have any queries, please contact us and a member of our team will be happy to\n        help\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "FooterDarkBlue" },
      [
        _c(
          "router-link",
          { attrs: { id: "anchorTelNo", to: { name: "SalesAndHelp" } } },
          [
            _vm._v(
              _vm._s(_vm.footerDetails.contactUsText) +
                " -\n            " +
                _vm._s(_vm.footerDetails.supportPhoneNumber)
            ),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "ContactUsDivider" }, [_vm._v("|")]),
        _vm._v(" "),
        _c(
          "router-link",
          { attrs: { id: "anchorEmail", to: { name: "EmailUs" } } },
          [
            _vm._v(
              "Email us -\n            " +
                _vm._s(_vm.footerDetails.supportEmailAddress)
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "FooterSubText" }, [
      _vm._v(_vm._s(_vm.footerDetails.callChargeText)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "line-separator" }),
    _vm._v(" "),
    _c("table", { staticClass: "FooterDocumentsContainer" }, [
      _c("tbody", [
        _c("tr", [
          _c(
            "td",
            { staticClass: "FooterDocument" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    id: "anchorTC",
                    to: { name: "ViewTermsAndConditions" },
                  },
                },
                [_vm._v("Terms & Conditions")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { id: "anchorPP", to: { name: "Privacy" } } },
                [_vm._v("Privacy Policy")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "javascript: Cookiebot.renew()",
                    id: "anchorCK",
                  },
                },
                [_vm._v("Cookies")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { id: "anchorHF", to: { name: "FAQs" } } },
                [_vm._v("Help & FAQ's")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "FooterSubText" }, [
      _vm._v(
        "Experian Ltd is authorised and regulated by the Financial Conduct Authority. Experian\n        Ltd is registered in England and Wales under company registration number 653331.\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "FooterSubText" }, [
      _vm._v(
        "Registered office address. The Sir John Peace Building. Experian Way, NG2 Business\n        Park, Nottingham NG80 1ZZ\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "FooterSubText" }, [
      _vm._v("© 2018 Experian. All rights reserved"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "FooterSubText promotionalOfferText" },
      [_vm._t("promotionalOffer")],
      2
    ),
    _vm._v(" "),
    _c("table", { staticClass: "tblSocialMedia" }, [
      _c("tbody", [
        _c("tr", [
          _c(
            "td",
            [
              _c(
                "div",
                { staticClass: "innerMediaContainer" },
                [
                  _c("ebaImageLink", {
                    staticClass: "SocialMedia",
                    attrs: {
                      routeName: "LinkedIn",
                      source: require("./assets/images/LinkedIn.svg"),
                      altText: "LinkedIn",
                    },
                  }),
                  _vm._v(" "),
                  _c("ebaImageLink", {
                    staticClass: "SocialMedia",
                    attrs: {
                      routeName: "Facebook",
                      source: require("./assets/images/Facebook.svg"),
                      altText: "Facebook/ExperianBusinessAssist",
                    },
                  }),
                  _vm._v(" "),
                  _c("ebaImageLink", {
                    staticClass: "SocialMedia",
                    attrs: {
                      routeName: "YouTube",
                      source: require("./assets/images/Youtube.svg"),
                      altText: "Youtube/ExperianSme",
                    },
                  }),
                  _vm._v(" "),
                  _c("ebaImageLink", {
                    staticClass: "SocialMedia",
                    attrs: {
                      routeName: "Twitter",
                      source: require("./assets/images/Twitter.svg"),
                      altText: "Twitter/ExperianBAssist",
                    },
                  }),
                  _vm._v(" "),
                  _c("ebaImageLink", {
                    staticClass: "SocialMedia",
                    attrs: {
                      routeName: "Blog",
                      source: require("./assets/images/blog.svg"),
                      altText: "ExperianBlog",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("ebaImageLink", {
                staticClass: "Stripe",
                attrs: {
                  source: require("./assets/images/payment/powered_by_stripe.svg"),
                  altText: "Stripe",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }