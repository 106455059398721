<template>
    <div>
        <span v-bind:value="value">
            <ebaTextInput class="datePart" id="day"
                          v-on:onChange="updateValue"
                          ref="dayInput"
                          inputType="number"
                          inputID="dayInput"
                          invalidText=""
                          v-model="value.day"
                          place-holder="DD"/>
            <ebaTextInput class="datePart" id="month"
                          v-on:onChange="updateValue"
                          ref="monthInput"
                          inputType="number"
                          v-model="value.month"
                          inputID="monthInput"
                          invalidText=""
                          place-holder="MM"/>
            <ebaTextInput class="datePart" id="year"
                          v-on:onChange="updateValue"
                          ref="yearInput"
                          inputType="number"
                          v-model="value.year"
                          inputID="yearInput"
                          invalidText=""
                          place-holder="YYYY"/>
        </span>
        <div>
            <p id="infoText" :class="[getValidClass()]">{{getLabelText()}}</p>
        </div>
    </div>
</template>

<script>

    import validationHandler from '../../../scripts/common/validationHandler';

    export default {
        name: 'DateEntry',
        props: {
            invalidText: {
                type: String,
                required: true,
            },
            helpText: {
                type: String,
                required: false
            },
            value: {}
        },
        data() {
            return {
                validationStateEnum: validationHandler.getValidationStates(),
                validationState: null
            };
        },
        methods: {
            clearValidation() {
                this.validationState = this.validationStateEnum.INITIAL;
            },
            invalidate() {
                this.validationState = this.validationStateEnum.INVALID;
            },
            validate() {
                this.validationState = this.validationStateEnum.VALID;

                // Check day
                var day = this.$refs.dayInput.value;
                if (!this.$refs.dayInput.validate()) {
                    this.validationState = this.validationStateEnum.INVALID;
                } else if (day <= 0 || day > 31) {
                    this.$refs.dayInput.invalidate();
                    this.validationState = this.validationStateEnum.INVALID;
                }

                // Check month
                var month = this.$refs.monthInput.value;
                if (!this.$refs.monthInput.validate()) {
                    this.validationState = this.validationStateEnum.INVALID;
                } else if (month <= 0 || month > 12) {
                    this.$refs.monthInput.invalidate();
                    this.validationState = this.validationStateEnum.INVALID;
                }

                // Check year
                var year = this.$refs.yearInput.value;
                if (!this.$refs.yearInput.validate()) {
                    this.validationState = this.validationStateEnum.INVALID;
                } else if (year < 1883 || year > (new Date()).getFullYear() - 18) {
                    this.$refs.yearInput.invalidate();
                    this.validationState = this.validationStateEnum.INVALID;
                }

                return this.validationState !== this.validationStateEnum.INVALID;
            },
            getValidClass() {
                switch (this.validationState) {
                    case this.validationStateEnum.VALID:
                        return 'valid';
                    case this.validationStateEnum.INVALID:
                        return 'invalid';
                    case this.validationStateEnum.INITIAL:
                    default:
                        return 'initial';
                }
            },
            getLabelText() {
                switch (this.validationState) {
                    case this.validationStateEnum.VALID:
                        return this.helpText;
                    case this.validationStateEnum.INVALID:
                        return this.invalidText;
                    case this.validationStateEnum.INITIAL:
                    default:
                        return this.helpText;
                }
            },
            updateValue() {
                this.$emit('input', this.value);
                this.validationState = this.validationStateEnum.INITIAL;
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../assets/css/common.less';

    .datePart {
        margin: 14px 10px 0 0;
        display: inline-block;
    }

    #day {
        width: 40px;
    }

    #month {
        width: 40px;
    }

    #year {
        width: 58px;
    }

    #infoText {
        margin: 6px 4px 4px 0;
        font-size: 14px;
    }

    #infoText,
    #infoText.initial,
    #infoText.valid {
        color: @experian-sapphire;
    }

    #infoText,
    #infoText.invalid {
        color: @experian-danger-error;
    }

</style>