var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: { "step-title": "Please enter a password." },
        },
        [
          _c(
            "div",
            [
              _c("ebaTextInput", {
                ref: "password",
                attrs: {
                  inputID: "passwordInput",
                  autocomplete: "new-password",
                  "input-type": "password",
                  "place-holder": "Enter Password",
                  invalidText: _vm.passwordInvalidText,
                },
                model: {
                  value: _vm.registrationData.Password,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "Password", $$v)
                  },
                  expression: "registrationData.Password",
                },
              }),
              _vm._v(" "),
              _c("ebaCriteriaList", { ref: "passwordList" }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "confirmedPassword",
                attrs: {
                  inputID: "confirmPasswordInput",
                  autocomplete: "new-password",
                  "input-type": "password",
                  "place-holder": "Confirm Password",
                  invalidText: _vm.confirmPasswordInvalidText,
                },
                model: {
                  value: _vm.registrationData.ConfirmPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "ConfirmPassword", $$v)
                  },
                  expression: "registrationData.ConfirmPassword",
                },
              }),
              _vm._v(" "),
              _c("ebaCriteriaList", { ref: "confirmPasswordList" }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }