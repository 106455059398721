import axios from 'axios';
import { get as config } from './config.js';

class SecurityHandler {
    static requestAntiForgeryToken() {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: config().AntiForgeryAPI + 'create',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'applicationId': config().ApplicationId,
                    'ipAddress': ''
                }
            })
                .then((response) => {
                    //console.log('response:', response);
                    var expires = new Date(response.data.cookieExpiresOn);
                    //console.log('expires:', expires);
                    //Commented out due to Proxy Api changes
                    //document.cookie = 'xsrf-token=' + response.data.cookieToken + '; expires=' + expires + '; path=/; domain=' + config().AntiForgeryCookieDomain;
                    resolve(response.data.formToken);
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data);
                    } else {
                        console.log(error);
                    }
                    reject(error);
                });

        });
    }

    static generateAntiForgeryToken() {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: config().AntiForgeryAPI + 'create',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'applicationId': config().ApplicationId,
                    'ipAddress': ''
                }
            })
                .then((response) => {
                    resolve({
                        xsrfToken: response.data.cookieToken,
                        formToken: response.data.formToken
                    });
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data);
                    } else {
                        console.log(error);
                    }
                    reject(error);
                });

        });
    }

    static validateAntiForgeryToken(formToken) {
        var cookieToken = this.getCookie('xsrf-token');
        if (cookieToken !== undefined && cookieToken !== null && cookieToken !== '') {
            return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: config().AntiForgeryAPI + 'validate',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'applicationId': config().ApplicationId,
                    'formToken': formToken,
                    'cookieToken': cookieToken
                }
            })
                .then(response => {
                    //console.log('response:', response);
                    if (response.data.validated) {
                        resolve(true);
                    } else {
                        reject(false);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data);
                    } else {
                        console.log(error);
                    }
                    reject(error);
                });
            });
        } else {
            return false;
        }
    }

    static getCookie(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) { 
            return match[2];
        }
    }
}



export { SecurityHandler as default };
