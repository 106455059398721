import axios from 'axios';
import SecurityHandler from './antiForgeryHandler';
import {get as appConfig} from './config';

// Create a separate instance of Axios to avoid an infinite loop
const securityAxios = axios.create({});

export default function setup() {

    // Request
    axios.interceptors.request.use(config => {
        return new Promise((resolve, reject) => {
            securityAxios({
                method: 'POST',
                url: appConfig().AntiForgeryAPI + 'create',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'applicationId': appConfig().ApplicationId,
                    'ipAddress': ''
                }
            })
                .then((response) => {
                    config.headers['CookieToken'] = response.data.cookieToken;
                    config.headers['xsrf-token'] = response.data.cookieToken;
                    config.headers['FormToken'] = response.data.formToken;
                    config.headers['applicationID'] = appConfig().ApplicationId;

                    resolve(config);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }, (err) => {
        return Promise.reject(err);
    });

    // Response
    axios.interceptors.response.use(response => {
        return new Promise((resolve, reject) => {
            // Reject any response with an unauthorized as these come through with a status 200
            if (response.data.unauthorized) {
                reject('Unauthorized');
            } else {
                resolve(response);
            }
        });
    }, (error) => {
        return Promise.reject(error);
    });
}
