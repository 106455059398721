var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: { "step-title": "Tell us about you." },
        },
        [
          _c(
            "div",
            [
              _c(
                "ebaWarning",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showInvalidAttempt,
                      expression: "showInvalidAttempt",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "errorText" }, [
                    _c("p", { staticClass: "heading" }, [
                      _c("strong", [_vm._v("Having difficulty?")]),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "You may need to check your home address details registered at the UK Companies House to ensure it’s the same as what you are entering."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "You can check this by looking at your details held at Companies House."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("Follow this "),
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.WebFilingLink, target: "_blank" },
                        },
                        [_c("strong", [_vm._v("Link")])]
                      ),
                      _vm._v(
                        ". to the Companies House Web Filing Service and log in. If you are unsure of your log in details your accountant may be able to help."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Once logged in find your name under 'Directors & Secretaries' and click edit. This will show you 3 addresses, the registered office, the correspondence address, and your\n                    "
                      ),
                      _c("strong", [_vm._v("home address")]),
                      _vm._v(". It’s the \n                    "),
                      _c("strong", [_vm._v("home address")]),
                      _vm._v(
                        "\n                    that needs to match the details you are keying into the My Business Profile registration form."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("strong", [_vm._v("Still having difficulty?")]),
                      _vm._v(" Give us a call on 0345 045 8081 or email at "),
                      _c("a", { attrs: { href: _vm.EBEInfoEmail } }, [
                        _vm._v("ebe.info@experian.com"),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("ebaDropdown", {
                ref: "title",
                attrs: {
                  inputID: "titleInput",
                  "place-holder": "Title",
                  invalidText: "You must select a title",
                  "data-set": _vm.titles,
                },
                model: {
                  value: _vm.registrationData.Title,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "Title", $$v)
                  },
                  expression: "registrationData.Title",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "fullNameInput",
                attrs: {
                  inputID: "fullNameinput",
                  "input-type": "text",
                  "place-holder": "Full Name",
                  validation: _vm.fullNameValidation,
                  disabled: true,
                },
                model: {
                  value: _vm.registrationData.FullName,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "FullName", $$v)
                  },
                  expression: "registrationData.FullName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("br", { staticStyle: { clear: "both" } }),
        ]
      ),
      _vm._v(" "),
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: {
            "step-title": "What's your date of birth?",
            showStepNumber: false,
          },
        },
        [
          _c(
            "div",
            [
              _c("ebaDateEntry", {
                ref: "dateOfBirthEntry",
                attrs: {
                  id: "dateOfBirth",
                  invalidText: "You must provide your date of birth",
                },
                model: {
                  value: _vm.registrationData.DateOfBirth,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "DateOfBirth", $$v)
                  },
                  expression: "registrationData.DateOfBirth",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("br", { staticStyle: { clear: "both" } }),
        ]
      ),
      _vm._v(" "),
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: {
            "step-title": "What's your home address?",
            showStepNumber: false,
          },
        },
        [
          _c(
            "div",
            [
              _c("ebaTextInput", {
                ref: "houseNumberName",
                attrs: {
                  autocomplete: "address-line1",
                  inputID: "houseNumberNameInput",
                  invalidText: "You must specify a Building Number/Name",
                  "place-holder": "Building Number/Name",
                  validation: _vm.anyCharacterValidationRegex,
                },
                model: {
                  value: _vm.registrationData.SearchHouseNumberName,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "SearchHouseNumberName", $$v)
                  },
                  expression: "registrationData.SearchHouseNumberName",
                },
              }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "postCode",
                attrs: {
                  autocomplete: "postal-code",
                  inputID: "postcodeInput",
                  invalidText: "You must specify a valid postcode",
                  "place-holder": "Postcode",
                  validation: _vm.postcodeValidationRegex,
                },
                model: {
                  value: _vm.registrationData.SearchPostcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.registrationData, "SearchPostcode", $$v)
                  },
                  expression: "registrationData.SearchPostcode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("br", { staticStyle: { clear: "both" } }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }