<template>
    <div>
        <div class="confirmationImageContainer">
            <img v-if="commonProperties.success== true" class="confirmationImage" src="../../assets/Images/success.svg" />
            <img v-else-if="!success" class="confirmationImage" src="../../assets/Images/alert.svg" />
        </div>
        <div class="confirmationTextContainer">
            <h3 v-if="commonProperties.success" class="gradientText">
                {{successText}}
            </h3>
            <h3 v-else-if="!commonProperties.success" class="gradientText">
                We encountered an error processing your request. Please try again.
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConfirmationStep",
    props: {
        "commonProperties": {
            type: Object,
            confirmationType: null
        }
    },
    data() {
        return {
            successText: null,
            step: null
        };
    },
    mounted() {
        const context = this;
        this.$emit("action", ["setConfirmation"]);
        this.$emit("generateNavButtons", [
            {
                type: "primary",
                text: "Continue",
                action() {
                    context.$emit("action", ["redirectToReport"]);
                }
            }
        ]);
        if(this.commonProperties.confirmationType == "downgradePlanToFree"){
            context.successText = "You have been successfully downgraded. You can upgrade to premium anytime."
        }else {
            context.successText = "Your offer has been successfully applied."
        }
        this.commonProperties.confirmationType = null;
    },
};
</script>

<style scoped>
</style>

<style less>
.confirmationImageContainer {
    margin-top: 20px;
}
.confirmationTextContainer {
    text-align: center;
}
.confirmationImage {
    width: 100px;
    height: 100px;
    padding: 10px;
    display: block;
    margin: auto;
}
</style>
