<template>
    <div>
        <div v-if="hasValidRoute()">
            <router-link :to="{ name: routeName }">
                <ebaLogo :altText="altText" :is-responsive="isResponsive"
                        :source="source"
                        :tabletSource="tabletSource"
                        :mobileSource="mobileSource"/>
            </router-link>
        </div>
        <div v-else>
            <a :href="imageLinkUrl" target="_blank">
                <ebaLogo :altText="altText" :is-responsive="isResponsive"
                      :source="source"
                      :tabletSource="tabletSource"
                      :mobileSource="mobileSource"/>
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ImageLink',
        props: {
            isResponsive: {
                type: Boolean,
                required: false,
                default: false
            },
            source: {
                type: String,
                required: true
            },
            tabletSource: {
                type: String,
                required: false
            },
            mobileSource: {
                type: String,
                required: false
            },
            altText: {
                type: String,
                required: true
            },
            imageLinkUrl: {
                type: String,
                required: false,
                default: null
            },
            routeName: {
                type: String,
                required: false,
                default: null
            },
            trackingEvent: {
                type: Object,
                required: false
            }
        },
        methods: {
            hasValidRoute() {
                return this.routeName !== null;
            }
        }
    };
</script>

<style scoped>

    a {
        height: inherit;
    }

    div {
        height: inherit;
    }

</style>
