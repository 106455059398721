var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "header" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("span", { staticClass: "gradientText" }, [
        _c("strong", [_vm._v(_vm._s(_vm.commonProperties.customerName))]),
        _vm._v(", you are one of our favourites and\n            "),
        _c("br"),
        _vm._v("we don't want to see you go...\n        "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "spacer-line" }),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: { src: require("../../assets/Images/discount.svg") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "text-center" }, [
        _c("p", { staticClass: "gradientText text-center" }, [
          _c("strong", [
            _vm._v(
              "\n                    Let us make you an offer you can't refuse.\n                    "
            ),
            _c("br"),
            _vm._v(
              "A huge saving for 6 months, that's only...\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "price" }, [
          _c("p", { staticClass: "gradientText" }, [_vm._v("£12.50")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("p", { staticClass: "sub gradientText" }, [_vm._v("per month")]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "price-summary text-center" }, [
          _c("strong", [_vm._v("Cancel anytime")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "benefits" }, [
        _c("p", { staticClass: "gradientText" }, [
          _c("strong", [_vm._v("Your premium benefits include:")]),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n                The most accurate and fully comprehensive view\n                of your own business credit profile.\n            "
          ),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n                Receive alerts about any changes so you can act\n                quickly to reduce any negative impact on your score.\n            "
          ),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v("Premium customer support (phone, email and chat)."),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "smallprint" }, [
        _c("span", { staticClass: "tc" }, [
          _vm._v(
            "Please note you may be required to re-authenticate your payment method via email."
          ),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n                The offer will provide the Full My Business Profile product for £12.50 for 6 months.\n                After 6 months your subscription will revert back to your current subscription amount.\n                You can still cancel the product at any time.\n            "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }