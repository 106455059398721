import * as types from './pepsMutation';
import pepsHandler from '../../../../scripts/common/pepsAndSanctionsHandler.js';

const state = {
    hasPeps: undefined
};

const getters = {
    isUserOnPeps: state => {
        return state.hasPeps;
    }
};

const mutations = {
    checkPepsAndSanctions(state, payload) {
        state.hasPeps = payload;
    }
};

const actions = {
    checkPepsAndSanctions({commit, state}, payload) {
        return new Promise((resolve, reject) => {
            try {
                pepsHandler.checkForPepsAndSanctions(payload)
                    .then((resp) => {
                        commit(types.CHECKPEPS, resp);
                        resolve(resp);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
