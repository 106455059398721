import { render, staticRenderFns } from "./termsAndConditions.vue?vue&type=template&id=320d62ea&scoped=true"
import script from "./termsAndConditions.vue?vue&type=script&lang=js"
export * from "./termsAndConditions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../Server/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "320d62ea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\data\\jenkins_home\\workspace\\Web_Applications\\Deploy_MBPUX_Site\\Server\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('320d62ea')) {
      api.createRecord('320d62ea', component.options)
    } else {
      api.reload('320d62ea', component.options)
    }
    module.hot.accept("./termsAndConditions.vue?vue&type=template&id=320d62ea&scoped=true", function () {
      api.rerender('320d62ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/registration/termsAndConditions.vue"
export default component.exports