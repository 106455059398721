<template>
    <div>
        <ebaButton v-show="showPackageDetails === true" style-type="flat" v-on:click.native="showPackageInfo()">
            Package <span class="success">(1)</span>
        </ebaButton>
        <ebaButton v-show="isAuthenticated === false" style-type="flat" route-name="Login">Login</ebaButton>
        <ebaButton v-show="isAuthenticated === true" style-type="flat" route-name="Logout">Logout</ebaButton>
        <ebaPackageModal ref="packageModal"/>
    </div>
</template>

<script>

    import {mapGetters, mapActions, mapState} from 'vuex';

    export default {
        name: 'HeaderActions',
        computed: {
            ...mapGetters([
                'isAuthenticated',
                'showPackageDetails'
            ]),
        },
        methods: {
            ...mapActions([
                'authenticate'
            ]),
            logout() {
                this.authenticate({
                    success: false,
                    user: {
                        id: null,
                        firstName: null,
                        lastName: null
                    }
                });

            },
            showPackageInfo() {
                this.$refs.packageModal.show();
            }
        }
    }
</script>

<style lang="less" scoped>

    @import '../common/assets/css/common.less';

    .success {
        color: @experian-success;
    }

</style>