import axios from 'axios';
import {get as config} from "./config";

class PepsAndSanctionsHandler {

    static checkForPepsAndSanctions(Business) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: config().RegistrationAPI + 'Peps/peps',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    BusinessName: Business.name.trim(),
                    AddressOne: Business.locationLine1.trim(),
                    AddressTwo: Business.locationLine2.trim(),
                    State: '',
                    City: this.getCity(Business),
                    Country: 'United Kingdom',
                    Postcode: Business.postcode.trim()
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getCity(Business){
        if(Business.locationLine3 != null && Business.locationLine3.trim() != ""){
            return Business.locationLine3.trim();
        }else if(Business.locationLine2 != null && Business.locationLine2.trim() != ""){
            return Business.locationLine2.trim();
        }
    }

}

export { PepsAndSanctionsHandler as default };
