var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cardContainer" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { attrs: { id: "innerContainer" } }, [
        _c("div", { attrs: { id: "supportImage" } }, [
          _c("img", {
            attrs: {
              src: require("../components/common/assets/images/support.svg"),
            },
          }),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cardErrorCode" }, [
        _vm._v("\n            EXP-MBP-001\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "cardBodyText gradientText", attrs: { id: "infoText" } },
      [
        _vm._v(
          "\n                Thank you for submitting your details\n                "
        ),
        _c("br"),
        _vm._v(
          "\n                we will email you within 24 hours (Monday-Friday) to complete your registration.\n            "
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }