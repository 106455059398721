var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", [
      _c("input", {
        ref: "searchBar",
        staticClass: "searchInput",
        attrs: { type: "text", placeholder: _vm.placeholderText },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.fireMethod.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "searchButton",
          attrs: { id: "btnSearch", name: "btnSearch" },
          on: { click: _vm.fireMethod },
        },
        [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading === false,
                expression: "loading === false",
              },
            ],
            staticClass: "exp-icon-magnify centerIcon",
          }),
          _vm._v(" "),
          _c("ebaSpinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading === true,
                expression: "loading === true",
              },
            ],
            staticClass: "centerIcon",
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }