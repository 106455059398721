var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "header" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("span", { staticClass: "gradientText" }, [
        _c("strong", [_vm._v(_vm._s(_vm.commonProperties.customerName))]),
        _vm._v(", has it really got to end like this?\n            "),
        _c("br"),
        _vm._v(
          "Before you go, we're sure our free product can help...\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "spacer-line" }),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: { src: require("../../assets/Images/companionDanger.svg") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("div", [
        _c("span", { staticClass: "gradientText" }, [
          _c("strong", [_vm._v("Basic credit profile updated monthly")]),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v("A detailed view of your own business credit profile."),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticClass: "gradientText" }, [
          _c("strong", [_vm._v("Basic email support")]),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("Email support to help you and your business.")]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticClass: "gradientText" }, [
          _c("strong", [_vm._v("Upgrade to premium anytime and enjoy...")]),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "The most accurate and fully comprehensive view of your own business credit profile."
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", [
          _vm._v(
            "Receive alerts about any changes so you can act quickly to reduce any negative impact to your credit score."
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", [
          _vm._v("Premium customer support (phone, email and chat)."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }