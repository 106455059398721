<template>
    <div class="searchContainer">
        <ebaCommonErrorModal ref="commonErrorModal"/>
        <ebaCommonErrorModal ref="directorshipTakenModal" :showCloseFooter="true" :showEmailFooter="false">
            <div class="directorTaken">
                <h3 class="gradientText" id="directorTakenHeader">We encountered an issue processing your details.</h3>
                <p class="informationText">
                    Please check that you have selected the correct business and directorship.<br><br>
                    If you have already registered for your Experian My Business Profile Credit Report please <router-link class="registrationLink" :to="{name: 'Login'}">Login</router-link>.
                    <br><br>
                    If your selections are correct please contact our Customer Service Team on 0345 045 8081 (Calls charged at standard rate plus your phone company's access charge - please see FAQ's)
                </p>
            </div>
        </ebaCommonErrorModal>
        <section>
            <ebaSearchBar
                    ref="searchBar"
                    class="searchBar"
                    placeholderText="Enter your business name or registration number"
                    @searchClick="BusinessSearch"/>
            <div>
                <div class="gradientText" id="selectName">
                    Please select your name
                </div>
                <br>
                <div class="gradientText" id="directorCount">
                    {{getDirectorCount()}}
                </div>
            </div>
        </section>
        <section>
            <div class="resultsContainer">
                <ul class="resultsList">
                    <li :class="['resultListItem', 'resultBorder', showBottomBorder(result)]"
                        v-for="result in Directors" :key="result.officerId">
                        <ebaDirectorResult :DirectorResultObj="result"
                                           :IsDisabled="isDisabled"
                                           v-on:showCommonError="showCommonError"
                                           v-on:showDirectorshipTaken="showDirectorshipModal"/>
                    </li>
                </ul>
            </div>
        </section>
        <section class="businessNotShown">
            <ebaButton routeName="FAQs" styleType="raised">Directorship not shown?</ebaButton>
        </section>
    </div>
</template>

<script>

    import businessSearchHandler from '../../scripts/businessSearchHandler';

    export default {
        name: "directorSearch",
        props: {
            Directors: {
                type: Array,
                required: true
            },
        },
        mounted() {
            if (this.Directors === undefined) {
                this.$router.push({
                    name: 'Home'
                });
            }
        },
        data() {
            return {
                isDisabled: false
            }
        },
        methods: {
            getDirectorCount() {
				if (this.Directors !== undefined) {
					return this.Directors.length + " results for " + this.$store.state.businessSearch.Business.name;
				}
            },
            BusinessSearch(searchText) {
                this.$refs.searchBar.loading = true;
                businessSearchHandler.searchBusiness(searchText)
                    .then((response) => {
                        this.$refs.searchBar.loading = false;
                        this.$router.push({
                            name: 'BusinessResults',
                            params: {
                                SearchPhrase: searchText,
                                SearchResults: response.data
                            }
                        });
                    })
                    .catch(() => {
                        this.$refs.searchBar.loading = false;
                        this.showCommonError();
                    });
            },
            toUpperCase(value) {
                if (value !== null && value !== undefined && typeof value === "string") {
                    return value.toUpperCase();
                }

                return value;
            },
            showBottomBorder(result) {
                if (result.officerId === this.Directors.slice(-1)[0].officerId) {
                    return 'resultBorderLast';
                }
            },
            showCommonError() {
                this.$refs.commonErrorModal.show();
            },
            showDirectorshipModal() {
                this.$refs.directorshipTakenModal.show();
            }
        }
    };
</script>

<style lang="less" scoped>

    @import "../../components/common/assets/css/common.less";

    .searchContainer {
        text-align: center;
    }

    .searchBar {
        margin-top: 32px;
    }

    .resultsContainer {
        width: 100%;
    }

    #selectName {
        font-size: 24px;
        margin: 20px 0 0 0;
    }

    #directorCount {
        font-size: 24px;
        margin: 6px 0 16px 0;
    }

    .headerSpacing {
        margin: 20px;
    }

    .resultBorder {
        border-top: 1px solid @experian-light-grey;
    }

    .resultBorderLast {
        border-bottom: 1px solid @experian-light-grey;
    }

    .resultsList {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .businessNotShown {
        margin: 24px;
    }

    .directorTaken {
        width: 100%;
        text-align: left;
    }

    #directorTakenHeader {
        margin-bottom: 0;
    }

    .directorshipTakenText {
        margin: 0;
    }

</style>
