import axios from 'axios';

import Store from '../../vuex/store.js';
import {get as config} from './config.js';

const trackingId = generateGuid();

class EventTrackingHandler {

    static trackEvent(evt) {
        new Promise((resolve, reject)=> {
        // Extract the user id from the store
        if (!evt.User) {
            try {
                evt.User = Store.state.authentication.user.id;
            } catch (e) {}
        }

        //Validate the model to ensure it is correct
        validateModel(evt);

        axios({
            method: 'POST',
            url: config().EventTracking,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                ApplicationId: config().ApplicationId,
                SessionId: trackingId,
                UserId: evt.User || null,
                Event: evt.Event,
                From: evt.From,
                To: evt.To,
                Detail: evt.Details || null
            }
        })
    });
    }

    static trackingEvents = {
        landingPage : 47,
        contactUsLink : 48,
        loginLink : 49,
        callUsLink : 50,
        emailLink : 51,
        termsLink : 52,
        privacyLink : 53,
        cookiesLink : 54,
        helpFaqLink : 55,
        linkedInLink : 56,
        facebookLink : 57,
        youTubeLink : 58,
        twitterLink : 59,
        personalPage : 60,
        contactPage : 61,
        companyPage : 62,
        companySelectPage : 63,
        passwordPage : 64,
        memorablePage : 65,        
        tandcPage : 66,
        communicationsPage : 67,
        securityPage : 68,
        blogLink: 69
    };
}

function validateModel(evt) {
    // Check Event
    console.log('model to validate:', evt);
    if (evt.Event === undefined || evt.Event === null) {
        throw 'No Event specified';
    } else {
        let event = evt.Event;
        if (typeof event !== "number") {
            let parsedValue = parseInt(event);
            if (!isNaN(parsedValue)) {
                evt.Event = parsedValue;
            } else {
                throw 'Unexpected Tracking Event';
            }
        }
    }

    //Check Details
    if (evt.Details !== undefined && evt.Details !== null) {
        let details = evt.Details;
        if (typeof details === "object") {
            try {
                evt.Details = JSON.stringify(evt.Details);
            } catch (e) {
                throw 'Unable to automatically cast Event Details to string from ' + typeof details;
            }
        }
    }

    //Check User isnt an object, if it is, try strip out just the ID
    if (typeof evt.User === "object") {
        if (evt.User.Id !== undefined && evt.User.Id != null) {
            evt.User = evt.User.Id;
        } else if (evt.User.id !== undefined && evt.User.id != null) {
            evt.User = evt.User.id;
        } else {
            throw 'Unexpected Event User object type'
        }
    }

    if (typeof evt.User === "string") {
        let parsedValue = parseInt(evt.User);
        if (!isNaN(parsedValue)) {
            evt.User = parsedValue
        } else {
            throw 'Unexpected Event User object type'
        }
    }
}

function generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export {EventTrackingHandler as default};
