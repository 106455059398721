var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.useSmallContainer() },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.iconSource,
              expression: "iconSource",
            },
          ],
        },
        [
          _c("img", {
            class: _vm.useSmallIcons(),
            attrs: { src: _vm.iconSource },
          }),
        ]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }