var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cardContainer page" },
    [
      _c("ebaCommonErrorModal", { ref: "commonErrorModal" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.topMessage !== undefined,
              expression: "topMessage !== undefined",
            },
          ],
          staticClass: "headerTextContainer",
        },
        [
          _c("p", {
            staticClass: "registrationHeaderText gradientText",
            domProps: { innerHTML: _vm._s(_vm.topMessage) },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          [
            _c("router-view", {
              staticStyle: { width: "100%" },
              attrs: { registrationData: _vm.regModel },
              on: {
                requestCustomProceedText: _vm.handleCustomProceedText,
                clearCustomProceedText: _vm.clearCustomProceedText,
                setCustomHeaderText: _vm.handleCustomerHeaderText,
                clearHeaderText: _vm.clearHeaderText,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cardNavigationButton" },
          [
            _c(
              "ebaButton",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showPrevious,
                    expression: "showPrevious",
                  },
                ],
                attrs: { "is-disabled": _vm.isDisabled, "style-type": "flat" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.previousClick.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n                Previous\n            ")]
            ),
            _vm._v(" "),
            _c(
              "ebaButton",
              {
                attrs: {
                  "is-disabled": _vm.isDisabled,
                  "is-loading": _vm.isDisabled,
                  "style-type": "raised",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.proceedClick.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.progressButtonText) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("br", { staticStyle: { clear: "both" } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }