<template>
    <div class="LineText">
        {{text}}
    </div>
</template>

<script>

    export default {
        name: 'lineText',
        props: {
            text: {
                type: String,
                required: true
            }
        }
    };

</script>

<style scoped  lang="less">

    @import 'assets/css/common.less';
    
    .LineText {
        overflow: hidden;
        text-align: center;
        color: @experian-violet;
        font-size: 18px;
        padding-bottom: 4px;
    }

    .LineText:before,
    .LineText:after {
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }

    .LineText:before {
        right: 20px;
        margin-left: -50%;
        background-color: @experian-sapphire;
    }

    .LineText:after {
        left: 20px;
        margin-right: -50%;
        background-color: @experian-violet;
    }

    /* Mobile */
    @media only screen and (max-width: 560px) {

        .LineText {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

        .LineText:before,
        .LineText:after {
            height: 0;
        }
    }

</style>
