var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaRegistrationStep",
        {
          ref: "RegistrationStep",
          attrs: {
            "step-title": "For added security please enter a memorable word.",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("ebaTextInput", {
                ref: "memorableWordInput",
                attrs: {
                  helpText:
                    "You will need this memorable word when you login to your account",
                  "input-type": "password",
                  inputID: "memorableWordInput",
                  "place-holder": "Enter memorable word",
                },
                model: {
                  value:
                    _vm.registrationData.MemorableWordDetails.MemorableWord,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData.MemorableWordDetails,
                      "MemorableWord",
                      $$v
                    )
                  },
                  expression:
                    "registrationData.MemorableWordDetails.MemorableWord",
                },
              }),
              _vm._v(" "),
              _c("ebaCriteriaList", { ref: "memorableWordList" }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "memorableWordConfirmationInput",
                attrs: {
                  "input-type": "password",
                  inputID: "memorableWordConfirmInput",
                  "place-holder": "Confirm memorable word",
                },
                model: {
                  value:
                    _vm.registrationData.MemorableWordDetails
                      .ConfirmMemorableWord,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData.MemorableWordDetails,
                      "ConfirmMemorableWord",
                      $$v
                    )
                  },
                  expression:
                    "registrationData.MemorableWordDetails.ConfirmMemorableWord",
                },
              }),
              _vm._v(" "),
              _c("ebaCriteriaList", { ref: "confirmMemorableWordList" }),
              _vm._v(" "),
              _c("ebaTextInput", {
                ref: "memorableWordReminderInput",
                attrs: {
                  autocomplete: false,
                  "place-holder": "Enter memorable word hint",
                  inputID: "memorableWordReminderInput",
                },
                model: {
                  value:
                    _vm.registrationData.MemorableWordDetails.MemorableHint,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.registrationData.MemorableWordDetails,
                      "MemorableHint",
                      $$v
                    )
                  },
                  expression:
                    "registrationData.MemorableWordDetails.MemorableHint",
                },
              }),
              _vm._v(" "),
              _c("ebaCriteriaList", { ref: "memorableWordHintList" }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }