var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("ebaTopButton", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTopButton,
            expression: "showTopButton",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "header" }, [_c("ebaHeader")], 1),
          _vm._v(" "),
          _c("router-view"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "ebaFooter",
                [
                  _c("template", { slot: "promotionalOffer" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.promotionalText) +
                        "\n                    "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          id: "TAndCLink",
                          href: _vm.offerTermsAndConditions,
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Terms and Conditions\n                    "
                        ),
                      ]
                    ),
                    _vm._v("\n                    apply.\n                "),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }