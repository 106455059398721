<template>
    <div class="Chevron">
        <img :src="require('./assets/Images/chevron.svg')" alt="chevron">
    </div>
</template>

<script>

    export default {
        name: 'chevron'
    };

</script>

<style lang="less" scoped>

    .Chevron {
        margin: auto;
        text-align: center;
    }

    .Chevron img {
        height: 30px;

    }
</style>
