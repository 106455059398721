<template>
    <div class="cardContainer">
        <ebaCommonErrorModal ref="commonErrorModal"/>
        <ebaLoadingModal ref="loadingModal"/>
        <div class="card">
            <ebaRegistrationStep step-title="We've been unable to verify your identity." :showStepNumber="false">
                <div>
                    <div class="cardBodyText">
                        We have been unable to verify your identity from the information provided.<br/><br/>
                        To ensure the security of your business data we have sent a PIN (Personal Identification Number)
                        verification
                        letter to the address that you entered during the registration process.<br/><br/>

                        When you receive your PIN letter please follow the instructions provided to gain access to your
                        Experian My Business Profile credit report.
                        <br/><br/>
                        <strong>Please Note it could take up to 7 Days for a PIN letter to arrive</strong>
                    </div>
                    <ebaInfo>
                        <strong>Your subscription will NOT start &amp; payment will not be taken until you have
                            completed the registration process.</strong>
                    </ebaInfo>
                </div>
            </ebaRegistrationStep>
        </div>
    </div>
</template>

<script>

    import registrationHandler from '../../scripts/registrationHandler';

    export default {
        name: 'pinSent',
        mounted() {
            var self = this;
            const userID = self.$store.state.authentication.userID;

            // Invalid data, redirect to home page
            if (!userID) {
                self.$router.push({
                    name: 'Home'
                });
            } else {

                self.$refs.loadingModal.show();

                registrationHandler.pinEntryValid(userID)
                    .then(response => {
                        if (response === true) {
                            // Pin can now be entered, push to Pin Entry
                            self.$router.push({
                                name: 'PinEntry'
                            });
                        } else {
                            // Request PIN for User
                            registrationHandler.requestPin(userID)
                                .then((response) => {
                                    console.log(response);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    self.$refs.commonErrorModal.show();
                                })
                                .finally(() => {
                                    self.$refs.loadingModal.close();
                                });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        self.$refs.commonErrorModal.show();
                    })
                    .finally(() => {
                        self.$refs.loadingModal.close();
                    });
            }
        }
    };

</script>
