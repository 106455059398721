import { render, staticRenderFns } from "./registration.vue?vue&type=template&id=8ea26c8a"
import script from "./registration.vue?vue&type=script&lang=js"
export * from "./registration.vue?vue&type=script&lang=js"
import style0 from "./registration.vue?vue&type=style&index=0&id=8ea26c8a&lang=less"


/* normalize component */
import normalizer from "!../../../../Server/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\data\\jenkins_home\\workspace\\Web_Applications\\Deploy_MBPUX_Site\\Server\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8ea26c8a')) {
      api.createRecord('8ea26c8a', component.options)
    } else {
      api.reload('8ea26c8a', component.options)
    }
    module.hot.accept("./registration.vue?vue&type=template&id=8ea26c8a", function () {
      api.rerender('8ea26c8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/registration/registration.vue"
export default component.exports