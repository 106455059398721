var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showModal
    ? _c(
        "ebaModal",
        {
          on: {
            close: function ($event) {
              _vm.showModal = false
            },
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "headerText gradientText" }, [
              _vm._v("Package Details."),
            ]),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                height: "1px",
                width: "100%",
                "border-bottom": "solid 1px #eee",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "packageBody",
              attrs: { slot: "body" },
              slot: "body",
            },
            [
              _c("p", { staticClass: "violetSubText" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.currentPrice) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "selectedDetails" }, [
                _vm._v("What you pay today"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "successText" }, [_vm._v("£0")]),
              _vm._v(" "),
              _c("ebaInfo", { attrs: { id: "cancelInfo" } }, [
                _c("div", { attrs: { id: "cancelText" } }, [
                  _c("strong", [_vm._v("Cancel Anytime")]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showDirector,
                        expression: "showDirector",
                      },
                    ],
                    staticClass: "directorshipSection",
                  },
                  [
                    _c("p", { staticClass: "subTitleText" }, [
                      _vm._v(
                        "\n                    Selected Directorship\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "selectedDetails" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.directorName))]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showBusiness,
                        expression: "showBusiness",
                      },
                    ],
                    staticClass: "businessSection",
                  },
                  [
                    _c("p", { staticClass: "subTitleText" }, [
                      _vm._v(
                        "\n                    Selected Business\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "selectedDetails" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.BusinessName))]),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "selectedDetails" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.businessDetails) +
                          "\n                "
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }