var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "searchContainer" },
    [
      _c("ebaCommonErrorModal", { ref: "commonErrorModal" }),
      _vm._v(" "),
      _c(
        "ebaCommonErrorModal",
        {
          ref: "directorshipTakenModal",
          attrs: { showCloseFooter: true, showEmailFooter: false },
        },
        [
          _c("div", { staticClass: "directorTaken" }, [
            _c(
              "h3",
              {
                staticClass: "gradientText",
                attrs: { id: "directorTakenHeader" },
              },
              [_vm._v("We encountered an issue processing your details.")]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "informationText" },
              [
                _vm._v(
                  "\n                Please check that you have selected the correct business and directorship."
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  "\n                If you have already registered for your Experian My Business Profile Credit Report please\n                "
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "registrationLink",
                    attrs: { to: { name: "Login" } },
                  },
                  [_vm._v("Login")]
                ),
                _vm._v("\n                .\n                "),
                _c("br"),
                _c("br"),
                _vm._v(
                  "\n                If your selections are correct please contact our Customer Service Team on 0345 045 8081 (Calls charged at standard rate plus your phone company's access charge - please see FAQ's)\n            "
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        [
          _c("ebaSearchBar", {
            ref: "searchBar",
            staticClass: "searchBar",
            attrs: {
              placeholderText:
                "Enter your business name or registration number",
            },
            on: { searchClick: _vm.BusinessSearch },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ShowResultSummary,
                  expression: "ShowResultSummary",
                },
              ],
              staticClass: "resultsCount gradientText headerText",
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.getSearchResultsLengthPhrase) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "resultsContainer" }, [
          _c(
            "ul",
            { staticClass: "resultsList" },
            _vm._l(_vm.results, function (result) {
              return _c(
                "li",
                {
                  key: result.businessRef,
                  class: [
                    "resultListItem",
                    "resultBorder",
                    _vm.showBottomBorder(result),
                  ],
                },
                [
                  _c("ebaBusinessResult", {
                    attrs: {
                      BusinessResultObj: result,
                      IsDisabled: _vm.isDisabled,
                    },
                    on: {
                      showCommonError: _vm.showCommonError,
                      showDirectorshipTaken: _vm.showDirectorshipModal,
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "businessNotShown" },
        [
          _c(
            "ebaButton",
            { attrs: { routeName: "FAQs", styleType: "raised" } },
            [_vm._v("Business not shown?")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }